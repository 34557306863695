export enum AnalyticsEvent {
  /**
   * User authenticated.
   */
  UserAuthenticated = 'ia.user.authenticated',

  /**
   * Pass a step of the onboarding.
   */
  OnboardingNextStepClick = 'ia.onboarding.next_step_click',

  /**
   * Submit a form of user's onboarding.
   */
  SubmitOnboarding = 'ia.onboarding.submit',

  /**
   * Click on any game card on the discover page.
   */
  GameCardClick = 'ia.game_card.click',

  /**
   * Click on the contact developer button.
   */
  ContactDeveloperClick = 'ia.contact_developer.click',

  /**
   * Submit a form with a message to the developer.
   */
  SubmitContactDeveloper = 'ia.contact_developer.submit',

  /**
   * User applied filters on the discover page.
   */
  UserGamesFilterApplied = 'ia.user.games_filter_applied'
}

export enum AuthenticationType {
  RegistrationEmail = 'registration_email',
  LoginEmail = 'login_email',
  Social = 'social'
}
