import React from 'react';
import {FieldRenderProps} from 'react-final-form';
import {Notification, RadioGroup as UIRadioGroup} from '@xsolla/uikit';
import classNames from 'classnames/bind';
import {FieldValue, getFieldMetaError} from '../../../lib/form';
import style from './style.module.scss';

interface Options {
  label: string;
  radioValue: FieldValue;
}

export interface RadioGroupProps extends FieldRenderProps<FieldValue, HTMLInputElement> {
  options: Options[];
}

const cx = classNames.bind(style);

export const RadioGroup = ({input, meta, ...rest}: RadioGroupProps) => {
  const error = getFieldMetaError(meta);

  return (
    <>
      <div className={cx(style.radioGroup, !!error && style.error)} onBlur={input.onBlur}>
        <UIRadioGroup input={input} meta={{}} {...rest} />
      </div>

      {error && (
        <Notification appearance="string" status="error">
          {error}
        </Notification>
      )}
    </>
  );
};
