import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';
import style from './style.module.scss';

export interface FilterFieldGroupProps {
  children: ReactNode;
  label: string;
}

const cx = classNames.bind(style);

export const FilterFieldGroup = ({children, label}: FilterFieldGroupProps) => (
  <div className={cx(style.filterGroup)}>
    <div className={cx(style.label)}>{label}</div>
    {children}
  </div>
);
