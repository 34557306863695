import React, {ReactNode} from 'react';
import styled from 'styled-components';

export enum TitleAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

interface TitleProps {
  children: ReactNode;
  align?: TitleAlign;
}

const StyledTitle = styled.div<TitleProps>`
  margin-bottom: 32px;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  text-align: ${p => p.align};
`;

export const Title = ({children, align = TitleAlign.Left}: TitleProps) => (
  <StyledTitle align={align}>{children}</StyledTitle>
);
