import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;

  & > div {
    display: flex;
  }
`;

export const BlockedContainer = styled.div`
  flex-shrink: 0;
  pointer-events: none;
`;

export const LogoContainer = styled.div`
  flex-shrink: 0;
`;

export const UserContainer = styled.div`
  flex-shrink: 0;
  align-self: center;
`;

export const NavigationContainer = styled.div`
  flex-grow: 1;
  padding-left: 75px;
  padding-top: 20px;
`;
