import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {GDPR} from '../component/gdpr';

interface PromoXfcLayoutProps {
  children: ReactNode;
}

const StyledPromoXfcLayout = styled.div`
  min-height: 100vh;
  background-color: #00244d;
  color: #fff;
`;

const Entry = styled.div`
  max-width: 1360px;
  padding: 0 32px;
  margin: 0 auto;
  box-sizing: content-box;
`;

export const PromoXfcLayout = ({children}: PromoXfcLayoutProps) => (
  <StyledPromoXfcLayout>
    <GDPR />
    <Entry>{children}</Entry>
  </StyledPromoXfcLayout>
);
