import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {setupToken} from '../../lib/jwtToken';
import {actionUpdateOnboardingStatus} from '../../redux/action/onboarding';
import {selectApplicationLoggedIn} from '../../redux/selectors/application';
import {COOKIES, getFromCookie} from '../../lib/cookies';
import {OnboardingStatus} from '../../constants/OnboardingStatus';
import {logOutApplication} from '../../redux/action/application';
/* */
import {useOnboardingStatus} from './useOnboardingStatus';

interface UseUserInitialize {
  isInitUserPending: boolean;
}

export const useUserInitialize = (): UseUserInitialize => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loggedIn = useSelector(selectApplicationLoggedIn);

  const {getOnboardingStatus, isFetchingOnboardingStatus} = useOnboardingStatus();

  useEffect(() => {
    if (!loggedIn) {
      return;
    }

    setupToken(getFromCookie(COOKIES.PRODUCT_COOKIE_TOKEN));

    getOnboardingStatus({
      onSuccess: () => {
        dispatch(actionUpdateOnboardingStatus(OnboardingStatus.APPROVED));
      },
      onOnboardingIsPassed: () => {
        dispatch(actionUpdateOnboardingStatus(OnboardingStatus.PASSED));
      },
      onOnboardingIsUncomplete: () => {
        dispatch(actionUpdateOnboardingStatus(OnboardingStatus.UNCOMPLETED));
        history.replace('/decline');
      },
      onUnknownError: () => {
        logOutApplication();
      }
    });
  }, [loggedIn]);

  return {
    isInitUserPending: loggedIn && isFetchingOnboardingStatus
  };
};
