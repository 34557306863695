import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import classNames from 'classnames/bind';
import {PrivateRoute} from '../component/PrivateRoute';
import {PublicRoute} from '../component/PublicRoute';
import {LoginBackwardCompatibility} from './LoginBackwardCompatibility';
import {PromoContainer} from '../container/PromoContainer';
import {OfficeContainer} from '../container/OfficeContainer';
import {SettingsContainer} from '../container/SettingsContainer';
import {DeclineContainer} from '../container/DeclineContainer';
import {OnboardingContainer} from '../container/OnboardingContainer';
import {VerifyPage} from '../page/Auth';
import {UnsubscribePage} from '../page/UnsubscribePage';
import {useAnalyticsObserver} from '../hooks/useAnalytics';
import {useUserInitialize} from '../hooks/useUserInitialize';
import {selectAppTheme} from '../redux/selectors/apptheme';
import {AppThemeEnum} from '../redux/types/theme';
import {getEnumLowerCaseKey} from '../utils/enum';
import {useBasicAuth} from './hooks';
import {Routes} from '../constant';
import style from './style.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@xsolla/uikit/lib/icons.css';
import '@xsolla/uikit/lib/fonts.css';
import '@xsolla/ui-core/dist/index.css'; // fonts
import './fonts.scss';

const cx = classNames.bind(style);

export const App: FC = () => {
  const {isInitUserPending} = useUserInitialize();
  const colorTheme = getEnumLowerCaseKey(AppThemeEnum, useSelector(selectAppTheme));
  const basicAuthBlock = useBasicAuth();
  useAnalyticsObserver();

  if (basicAuthBlock) {
    return null;
  }

  if (isInitUserPending) {
    return <div className={cx(style.app, style[`app__background_${colorTheme}`])} />;
  }

  return (
    <div className={cx(style.app, style[`app__background_${colorTheme}`])}>
      <LoginBackwardCompatibility />

      <Switch>
        <PublicRoute path={Routes.VERIFY} exact={true} component={VerifyPage} />
        <PublicRoute path={['/', '/auth*']} exact={true} component={PromoContainer} />

        <Route path="/unsubscribe" exact={true} component={UnsubscribePage} />

        <PrivateRoute path={Routes.ONBOARDING} exact={true} component={OnboardingContainer} />
        <PrivateRoute path="/decline" exact={true} component={DeclineContainer} />
        <PrivateRoute path="/office" component={OfficeContainer} />
        <PrivateRoute path={Routes.SETTINGS_PAGE} component={SettingsContainer} />
      </Switch>
    </div>
  );
};
