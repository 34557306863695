import {Action, AnyAction} from 'redux';
import {GameDetails, GameInvestmentInfo} from '../../api/game';

export interface MinimalGame
  extends Pick<
      GameDetails,
      | 'id'
      | 'game_name'
      | 'description'
      | 'header_image_url'
      | 'monetization_type'
      | 'launch_date'
      | 'game_already_launched'
    >,
    Pick<
      GameInvestmentInfo,
      | 'invested_amount'
      | 'requested_amount'
      | 'days_left'
      | 'investment_specialization'
      | 'percentage'
    > {
  game_type: number | string;
  partially_funded: boolean;
}

/**
 *
 * InvestmentProgressInfo for progress bars
 */
export type InvestmentProgressInfo = Pick<
  GameInvestmentInfo,
  'invested_amount' | 'requested_amount' | 'percentage' | 'days_left'
>;

export interface GamesState {
  details: GameDetails;
  investmentInfo: GameInvestmentInfo;
}

const initialState: GamesState = {
  details: null,
  investmentInfo: null
};

const LOAD_DETAILS_STARTED = '@/games/LOAD_DETAILS_STARTED';
const LOAD_DETAILS_FINISHED = '@/games/LOAD_DETAILS_FINISHED';

export const gamesReducer = (state = initialState, action: AnyAction): GamesState => {
  switch (action.type) {
    case LOAD_DETAILS_STARTED:
      return {
        ...state,
        details: null,
        investmentInfo: null
      };

    case LOAD_DETAILS_FINISHED:
      return {
        ...state,
        details: action.payload.game,
        investmentInfo: action.payload.investmentInfo
      };

    default:
      return state;
  }
};

/**
 * Return start load game action
 */
export const loadGameDetailsStarted = (): Action => ({
  type: LOAD_DETAILS_STARTED
});

/**
 * Return finish load game action
 * @param game
 * @param investmentInfo
 */
export const loadGameDetailsFinished = (
  game: GameDetails,
  investmentInfo: GameInvestmentInfo
): AnyAction => ({
  type: LOAD_DETAILS_FINISHED,
  payload: {game, investmentInfo}
});
