import {format} from 'date-fns';

interface GetLaunchDateArguments {
  gameAlreadyLaunchedLabel: string;
  gameAlreadyLaunched?: boolean;
  launchDate?: string;
}

export const getLaunchDate = ({
  gameAlreadyLaunched,
  launchDate,
  gameAlreadyLaunchedLabel
}: GetLaunchDateArguments) => {
  if (gameAlreadyLaunched) {
    return gameAlreadyLaunchedLabel;
  }
  if (launchDate) {
    return format(new Date(launchDate), 'MMM yyyy');
  }
  return 'N/A';
};
