import styled from 'styled-components';

export const StatusLabel = styled.span<{$color: 'default' | 'brand'}>`
  ${({theme}) => theme.typography.label};
  display: inline-block;
  padding: 2px 8px;
  color: ${({theme, $color}) => ($color === 'brand' ? theme.colors.brand : theme.colors.muted)};
  border: 1px solid
    ${({theme, $color}) => ($color === 'brand' ? theme.colors.brand : theme.colors.stroke)};
  border-radius: 4px;
`;
