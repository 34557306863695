import {
  investorGetCompanies,
  investorLoginUser,
  requestLoginUser,
  requestRegister
} from '../../../utils/requester/login';
import {COOKIES, setToCookie} from '../../../lib/cookies';
import {getTokenFromUrl, setupToken} from '../../../lib/jwtToken';

const investorApiAuth = async () => {
  await investorLoginUser();
  const response = await investorGetCompanies();
  const company = await response.data.find(elem => {
    return elem.id;
  });
  await setToCookie(COOKIES.PRODUCT_COOKIE_COMPANY_ID, company.id);
};

const xsollaLoginApiAuth = async registerUserFunction => {
  const response = await registerUserFunction();
  setupToken(getTokenFromUrl(response.data.login_url));
  await investorApiAuth();
};

export const xsollaLoginAuthSocial = async (payload: Record<string, string>) => {
  setupToken(getTokenFromUrl(payload.url));
  await investorApiAuth();
};

const registerUser = requestFunction => xsollaLoginApiAuth(requestFunction);

export const xsollaLoginApiRegister = (payload: Record<string, string>) =>
  registerUser(() => requestRegister(payload));

export const xsollaLoginApiLogin = (payload: Record<string, string>) =>
  registerUser(() => requestLoginUser(payload));
