import {useEffect} from 'react';

/**
 * Do something on component unmount depend on opening route
 * @param callback - callback function triggered when location and one of routes matches
 * @param routes - routes dependencies
 */
export const useRouteLeave = (callback: () => void, routes: string[]) => {
  useEffect(() => {
    return () => {
      if (routes.some(value => window.location.pathname.match(value))) {
        callback();
      }
    };
  }, [routes, callback]);
};
