import {InjectedIntl} from 'react-intl';

const gameType = {
  multiplayer_online: 'app.page.discoverpage.card-data.game_type.multiplayer_online',
  massively_multiplayer_online:
    'app.page.discoverpage.card-data.game_type.massively_multiplayer_online',
  local_multiplayer: 'app.page.discoverpage.card-data.game_type.local_multiplayer',
  single_player: 'app.page.discoverpage.card-data.game_type.single_player',
  18: 'app.page.discoverpage.card-data.game_type.local_multiplayer',
  19: 'app.page.discoverpage.card-data.game_type.massively_multiplayer_online',
  20: 'app.page.discoverpage.card-data.game_type.multiplayer_online',
  21: 'app.page.discoverpage.card-data.game_type.single_player'
};
export const getFormattedGameType = (value: string | number, intl: InjectedIntl): string | null =>
  value in gameType ? intl.formatMessage({id: gameType[value]}) : null;
