import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Head, Modal, ModalAlert} from '@xsolla/uikit';
import {useAnalytics} from '../../hooks/useAnalytics';
import {AnalyticsEvent} from '../../lib/XsollaAnalytics';
import {selectApplicationCompanyID} from '../../redux/selectors/application';
import SuccessIcon from '../../images/icons/success.svg';
import {GameDetails, useContactDeveloperMutation} from '../../api/game';
import {ContactDeveloperForm, ContactDeveloperFormValues} from '../@forms';

interface ContactDeveloperModalProps {
  game: GameDetails;
  onClose: () => void;
}

const ContactDeveloperModal = ({onClose, game}: ContactDeveloperModalProps) => {
  const analytics = useAnalytics();
  const companyID = useSelector(selectApplicationCompanyID);
  const [submitted, setSubmitted] = useState(false);
  const {mutateAsync} = useContactDeveloperMutation(companyID, game.id);

  const handleFormSubmit = async (values: ContactDeveloperFormValues) => {
    try {
      await mutateAsync(values);
      analytics.click(AnalyticsEvent.SubmitContactDeveloper, {
        exv: {game_id: game.id, game_name: game.game_name}
      });
    } finally {
      setSubmitted(true);
      window.localStorage.removeItem(`ContactDeveloperForm.${game.id}`);
    }
  };

  if (submitted) {
    return (
      <ModalAlert
        isOpen={true}
        icon={SuccessIcon}
        title="We have received your request"
        description="Our manager will send you information you are interested in."
        discard="Thanks, close"
        onClose={onClose}
      />
    );
  }

  return (
    <Modal isOpen={true} onClose={onClose}>
      <Head>
        Contact {game.studio_name} for {game.game_name}
      </Head>
      <ContactDeveloperForm game={game} onSubmit={handleFormSubmit} onClose={onClose} />
    </Modal>
  );
};

export default ContactDeveloperModal;
