import React from 'react';
import classNames from 'classnames/bind';
import style from './style.module.scss';

interface GamesPlaceholderProps {
  internal?: boolean;
}

const cx = classNames.bind(style);

export const GameCardsPlaceholder = ({internal}: GamesPlaceholderProps) => (
  <div className={cx(style.gamesPlaceholder, internal && style.internal)}>
    <div className={cx(style.item)}>
      <div className={cx(style.placeholder)} style={{height: 144, marginBottom: 12}} />
      <div className={cx(style.placeholder)} style={{height: 18, marginBottom: 8}} />
      <div className={cx(style.placeholder)} style={{width: 164, height: 15}} />
    </div>

    <div className={cx(style.item)}>
      <div className={cx(style.placeholder)} style={{height: 144, marginBottom: 12}} />
      <div className={cx(style.placeholder)} style={{height: 18, marginBottom: 8}} />
      <div className={cx(style.placeholder)} style={{width: 164, height: 15}} />
    </div>

    <div className={cx(style.item)}>
      <div className={cx(style.placeholder)} style={{height: 144, marginBottom: 12}} />
      <div className={cx(style.placeholder)} style={{height: 18, marginBottom: 8}} />
      <div className={cx(style.placeholder)} style={{width: 164, height: 15}} />
    </div>
  </div>
);
