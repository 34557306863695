import React from 'react';
import {Route} from 'react-router-dom';
import {PromoXfcLayout} from '../layouts';
import {PromoXfcPage} from '../page/Promo';

export const PromoContainer = () => {
  return (
    <PromoXfcLayout>
      <Route path="/" component={PromoXfcPage} />
    </PromoXfcLayout>
  );
};
