import React, {FC} from 'react';
import {Route, Switch} from 'react-router-dom';
import {OfficeLayout} from '../layouts';
import {GameDetailsPage} from '../features/GameDetailsPage';
import {DiscoverPage} from '../page/DiscoverPage';
import {Routes} from '../constant';

export const OfficeContainer: FC = () => (
  <OfficeLayout>
    <Switch>
      <Route path={Routes.OFFICE_DISCOVER} component={DiscoverPage} />
      <Route path={Routes.OFFICE_GAMEPAGE} component={GameDetailsPage} />
    </Switch>
  </OfficeLayout>
);
