import {IMGIX_S3_GCP_URL, IMGIX_S3_URL} from '../constants/config';

export const IMGIX_INVALID_URL = 'https://imgix-error-src.com';

export const prepareGCPUrlImgix = (url: string, source = IMGIX_S3_GCP_URL): string => {
  try {
    const parsedImgixUrl = new URL(source);
    const parsedSourceUrl = new URL(url);
    const pathMatch = parsedSourceUrl.pathname.match(/(\/[\d\w-]+\.\w{2,5})$/);
    if (pathMatch) {
      parsedImgixUrl.pathname = pathMatch[0] || '';
    }
    parsedImgixUrl.protocol = 'https:';
    return parsedImgixUrl.toString();
  } catch (err) {
    // Imgix parser fix
    return IMGIX_INVALID_URL;
  }
};

export const prepareUrlImgix = (url: string, imgixUrl = IMGIX_S3_URL): string => {
  const GCP_URL_PART = 'googleapis';
  if (url.indexOf(GCP_URL_PART) !== -1) {
    return prepareGCPUrlImgix(url);
  }

  const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i;
  try {
    const parsedImgixUrl = new URL(imgixUrl);
    if (urlRegex.test(url)) {
      const parsedSourceUrl = new URL(url);
      parsedImgixUrl.pathname = parsedSourceUrl.pathname;
    } else {
      parsedImgixUrl.pathname = url;
    }
    parsedImgixUrl.protocol = 'https:';
    return parsedImgixUrl.toString();
  } catch (err) {
    return IMGIX_INVALID_URL;
  }
};
