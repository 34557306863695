import React from 'react';
import styled from 'styled-components';
import {Section} from './Section';

const StyledDisclaimerBlock = styled.div``;

const Title = styled.div`
  position: relative;
  margin-bottom: 60px;
  color: #c9d0d7;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--fontFamilySpaceMono);

  &::after {
    position: absolute;
    background-color: #c9d0d7;
    height: 1px;
    top: 7px;
    width: 50px;
    content: ' ';
    margin-left: 26px;
    pointer-events: none;
  }
`;

const Entry = styled.div`
  font-size: 12px;
  line-height: 28px;
  color: rgba(201, 208, 215, 0.5);

  & p {
    margin: 0 0 15px 0;
  }
`;

export const DisclaimerBlock = () => (
  <Section>
    <StyledDisclaimerBlock>
      <Title>Capital at risk</Title>
      <Entry>
        <p>
          Past performance is not indicative of future results. Examples provided are purely for
          illustrative purposes. An investment in venture funds, including the Fund, involves a high
          degree of risk and is suitable only for sophisticated and qualified accredited investors.
        </p>

        <p>
          The information contained herein is provided for informational and discussion purposes
          only and is not intended to be a recommendation for any investment or other advice of any
          kind, and shall not constitute or imply any offer to purchase, sell or hold any security
          or to enter into or engage in any type of transaction. Any such offers will only be made
          pursuant to formal offering materials containing full details regarding risks, minimum
          investment, fees, and expenses of the Fund.
        </p>
      </Entry>
    </StyledDisclaimerBlock>
  </Section>
);
