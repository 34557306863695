import {AxiosError} from 'axios';
import {UseMutationResult, UseQueryResult, useMutation, useQuery} from 'react-query';
import {
  requestCompleteOnboarding,
  requestOnboardingData,
  requestOnboardingStatus,
  requestSaveOnboardingData
} from './requests';
import {
  OnboardingCompanyInfoStep,
  OnboardingDataUpdateParams,
  OnboardingInvestingInterestStep,
  OnboardingPersonalInfoStep
} from './types';
import {OnboardingSteps} from '../../constants/OnboardingSteps';

export const useOnboardingStatusApi = (companyId: number): UseQueryResult<void> => {
  return useQuery<void>(['onboarding-status'], () => requestOnboardingStatus(companyId), {
    enabled: false,
    cacheTime: 0,
    refetchInterval: false
  });
};

export const useOnboardingPersonalInfoStepApi = (
  companyId: number
): UseQueryResult<OnboardingPersonalInfoStep> => {
  return useQuery(
    ['onboarding-personal-info-step'],
    () => requestOnboardingData(companyId, OnboardingSteps.PERSONAL_INFO),
    {
      enabled: false,
      cacheTime: 0,
      refetchInterval: false
    }
  );
};

export const useOnboardingCompanyInfoStepApi = (
  companyId: number
): UseQueryResult<OnboardingCompanyInfoStep> => {
  return useQuery(
    ['onboarding-company-info-step'],
    () => requestOnboardingData(companyId, OnboardingSteps.COMPANY_INFO),
    {
      enabled: false,
      cacheTime: 0,
      refetchInterval: false
    }
  );
};

export const useOnboardingInvestingInterestStepApi = (
  companyId: number
): UseQueryResult<OnboardingInvestingInterestStep> => {
  return useQuery(
    ['onboarding-investing-interest-step'],
    () => requestOnboardingData(companyId, OnboardingSteps.INVESTING_INTEREST),
    {
      enabled: false,
      cacheTime: 0,
      refetchInterval: false
    }
  );
};

export const useSaveOnboardingDataApi = (): UseMutationResult<
  void,
  AxiosError,
  OnboardingDataUpdateParams
> => {
  return useMutation(props => requestSaveOnboardingData(props));
};

export const useCompleteOnboardingApi = (
  companyId: number
): UseMutationResult<void, AxiosError, void> => {
  return useMutation(() => requestCompleteOnboarding(companyId));
};
