import React, {useEffect, useState} from 'react';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import style from './style.module.scss';

interface Props {
  stop: boolean;
  setNewIndex: (delta: number) => () => void;
}

const StepperLoader = ({stop, setNewIndex}: Props) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    let timeout;
    if (!stop) {
      timeout = setTimeout(() => {
        if (percent > 100) {
          setNewIndex(1)();
          return;
        }
        setPercent(() => percent + 1);
      }, 100);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [stop, percent]);

  return (
    <div className={style.circleLoader}>
      <CircularProgressbar
        strokeWidth={15}
        value={percent}
        styles={buildStyles({
          strokeLinecap: 'butt',
          trailColor: '#C5D0DB',
          pathColor: '#747e8a'
        })}
      />
    </div>
  );
};

export default StepperLoader;
