import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {mediaDevices} from '../../lib/styled';
import {Button} from './Button';
import {Section} from './Section';
import {Routes} from '../../constant';

const StyledJumbotron = styled.div`
  margin: 0 auto 100px auto;
  max-width: 770px;
  text-align: center;
`;

const Title = styled.h1`
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 20px 0;

  @media screen and ${mediaDevices.mobile} {
    font-size: 40px;
    line-height: 40px;
  }
  @media screen and ${mediaDevices.desktop} {
    font-size: 72px;
    line-height: 72px;
  }
`;

const Caption = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: #d6e0e7;
  margin-bottom: 50px;
`;

export const Jumbotron = () => (
  <Section>
    <StyledJumbotron>
      <Title>POWER UP WITH VIDEO GAME INVESTMENTS</Title>
      <Caption>
        Discover investment-ready games vetted by industry <br /> experts.
      </Caption>
      <Link to={Routes.LOGIN_JOIN}>
        <Button>Get started</Button>
      </Link>
    </StyledJumbotron>
  </Section>
);
