import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {mediaDevices} from '../../../lib/styled';
import {ReactComponent as LineSvg} from './line.svg';
import {ReactComponent as CreateIcon} from './create.svg';
import {ReactComponent as ExploreIcon} from './explore.svg';
import {ReactComponent as ReviewIcon} from './review.svg';
import {ReactComponent as SelectIcon} from './select.svg';
import {Section} from '../Section';
import {Title} from '../Title';
import {Routes} from '../../../constant';

const StyledStepsToInvestBlock = styled.div``;

const StepsGrid = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 40px -15px -15px -15px;

  @media screen and ${mediaDevices.mobile} {
    flex-direction: column;
    flex-wrap: wrap;
  }

  @media screen and ${mediaDevices.desktop} {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

const Step = styled.div`
  padding: 15px;

  @media screen and ${mediaDevices.mobile} {
    margin-bottom: 30px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and ${mediaDevices.desktop} {
    margin-bottom: 0;
    width: 25%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const IconWrapper = styled.div`
  position: relative;
  background-color: rgba(73, 92, 107, 0.3);
  width: 96px;
  height: 96px;
  flex-shrink: 0;
  margin-right: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 48px;
    height: 48px;
  }
`;

const StyledLineSvg = styled(LineSvg)`
  @media screen and ${mediaDevices.mobile} {
    visibility: hidden;
  }

  @media screen and ${mediaDevices.desktop} {
    visibility: visible;
  }
`;

const ReversedLineSvg = styled(StyledLineSvg)`
  transform: scale(1, -1);
`;

const Label = styled.div`
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
  margin-bottom: 4px;
`;

const Text = styled.div`
  font-size: 15px;
  line-height: 24px;
  color: #d6e0e7;
`;

export const StepsToInvestBlock = () => (
  <Section edged={true}>
    <StyledStepsToInvestBlock>
      <Title>Steps to Invest</Title>

      <StepsGrid>
        <Step>
          <Header>
            <IconWrapper>
              <CreateIcon />
            </IconWrapper>
            <StyledLineSvg />
          </Header>
          <Label>Create an account</Label>
          <Text>
            <Link to={Routes.LOGIN_JOIN}>Create an account</Link>, submit your profile, and get
            approved in days.
          </Text>
        </Step>

        <Step>
          <Header>
            <IconWrapper>
              <ExploreIcon />
            </IconWrapper>
            <ReversedLineSvg />
          </Header>
          <Label>Explore games</Label>
          <Text>
            Browse Xsolla&apos;s expertly pre-qualified games portfolio according to your investment
            criteria. Or search by genre, budget, and more.
          </Text>
        </Step>

        <Step>
          <Header>
            <IconWrapper>
              <ReviewIcon />
            </IconWrapper>
            <StyledLineSvg />
          </Header>
          <Label>Review opportunity</Label>
          <Text>
            Co-invest with confidence. Choose your investment amount and submit. Xsolla takes care
            of the rest.
          </Text>
        </Step>

        <Step>
          <Header>
            <IconWrapper>
              <SelectIcon />
            </IconWrapper>
          </Header>
          <Label>Select investment</Label>
          <Text>
            Fixed terms. Powerful potential. Xsolla handles everything from legal compliance to
            Quarterly Revenue Distribution.
          </Text>
        </Step>
      </StepsGrid>
    </StyledStepsToInvestBlock>
  </Section>
);
