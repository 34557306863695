import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import Slider, {Settings as SliderSettings} from 'react-slick';
import styled from 'styled-components';
import {SlickSlider} from '../../../@ui';
import {DiscoverSlickPlaceholder} from '../../../@placeholders/DiscoverSlickPlaceholder';
import {DiscoverSliderControls, eventName} from './DiscoverSliderControls';
import {useAsyncRequest} from '../../../../hooks/useAsyncRequest';
import {requestDiscover} from '../../../../utils/requester/discover';
import {selectApplicationCompanyID} from '../../../../redux/selectors/application';
import {CardProps, DiscoverSliderCard} from '../DiscoverSliderCard';
import {DiscoverPageSectionSize, DiscoverPageSectionType} from '../../../../redux/types/discover';
import {discoverPageSectionSize} from '../../../../lib/discover-page';
import {DiscoverPageSection} from '../../DiscoverPageSection';

const StyledSliderGamesContainer = styled.div``;

const SlickSliderControls = styled.div`
  display: flex;
  justify-content: center;
`;

export interface DiscoverSliderGamesProps {
  label: string;
  section: DiscoverPageSectionType;
  description?: string;
}

const slickAnimationSpeed = 350;
const smallSliderSlidesToShow = 2;

export const DiscoverSliderGames = ({section, ...rest}: DiscoverSliderGamesProps) => {
  const companyID = useSelector(selectApplicationCompanyID);
  const sliderRef = useRef<Slider>();
  const controlsRef = useRef<HTMLDivElement>();
  const [games, setGames] = useState([]);
  const [hovered, setHovered] = useState<boolean>(false);

  const onChangeCard = (event: CustomEvent) => {
    if (!sliderRef.current) {
      return;
    }

    if (event.detail.type === 'next') {
      return sliderRef.current.slickNext();
    }

    if (event.detail.type === 'prev') {
      return sliderRef.current.slickPrev();
    }
  };

  const [fetching] = useAsyncRequest(async () => {
    const response = await requestDiscover(companyID, section);
    setGames(response.games);
  }, [companyID]);

  useEffect(() => {
    controlsRef?.current?.addEventListener(eventName, onChangeCard);
    return () => {
      controlsRef?.current?.removeEventListener(eventName, onChangeCard);
    };
  }, [fetching, games]);

  /**
   * Get slider settings depend on slider games type
   */
  const getSliderSettings = (): SliderSettings => {
    if (discoverPageSectionSize(section) === DiscoverPageSectionSize.SMALL_SLIDER) {
      return {
        slidesToShow: smallSliderSlidesToShow,
        slidesToScroll: 1,
        adaptiveHeight: false
      };
    }

    return {};
  };

  /**
   * Get slider controls length
   */
  const getSliderControlsLength = (): number => {
    if (
      discoverPageSectionSize(section) === DiscoverPageSectionSize.SMALL_SLIDER &&
      games?.length === smallSliderSlidesToShow
    ) {
      return 0;
    }

    return games?.length || 0;
  };

  if (fetching) {
    return <DiscoverSlickPlaceholder {...rest} section={section} />;
  }

  if (!games.length) {
    return null;
  }

  return (
    <DiscoverPageSection section={section} {...rest}>
      <StyledSliderGamesContainer>
        <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
          <SlickSlider
            sliderRef={sliderRef}
            settings={{...getSliderSettings(), speed: slickAnimationSpeed}}>
            {games.map((game: CardProps) => (
              <DiscoverSliderCard key={`${section}-${game.id}`} game={game} section={section} />
            ))}
          </SlickSlider>

          <SlickSliderControls ref={controlsRef}>
            <DiscoverSliderControls
              count={getSliderControlsLength()}
              hovered={hovered}
              animationTimeout={slickAnimationSpeed}
            />
          </SlickSliderControls>
        </div>
      </StyledSliderGamesContainer>
    </DiscoverPageSection>
  );
};
