import React, {ReactNode} from 'react';
import {DiscoverPageSectionSize, DiscoverPageSectionType} from '../../../redux/types/discover';
import {DiscoverPageSectionSmall} from './DiscoverPageSectionSmall';
import {DiscoverPageSectionDefault} from './DiscoverPageSectionDefault';
import {discoverPageSectionSize} from '../../../lib/discover-page';

interface DiscoverPageSectionProps {
  label: string;
  section: DiscoverPageSectionType;
  children: ReactNode;
  description?: string;
}

export const DiscoverPageSection = ({section, children, ...rest}: DiscoverPageSectionProps) => {
  if (discoverPageSectionSize(section) === DiscoverPageSectionSize.SMALL_SLIDER) {
    return <DiscoverPageSectionSmall {...rest}>{children}</DiscoverPageSectionSmall>;
  }

  return <DiscoverPageSectionDefault {...rest}>{children}</DiscoverPageSectionDefault>;
};
