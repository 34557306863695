import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Icon} from '@xsolla/uikit';
import {Routes} from '../constant';

const StyledLoginBackLink = styled.div`
  text-align: center;
  margin-top: 24px;
`;

const BackLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  margin: 0 auto;

  & i {
    margin-right: 5px;
    transform: rotate(180deg);
  }
`;

export const LoginBackLink = () => (
  <StyledLoginBackLink>
    <BackLink to={Routes.LOGIN}>
      <Icon name="arrow" />
      <span>Back to Log In</span>
    </BackLink>
  </StyledLoginBackLink>
);
