import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface TitleProps {
  children: ReactNode;
}

const StyledTitle = styled.h2`
  margin: 0 0 20px 0;
  font-weight: 900;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
`;

export const Title = ({children, ...rest}: TitleProps) => (
  <StyledTitle {...rest}>{children}</StyledTitle>
);
