import {createSelector} from 'reselect';
import {RootState} from '../types/store';

const selectUser = createSelector(
  (state: RootState) => state.user,
  user => user
);

export const selectUserInvestorProfile = createSelector(selectUser, user => user.investorProfile);
export const selectUserNotifications = createSelector(selectUser, user => user.notifications);
