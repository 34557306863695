import React from 'react';
import {InjectedIntl, injectIntl} from 'react-intl';
import {format} from 'date-fns';
import {Tag} from '../../@ui';
import {CardImage} from '../../CardImage';
import {MinimalGame} from '../../../redux/reducer/games';
import {getFormattedNumber} from '../../../utils/formatter';
import {getDiscoverGameMonetizationTypeTag} from '../../../lib/discover-page';
import {getFormattedGameType} from '../../../utils/formatter/from-json-value';
import {StatusLabel} from '../../StatusLabel';
import {AnalyticsEvent} from '../../../lib/XsollaAnalytics';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {DiscoverPageSectionType} from '../../../redux/types/discover';
import {
  Card,
  FundingGoal,
  GameName,
  ImageContainer,
  Info,
  InfoHeader,
  PropertyGrid,
  PropertyGridDivider,
  PropertyLabel,
  TagContainer,
  TargetReleseDate
} from './styled';

interface GameCardProps {
  intl: InjectedIntl;
  game: MinimalGame;
  showTags?: boolean;
  daysLeft?: number;
  exclusive?: boolean;
  section: DiscoverPageSectionType;
}

export const GameCard = injectIntl(({intl, game, section, showTags = false}: GameCardProps) => {
  const analytics = useAnalytics();

  const onGameCardClick = () => {
    analytics.click(AnalyticsEvent.GameCardClick, {
      exv: {
        section: section,
        game_id: game.id,
        game_name: game.game_name
      }
    });
  };

  return (
    <Card to={`/office/game/${game.id}/detail`} onClick={onGameCardClick}>
      <ImageContainer>
        <CardImage src={game.header_image_url} placeholder={game.game_name} />
      </ImageContainer>

      <Info>
        <InfoHeader>
          <GameName>{game.game_name}</GameName>
        </InfoHeader>

        <PropertyGrid>
          <div>
            <PropertyLabel>Target release date</PropertyLabel>
            <TargetReleseDate>
              {game.game_already_launched
                ? 'Already launched'
                : format(new Date(game.launch_date), 'MMM d, yyyy')}
            </TargetReleseDate>
          </div>
          <div>
            <PropertyGridDivider />
          </div>
          <div>
            <PropertyLabel>Funding goal</PropertyLabel>
            <FundingGoal>${getFormattedNumber(game.requested_amount)}</FundingGoal>
          </div>
        </PropertyGrid>

        {game.partially_funded && <StatusLabel $color="default">Partially funded</StatusLabel>}

        {showTags && (
          <TagContainer>
            <Tag>
              {getFormattedGameType(game.game_type, intl)}
              {getDiscoverGameMonetizationTypeTag(game.monetization_type)}
            </Tag>
          </TagContainer>
        )}
      </Info>
    </Card>
  );
});
