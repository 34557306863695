import React from 'react';
import styled, {keyframes} from 'styled-components';
import {GradientReplacer, useGradientImageReplacer} from '../../hooks/useImageReplacer';

interface ImageReplacerProps {
  children: string;
}

const imageReplacerKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledImageReplacer = styled.div<{gradient: GradientReplacer}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 32px;
  color: #fff;
  font-size: 22px;
  line-height: 24px;
  font-weight: bold;
  word-break: break-all;
  text-align: center;
  background-image: linear-gradient(25deg, ${p => p.gradient.from} 0%, ${p => p.gradient.to} 100%);
  animation: ${imageReplacerKeyframes} ease-in-out 80ms;
`;

export const ImageReplacer = ({children}: ImageReplacerProps) => {
  const [gradient] = useGradientImageReplacer();
  return <StyledImageReplacer gradient={gradient}>{children}</StyledImageReplacer>;
};
