import React, {useRef, useState} from 'react';
import {InjectedIntl, injectIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {NavLink, useHistory} from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import {Dropdown, Icon} from '@xsolla/uikit';
import {UserPanel} from '../../../@ui';
import {Routes} from '../../../../constant';
import {logOutApplication} from '../../../../redux/action/application';
import {actionUserLogout} from '../../../../redux/action/user';

interface OfficeUserPanelProps {
  intl: InjectedIntl;
}

export const OfficeUserPanel = injectIntl(({intl}: OfficeUserPanelProps) => {
  const rootRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [active, setActive] = useState(false);

  useOnClickOutside(rootRef, () => setActive(false));

  const onClickLogout = () => {
    dispatch(actionUserLogout());
    dispatch(logOutApplication());
    history.replace('/');
  };

  return (
    <div ref={rootRef}>
      <Dropdown
        align="right"
        options={[
          {
            label: intl.formatMessage({id: 'app.header.profile.settings'}),
            value: 'settings',
            customIcon: <Icon name="settings" />,
            tag: (
              <NavLink to={Routes.SETTINGS_PAGE}>
                {intl.formatMessage({id: 'app.header.profile.settings'})}
              </NavLink>
            )
          },
          {
            label: intl.formatMessage({id: 'app.header.profile.logout'}),
            value: 'logout',
            customIcon: <Icon name="logout" />,
            onClick: onClickLogout
          }
        ]}
        onClick={() => setActive(true)}>
        <UserPanel active={active} />
      </Dropdown>
    </div>
  );
});
