import {XSOLLA_RESET_PASSWORD_LOGIN_URL} from '../../../constant';
import {getLoginProjectID} from '../../../lib/login';
import {loginApiClient} from '../../axios/client/login';
import {request} from '../index';

const loginProjectID = getLoginProjectID();

export const resetPasswordRequest = ({username}) => {
  return request(
    loginApiClient,
    'post',
    `api/password/reset/request?projectId=${loginProjectID}&login_url=${XSOLLA_RESET_PASSWORD_LOGIN_URL}`,
    {
      username
    }
  );
};

export const confirmResetPasswordRequest = ({userID, resetCode, newPassword}) => {
  return request(loginApiClient, 'post', `api/password/reset/confirm?projectId=${loginProjectID}`, {
    user_id: userID,
    reset_code: resetCode,
    new_password: newPassword
  });
};
