import React from 'react';
import styled from 'styled-components';
import {mediaDevices} from '../../../lib/styled';
import {ReactComponent as BarChart} from './bar.svg';
import pieImage from './pie.png';
import pieImage2x from './pie@2x.png';
import {Caption, Section, Title} from '../index';
import {Highlight} from './Highlight';
import {subTitleTypography} from '../typography';

const StyledGrowBlock = styled.div``;

const SubTitle = styled.h2`
  ${subTitleTypography};
  margin: 0 0 60px 0;
`;

const Highlights = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
`;

const Charts = styled.div`
  display: flex;
  margin-bottom: 40px;

  @media screen and ${mediaDevices.mobile} {
    flex-direction: column;
    & > * {
      margin-right: 0;
    }
  }

  @media screen and ${mediaDevices.desktop} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    & > * {
      margin-right: 70px;
    }
  }
`;

const StyledBarChart = styled(BarChart)`
  display: block;
  width: 100%;
`;

const StyledPieChart = styled.img`
  @media screen and ${mediaDevices.mobile} {
    flex-shrink: 1;
    width: 100%;
  }
  @media screen and ${mediaDevices.desktop} {
    flex-shrink: 0;
  }
`;

const Source = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #d6e0e7;
`;

export const GrowBlock = () => (
  <Section edged={true}>
    <StyledGrowBlock>
      <Title>Grow with the video game industry</Title>
      <Caption>Invest in entertainment&apos;s largest, fastest-growing global industry.</Caption>

      <Highlights>
        <Highlight label="invested in 2020*" value="$9.6B" />
        <Highlight label="year/year growth*" value="13.3%" />
        <Highlight label="revenue in 2020" value="$164B+" />
      </Highlights>

      <Charts>
        <div>
          <SubTitle>Video game market profit forecast*</SubTitle>
          <StyledBarChart />
        </div>
        <div>
          <SubTitle>2019 profit by device*</SubTitle>
          <StyledPieChart src={pieImage} srcSet={`${pieImage2x} 2x`} alt="pie" />
        </div>
      </Charts>

      <Source>*source: Newzoo | 2019 Global Games market report</Source>
    </StyledGrowBlock>
  </Section>
);
