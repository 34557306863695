import {GTM_ID} from '../../constant/config';

export const injectGtm = () => {
  if (!GTM_ID) {
    return;
  }

  const code = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`;

  const scriptNode = document.createElement('script');
  const scriptText = document.createTextNode(code);
  scriptNode.appendChild(scriptText);
  document.head.appendChild(scriptNode);
};
