import styled, {css} from 'styled-components';

export const StyledOfficeLayout = styled.div`
  padding: 0 32px;
  width: 1040px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: content-box;

  & > * {
    width: 100%;
  }
`;

export const Entry = styled.div<{tribeFix: boolean}>`
  margin: 40px 0;
  width: 100%;

  ${p =>
    p.tribeFix
      ? css`
          min-height: calc(100vh - 312px);
        `
      : css`
          min-height: calc(100vh - 264px);
        `}
`;

export const Bottom = styled.div`
  margin-top: auto;
`;
