import {AnyAction} from 'redux';
import {
  USER_LOGOUT,
  USER_UPDATE,
  USER_UPDATE_ACCOUNT,
  USER_UPDATE_INVESTOR_PROFILE,
  USER_UPDATE_NOTIFICATIONS
} from '../reducer/user';
import {User, UserNotifications} from '../types/user';
import {InvestorProfile, UserAccount} from '../../api/user';
/**
 * @param user
 */
export const actionUserUpdate = (user: User): AnyAction => ({
  type: USER_UPDATE,
  payload: user
});

/**
 *
 * @param account
 */
export const actionUserUpdateAccount = (account: Partial<UserAccount>): AnyAction => ({
  type: USER_UPDATE_ACCOUNT,
  payload: account
});

/**
 *
 * @param investorProfile
 */
export const actionUserUpdateInvestorProfile = (investorProfile: InvestorProfile): AnyAction => ({
  type: USER_UPDATE_INVESTOR_PROFILE,
  payload: investorProfile
});

/**
 * @param notifications
 */
export const actionUserUpdateNotifications = (notifications: UserNotifications): AnyAction => ({
  type: USER_UPDATE_NOTIFICATIONS,
  payload: notifications
});

/**
 */
export const actionUserLogout = (): AnyAction => ({
  type: USER_LOGOUT
});
