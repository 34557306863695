import {AnyAction} from 'redux';
import {DiscoverFilterValue} from '../types';
import {DiscoverPageSectionType, DiscoverSearchPage} from '../types/discover';

interface DiscoverFilterState {
  fetching: boolean;
  filterCount: number;
  value: DiscoverFilterValue;
  searchSectionPage: DiscoverSearchPage;
}

export const defaultDiscoverFilterValue: DiscoverFilterValue = {
  investment_prefer_range: [],
  game_engine: [],
  monetization_model: [],
  subgenre: [],
  genres: [],
  platforms: [],
  query: '',
  sort: 'newest'
};

const initialState: DiscoverFilterState = {
  fetching: false,
  filterCount: 0,
  searchSectionPage: null,
  value: defaultDiscoverFilterValue
};

/**
 * Calculate current filters count
 * @param currentFilters
 */
const calcFilterCount = (currentFilters: DiscoverFilterValue): number => {
  const initialFilters = initialState.value;

  return Object.keys(initialFilters).reduce((total, key) => {
    if (JSON.stringify(currentFilters[key]) !== JSON.stringify(initialFilters[key])) {
      return total + 1;
    }
    return total;
  }, 0);
};

export const SET_FILTER_FETCHING = '@/discoverFilter/SET_FILTER_FETCHING';
export const SET_FILTER_VALUE = '@/discoverFilter/SET_FILTER_VALUE';
export const SET_FILTER_SORT = '@/discoverFilter/SET_FILTER_SORT';
export const SET_SEARCH_PAGE = '@/discoverFilter/SET_SEARCH_PAGE';

export const discoverFilterReducer = (
  state = initialState,
  action: AnyAction
): DiscoverFilterState => {
  switch (action.type) {
    case SET_FILTER_FETCHING:
      return {...state, fetching: action.payload.fetching};
    case SET_FILTER_VALUE: {
      const filters = action.payload.value;
      const filterCount = calcFilterCount(filters);
      let sectionPage = state.searchSectionPage;
      if (filterCount && !state.searchSectionPage) {
        sectionPage = DiscoverPageSectionType.BROWSE;
      }
      return {
        ...state,
        searchSectionPage: sectionPage,
        filterCount: filterCount,
        value: {...state.value, ...filters}
      };
    }
    case SET_FILTER_SORT:
      return {...state, value: {...state.value, sort: action.payload.sort}};
    case SET_SEARCH_PAGE:
      return {...state, searchSectionPage: action.payload.searchSectionPage};
    default:
      return state;
  }
};
