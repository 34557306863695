import React from 'react';
import styled from 'styled-components';
import {DiscoverPageSectionDefaultProps} from './DiscoverPageSectionDefault';

const StyledPageSectionContainer = styled.div`
  display: flex;
`;

const HeaderContainer = styled.div`
  width: 310px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const Label = styled.div`
  display: inline-block;
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
  letter-spacing: -0.2px;
  margin-bottom: 8px;
`;

const Description = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: var(--colorTextSecondary);
`;

const Children = styled.div`
  overflow: hidden;
  width: 100%;
  flex: auto;
`;

type DiscoverPageSectionSmallProps = DiscoverPageSectionDefaultProps;

export const DiscoverPageSectionSmall = ({
  label,
  description,
  children
}: DiscoverPageSectionSmallProps) => {
  return (
    <StyledPageSectionContainer>
      <HeaderContainer>
        {label && <Label>{label}</Label>}
        {description && <Description>{description}</Description>}
      </HeaderContainer>
      <Children>{children}</Children>
    </StyledPageSectionContainer>
  );
};
