import React, {useEffect, useState} from 'react';
import {FieldArrayRenderProps} from 'react-final-form-arrays';
import {Checkbox, FormGroup, Notification} from '@xsolla/uikit';
import classNames from 'classnames/bind';
import {FieldValue, getFieldMetaError} from '../../../lib/form';
import style from './style.module.scss';

interface Option {
  id: string;
  label: string;
  checked?: boolean;
  value: FieldValue;
}

export interface MultiCheckboxProps extends FieldArrayRenderProps<FieldValue, HTMLInputElement> {
  options: Option[];
  maySelectAll?: boolean;
  removeAll?: (fieldName: string) => void;
  // ToDo:: че те надо придумать, либо свой полностью checkbox юзать
  classNames?: string;
}

interface MultiCheckboxInnerProps extends MultiCheckboxProps {
  setAllSelected: (value: boolean) => void;
}

interface SelectedButtonProps {
  maySelectAll: boolean;
  allSelected: boolean;
  setSelected: (value: boolean) => void;
}

const cx = classNames.bind(style);

const SelectedButton = ({maySelectAll, allSelected, setSelected}: SelectedButtonProps) => {
  if (maySelectAll) {
    return (
      <div
        className={cx(style.multiCheckbox, style.selectAll, !allSelected && style.active)}
        onClick={() => setSelected(!allSelected)}>
        {allSelected ? 'Unselect all' : 'Select all'}
      </div>
    );
  }

  return null;
};

const MultiCheckboxInner = ({
  fields,
  meta,
  classNames,
  options,
  setAllSelected
}: MultiCheckboxInnerProps) => {
  const [touched, setTouched] = useState(false);
  const fieldsValue = fields.value || [];
  const error = getFieldMetaError(meta, {forced: touched});

  useEffect(() => {
    setAllSelected(fields.value?.length === options.length);
  }, [fields, options]);

  const onChange = (value: FieldValue) => {
    setTouched(true);

    if (fieldsValue.includes(value)) {
      fields.remove(fieldsValue.indexOf(value));
      return;
    }

    fields.insert(0, value);
  };

  return (
    <>
      <div className={cx(style.multiCheckbox, !!error && style.error, classNames)}>
        {options.map(option => (
          <FormGroup key={`${fields.name}.${option.id}`} indentation="sm">
            <Checkbox
              input={{
                name: option.id,
                value: fieldsValue.includes(option.id),
                onChange: () => onChange(option.id)
              }}
              meta={{}}
              label={option.label}
            />
          </FormGroup>
        ))}
      </div>

      {error && (
        <Notification appearance="string" status="error">
          {error}
        </Notification>
      )}
    </>
  );
};

export const MultiCheckbox = ({
  fields,
  meta,
  options,
  classNames,
  maySelectAll = false,
  removeAll = () => void 0
}: MultiCheckboxProps) => {
  const [allSelected, setAllSelected] = useState(false);

  const selectAll = () => {
    options.forEach(option => {
      if (!fields.value.includes(option.id)) {
        fields.push(option.id);
      }
    });
  };

  return (
    <div className={cx(style.multiCheckboxContainer)}>
      <SelectedButton
        maySelectAll={maySelectAll}
        allSelected={allSelected}
        setSelected={(value: boolean) => {
          if (value) {
            selectAll();
          } else {
            removeAll(fields.name);
          }

          setAllSelected(value);
        }}
      />
      <MultiCheckboxInner
        fields={fields}
        options={options}
        meta={meta}
        setAllSelected={setAllSelected}
        classNames={classNames}
      />
    </div>
  );
};
