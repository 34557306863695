export enum Routes {
  LOGIN = '/auth/login',
  LOGIN_JOIN = '/auth/signup',
  LOGIN_RESET = '/auth/forgot-password',
  LOGIN_CHANGE = '/auth/change-password',
  VERIFY = '/auth/verify',
  ONBOARDING = '/onboarding',
  OFFICE_DISCOVER = '/office/discover',
  OFFICE_GAMEPAGE = '/office/game/:game_id/detail',
  SETTINGS_PAGE = '/settings',
  SETTINGS_USER_ACCOUNT = '/settings/account',
  SETTINGS_USER_INVESTOR_PROFILE = '/settings/investor',
  SETTINGS_USER_NOTIFICATIONS = '/settings/notifications'
}
