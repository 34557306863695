import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface CaptionProps {
  children: ReactNode;
}

const StyledCaption = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: #d6e0e7;
  margin: 0 auto 50px auto;
  text-align: center;
  max-width: 570px;
`;

export const Caption = ({children}: CaptionProps) => <StyledCaption>{children}</StyledCaption>;
