import React from 'react';
import {ReactComponent as SuccessIcon} from '../../images/icons/success.svg';
import {getEmailFromToken} from '../../lib/jwtToken';
import {FUNDING_CLUB_EMAIL} from '../../constant';
import styled from 'styled-components';

const StyledConfirmationPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 82px;
`;

const Title = styled.div`
  margin: 8px 0 8px 0;
  font-size: 22px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
`;

const Entry = styled.div`
  text-align: center;
`;

export const ConfirmationPage = () => {
  return (
    <StyledConfirmationPage>
      <SuccessIcon />
      <Title>Application sent</Title>
      <Entry>
        <p>
          We have received your application from {getEmailFromToken()}. After evaluation (within 3-5
          working days), you’ll receive an email with further information and Investor Account
          access if approved.
        </p>
        <p>
          If you have questions, please address
          <br />
          <a href={`mailto: ${FUNDING_CLUB_EMAIL}`}>{FUNDING_CLUB_EMAIL}</a>.
        </p>
      </Entry>
    </StyledConfirmationPage>
  );
};
