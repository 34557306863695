import styled from 'styled-components';

export const StyledOnboardingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 532px;
`;

export const LoaderContainer = styled.div`
  align-self: center;
`;
