import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, RouteProps, useLocation} from 'react-router-dom';
import {selectApplicationLoggedIn} from '../redux/selectors/application';
import {selectOnboardingStatus} from '../redux/selectors/onboarding';
import {OnboardingStatus} from '../constants/OnboardingStatus';
import {Routes} from '../constant';

export const PrivateRoute = ({...rest}: RouteProps) => {
  const location = useLocation();
  const loggedIn = useSelector(selectApplicationLoggedIn);
  const onboardingStatus = useSelector(selectOnboardingStatus);

  if (!loggedIn) {
    return (
      <Redirect
        to={{
          pathname: Routes.LOGIN,
          search: `?redirect=${location.pathname}`
        }}
      />
    );
  }

  if (!onboardingStatus) {
    return <></>;
  }

  if (onboardingStatus !== OnboardingStatus.APPROVED && location.pathname !== Routes.ONBOARDING) {
    return <Redirect to={{pathname: Routes.ONBOARDING}} />;
  }

  return <Route {...rest} />;
};
