import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';

const LoadStatus = ({children, status, withoutIcon = false}) => {
  return (
    <div className={cn(style.submit_status, style[`submit_status_${status}`])}>
      <div className={style.submit_status__children}>{children}</div>
      {!withoutIcon ? (
        <div className={cn(style.submit_status__icon, style[`submit_status__icon_${status}`])} />
      ) : null}
    </div>
  );
};

export default LoadStatus;
