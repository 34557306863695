import React, {ReactNode} from 'react';
import styled from 'styled-components';

const StyledHeaderContainer = styled.div`
  margin-bottom: 40px;
`;

const StyledLabel = styled.div`
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  margin-right: 12px;
`;

const StyledDescription = styled.div`
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  color: var(--colorTextSecondary);
`;

const StyledChildren = styled.div`
  overflow: hidden;
  width: 100%;
`;

const StyledPageSectionContainer = styled.div``;

interface PageSectionProps {
  children: ReactNode;
  label?: ReactNode;
  description?: ReactNode;
}

export const PageSection = ({children, label, description}: PageSectionProps) => {
  return (
    <StyledPageSectionContainer>
      <StyledHeaderContainer>
        {label && <StyledLabel>{label}</StyledLabel>}
        {description && <StyledDescription>{description}</StyledDescription>}
      </StyledHeaderContainer>
      <StyledChildren>{children}</StyledChildren>
    </StyledPageSectionContainer>
  );
};
