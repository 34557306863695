import jwt from 'jsonwebtoken';
import {COOKIES, getFromCookie, setToCookie} from '../lib/cookies';
import {extractUrlValue} from '../utils';
import {investorApiClient as investorApiClientLegacy} from '../utils/axios/client/investor';
import {investorApiClient} from '../api/apiClient';

export interface JWTPayload {
  exp: number;
  username: string | undefined;
  name: string | undefined;
  picture: string | undefined;
  sub: string;
}

/**
 * Checks jwt token for date validity.
 * @param token
 * @returns {boolean}
 */
export const validateToken = (token: string): boolean => {
  if (!token) {
    return false;
  }

  const {exp} = jwt.decode(token);
  return Math.round(Date.now() / 1000) <= exp;
};

export const getTokenParam = <K extends keyof JWTPayload>(
  token: string | undefined,
  key: K
): JWTPayload[K] | undefined => {
  if (!token || !validateToken(token)) {
    return;
  }

  const payload = jwt.decode(token) as JWTPayload;
  return payload[key];
};

/**
 * Get user email from token
 * @returns {string}
 */
export const getEmailFromToken = (): string => {
  const token = getFromCookie(COOKIES.PRODUCT_COOKIE_TOKEN);
  if (validateToken(token)) {
    const {username} = jwt.decode(token);
    return username;
  }

  return '';
};

export const getLoginIdFromToken = (): string | undefined => {
  const token = getFromCookie(COOKIES.PRODUCT_COOKIE_TOKEN);
  return getTokenParam(token, 'sub');
};

/**
 * Return user name from jwt token
 * @returns {string}
 */
export const getNameFromToken = (): string => {
  const token = getFromCookie(COOKIES.PRODUCT_COOKIE_TOKEN);
  if (validateToken(token)) {
    const {name} = jwt.decode(token);
    return name;
  }

  return '';
};

/**
 * Setup token to cookie
 * @param token
 */
export const setupToken = (token: string): void => {
  if (!validateToken(token)) {
    throw Error('Invalid jwt token');
  }

  investorApiClientLegacy.defaults.headers = {
    ...investorApiClientLegacy.defaults.headers,
    ...{Authorization: token}
  };

  investorApiClient.defaults.headers = {
    ...investorApiClient.defaults.headers,
    ...{Authorization: token}
  };

  if (getFromCookie(COOKIES.PRODUCT_COOKIE_TOKEN) !== token) {
    setToCookie(COOKIES.PRODUCT_COOKIE_TOKEN, token);
  }
};

/**
 * Returns token from url address string
 * @param urlString
 */
export const getTokenFromUrl = (urlString: string): string => {
  if (urlString) {
    const url = new URL(urlString);
    return url.searchParams.get('token') || extractUrlValue('token', urlString);
  }
};
