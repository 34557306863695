import React from 'react';
import styled from 'styled-components';
import {XSOLLA_POLICIES_LINK, XSOLLA_TERMS_LINK} from '../constant';

const StyledFooter = styled.div`
  margin: 40px 0;
  color: #747e8a;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Links = styled.div`
  display: flex;
  align-items: center;

  & a {
    color: inherit;
  }

  & span {
    margin: 0 5px;
  }
`;

export const Footer = () => (
  <StyledFooter>
    <div>Copyright © 2006 - {new Date().getFullYear()} Xsolla Inc.</div>

    <Links>
      <a href={XSOLLA_TERMS_LINK} target="_blank" rel="noopener noreferrer">
        Terms of use
      </a>
      <span>|</span>
      <a href={XSOLLA_POLICIES_LINK} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
    </Links>
  </StyledFooter>
);
