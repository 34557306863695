import React, {ReactNode, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MinimalGame} from '../../../../redux/reducer/games';
import {GameCardsPlaceholder} from '../../../@placeholders/GameCardsPlaceholder';
import {dispatchDiscoverRequest} from '../../../../api/discover';
import {selectApplicationCompanyID} from '../../../../redux/selectors/application';
import {
  setDiscoverRecommendedFilterValue,
  setDiscoverSearchPage
} from '../../../../redux/action/discover-filter';
import {ManualList} from '../../../manual-list';
import {InfiniteList} from '../../../infinite-list';
import {DiscoverPageSectionType} from '../../../../redux/types/discover';
import {DiscoverGamesGrid} from '../DiscoverGamesGrid';
import {DISCOVER_PAGE_DEFAULT_LIMIT} from '../../../../constant';
import {DiscoverPageSection} from '../../DiscoverPageSection';
import {ToastType, useToasts} from '../../../ToastProvider';

interface DiscoverGamesProps {
  section: DiscoverPageSectionType;
  label: string;
  description?: string;
  limit?: number;
}

export const DiscoverGames = ({
  section,
  limit = DISCOVER_PAGE_DEFAULT_LIMIT,
  ...rest
}: DiscoverGamesProps) => {
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const [games, setGames] = useState<MinimalGame[]>([]);
  const [requested, setRequested] = useState(false);
  const companyID = useSelector(selectApplicationCompanyID);

  let Component: ReactNode = null;

  const setSearchPage = () => {
    window.scrollTo(0, 0);
    dispatch(setDiscoverSearchPage(section));
  };

  /**
   * Request page data
   * @param page - page number
   * @param limit - limit games
   */
  const requestGame = async (page: number, limit: number) => {
    try {
      const response = await dispatchDiscoverRequest({
        type: section,
        companyID,
        filters: {
          params: {page, limit}
        }
      });
      setGames(prevGames => [
        ...prevGames,
        ...response.games.filter(game => {
          return !prevGames.some(g => g.id === game.id);
        })
      ]);
      return response.has_more_games;
    } catch (err) {
      addToast('An error has occurred', ToastType.Error);
    } finally {
      setRequested(true);
    }
  };

  const params = {
    requestMethod: requestGame,
    limit,
    loader: <GameCardsPlaceholder key={`${section}-placeholder`} internal={true} />
  };

  if (section === DiscoverPageSectionType.BROWSE) {
    Component = (
      <InfiniteList {...params}>
        <DiscoverGamesGrid<MinimalGame> games={games} section={section} />
      </InfiniteList>
    );
  }

  if (section === DiscoverPageSectionType.RECOMMENDED) {
    const onShowMoreClick = () => {
      setSearchPage();
      dispatch(setDiscoverRecommendedFilterValue());
    };
    Component = (
      <ManualList onClick={onShowMoreClick} {...params}>
        <DiscoverGamesGrid<MinimalGame> section={section} games={games} />
      </ManualList>
    );
  }

  if (section === DiscoverPageSectionType.NEW_EXCLUSIVES) {
    Component = (
      <ManualList {...params}>
        <DiscoverGamesGrid<MinimalGame> section={section} games={games} />
      </ManualList>
    );
  }

  if (section === DiscoverPageSectionType.NEW_GAMES) {
    Component = (
      <ManualList {...params}>
        <DiscoverGamesGrid<MinimalGame> section={section} games={games} />
      </ManualList>
    );
  }

  if (requested && !games?.length) {
    return null;
  }

  return (
    <DiscoverPageSection section={section} {...rest}>
      {Component}
    </DiscoverPageSection>
  );
};
