import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Select} from '@xsolla/uikit';
import {selectDiscoverFilterSort} from '../../../redux/selectors/discover-filter';
import {setDiscoverFilterSort} from '../../../redux/action/discover-filter';
import {getDiscoverSortOptions} from './sortOptions';
import style from './style.module.scss';

export const DiscoverSort = () => {
  const dispatch = useDispatch();
  const value = useSelector(selectDiscoverFilterSort);

  const onChange = (value: string) => {
    dispatch(setDiscoverFilterSort(value));
  };

  return (
    <div className={style.discoverSort}>
      <div className={style.text}>Sort by</div>
      <div className={style.select}>
        <Select
          options={getDiscoverSortOptions()}
          input={{
            value: value,
            onChange: onChange
          }}
        />
      </div>
    </div>
  );
};
