import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {mediaDevices} from '../../../lib/styled';
import {Button} from '../Button';
import {Section} from '../Section';
import {Routes} from '../../../constant';

const StyledJoinBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 16px;
  background-image: url(${require('./mask.svg')});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  @media screen and ${mediaDevices.mobile} {
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 40px;
  }
  @media screen and ${mediaDevices.tablet} {
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
    padding: 40px;
  }
  @media screen and ${mediaDevices.desktop} {
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
    padding: 60px 80px;
  }
`;

const Title = styled.div`
  font-weight: 900;
  line-height: 40px;
  text-transform: uppercase;

  @media screen and ${mediaDevices.mobile} {
    margin-bottom: 20px;
    font-size: 26px;
  }
  @media screen and ${mediaDevices.tablet} {
    margin-bottom: 0;
    font-size: 40px;
  }
  @media screen and ${mediaDevices.desktop} {
    margin-bottom: 0;
    font-size: 40px;
  }
`;

export const JoinBlock = () => (
  <Section edged={true}>
    <StyledJoinBlock>
      <Title>Ready to give it a try?</Title>
      <Link to={Routes.LOGIN_JOIN}>
        <Button>Join for free</Button>
      </Link>
    </StyledJoinBlock>
  </Section>
);
