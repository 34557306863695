export const screens = {
  mobile: '320px',
  tablet: '768px',
  desktop: '1024px'
};

export const mediaDevices = {
  mobile: `(min-width: ${screens.mobile})`,
  tablet: `(min-width: ${screens.tablet})`,
  desktop: `(min-width: ${screens.desktop})`
};
