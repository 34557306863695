import React from 'react';
import {Field, FormSpy} from 'react-final-form';
import {InjectedIntl, injectIntl} from 'react-intl';
import {Input} from '@xsolla/uikit';
import {composeValidators, email, required, url} from '../../../../utils/validators';
import {PageTitle} from '../Title';
import {FormGroup} from '../../../@ui/FormGroup';

interface Props {
  intl: InjectedIntl;
  settings?: boolean;
}

export const XFCPersonalInfoPage = injectIntl(({intl, settings = false}: Props) => {
  return (
    <FormSpy
      subscription={{values: true, initialValues: true}}
      render={props => (
        <>
          {!settings && <PageTitle title="app.page.onboardingpage.page-title.personal-info" />}

          <FormGroup
            label={intl.formatMessage({id: 'app.page.onboardingpage.first-name'})}
            isRequired>
            <Field name="first_name" component={Input} validate={required} />
          </FormGroup>

          <FormGroup
            label={intl.formatMessage({id: 'app.page.onboardingpage.last-name'})}
            isRequired>
            <Field name="last_name" component={Input} validate={required} />
          </FormGroup>

          {settings && (
            <FormGroup label={intl.formatMessage({id: 'app.page.settingspage.email'})} isRequired>
              <Field name="email" component={Input} validate={composeValidators(required, email)} />
            </FormGroup>
          )}

          <FormGroup
            label={intl.formatMessage({id: 'app.page.onboardingpage.job-title'})}
            isRequired>
            <Field name="job_title" component={Input} validate={required} />
          </FormGroup>

          <FormGroup
            label={intl.formatMessage({id: 'app.page.onboardingpage.work-email'})}
            tooltip={intl.formatMessage({id: 'app.page.onboardingpage.work-email.tooltip'})}
            isRequired={props.initialValues.work_email}>
            <Field
              name="work_email"
              validate={email}
              component={Input}
              offset="right"
              disabled={props.initialValues.work_email}
            />
          </FormGroup>

          {!settings && (
            <FormGroup
              label={intl.formatMessage({id: 'app.page.onboardingpage.social-url'})}
              tooltip={intl.formatMessage({id: 'app.page.onboardingpage.social-url.tooltip'})}
              isRequired>
              <Field
                name="social_url"
                component={Input}
                validate={composeValidators(required, url)}
                placeholder="https://www.linkedin.com/in/profile"
              />
            </FormGroup>
          )}
        </>
      )}
    />
  );
});
