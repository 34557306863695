import React from 'react';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';
import styled from 'styled-components';
import {Title, TitleAlign} from '../../component/@ui';
import {
  ChangePasswordForm,
  ForgotPasswordForm,
  LoginForm,
  SignupForm
} from '../../component/@forms/Auth';
import {LoginBackLink} from '../../component/LoginBackLink';
import {Brand, Footer} from '../../component/Promo';
import {Routes} from '../../constant';
import {useLocation} from 'react-router';

const EntryContainer = styled.div`
  display: flex;
  min-width: 900px;
  min-height: 500px;
  margin: 40px 0;
  justify-content: space-between;
  background-color: #0073f7;
`;

const Aside = styled.div`
  width: 50%;
  padding: 95px;
`;

const WelcomeTitle = styled.h1`
  font-family: var(--fontFamilyLogin);
  word-break: break-word;
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
  line-height: 72px;
  margin: 0 0 24px 0;
  text-transform: uppercase;
`;

const Caption = styled.div`
  font-family: var(--fontFamilyLogin);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const FormInner = styled.div`
  width: 100%;
  max-width: 468px;
  padding: 48px 64px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
  color: #00244d;
`;

const FormCaption = styled.div`
  text-align: center;
  margin: 0 0 32px 0;
`;

const ResetFormCaption = styled.div`
  text-align: center;
  margin: -24px 0 32px 0;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const TabLink = styled(NavLink)`
  width: 50%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  border-bottom: 1px solid #747e8a;
  color: inherit;
  transition: border-color ease-in-out 80ms, color ease-in-out 80ms;

  &.active {
    border-bottom: 2px solid #0073f7 !important;
    color: var(--mainTextColor) !important;
    font-weight: 700;
  }
`;

export const PromoXfcPage = () => {
  const location = useLocation();

  return (
    <div>
      <Brand label="Investor Account" />
      <EntryContainer>
        <Aside>
          <WelcomeTitle>Welcome to Xsolla Investor Account</WelcomeTitle>
          <Caption>
            Xsolla Investor Account is a convenient internal management interface for investors,
            where you get access to all qualified games including those that match your investment
            criteria.
          </Caption>
        </Aside>
        <Aside>
          <FormInner>
            <Switch>
              <Route path={['/', Routes.LOGIN_JOIN, Routes.LOGIN]} exact={true}>
                <Title align={TitleAlign.Center}>Xsolla Investor Account</Title>
                <Tabs>
                  <TabLink to={`${Routes.LOGIN_JOIN}${location.search}`} exact={true}>
                    Join now
                  </TabLink>
                  <TabLink to={`${Routes.LOGIN}${location.search}`} exact={true}>
                    Log in
                  </TabLink>
                </Tabs>
              </Route>
            </Switch>

            <Switch>
              <Route path="/" exact={true}>
                <Redirect to={Routes.LOGIN_JOIN} />
              </Route>

              <Route path={Routes.LOGIN_JOIN} exact={true}>
                <FormCaption>
                  Fill in the application form to join Xsolla Funding Club and get access to the
                  games portfolio.
                </FormCaption>
                <SignupForm />
              </Route>

              <Route path={Routes.LOGIN} exact={true} component={LoginForm} />

              <Route path={Routes.LOGIN_RESET} exact={true}>
                <Title align={TitleAlign.Center}>Forgot password?</Title>
                <ResetFormCaption>
                  A link to change your password will be sent to your email.
                </ResetFormCaption>
                <ForgotPasswordForm />
                <LoginBackLink />
              </Route>

              <Route path={Routes.LOGIN_CHANGE} exact={true}>
                <Title align={TitleAlign.Center}>Change password</Title>
                <ResetFormCaption>Create a new password for your account.</ResetFormCaption>
                <ChangePasswordForm />
                <LoginBackLink />
              </Route>
            </Switch>
          </FormInner>
        </Aside>
      </EntryContainer>
      <Footer />
    </div>
  );
};
