import React, {
  DependencyList,
  Fragment,
  ReactElement,
  ReactNode,
  cloneElement,
  useEffect,
  useState
} from 'react';
import classNames from 'classnames/bind';
import {Button} from '@xsolla/uikit';
import {useAsyncRequest} from '../../hooks/useAsyncRequest';
import style from './style.module.scss';

interface ManualListProps {
  children: ReactNode;
  requestMethod: (offsetFactor: number, limit: number) => Promise<boolean>;
  limit: number;
  loader: ReactElement;
  deps?: DependencyList;
  onClick?: () => void;
}

const cx = classNames.bind(style);

export const ManualList = ({
  children,
  requestMethod,
  limit,
  loader,
  onClick,
  deps = []
}: ManualListProps) => {
  const [forceUpdate, setForceUpdate] = useState(new Date().getTime());
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const mount = () => {
    if (!!deps.length) {
      setForceUpdate(new Date().getTime());
      setPage(0);
      setHasMore(true);
    }
    setMounted(true);
  };

  useEffect(() => {
    mount();
  }, deps);

  const request = async () => {
    if (!mounted) return;
    const hasMoreGames = await requestMethod(page, limit);
    setHasMore(hasMoreGames);
  };
  const [fetching, error] = useAsyncRequest(request, [mounted, forceUpdate, page]);
  const showPlaceholder = !mounted || (fetching && page === 0);
  const showButton = !showPlaceholder && !error && hasMore;

  const onLoadMore = async offsetFactor => {
    if (onClick) {
      onClick();
      return;
    }
    setPage(offsetFactor + 1);
  };

  return (
    <Fragment>
      {children}
      {showPlaceholder && cloneElement(loader)}
      {showButton && (
        <div className={cx(style.showMore)}>
          <Button
            appearance="outline"
            size="lg"
            fetching={fetching}
            onClick={() => onLoadMore(page)}>
            Discover more
          </Button>
        </div>
      )}
    </Fragment>
  );
};
