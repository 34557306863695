import React, {ReactElement, ReactNode, cloneElement, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

interface InfiniteListProps {
  children: ReactNode;
  requestMethod: (page: number, limit: number) => Promise<boolean>;
  limit: number;
  loader?: ReactElement;
}

export const InfiniteList = ({children, requestMethod, limit, loader}: InfiniteListProps) => {
  const [hasMore, setHasMore] = useState(true);

  const onLoadMore = async (page: number) => {
    setTimeout(async () => {
      const hasMoreGames = await requestMethod(page - 1, limit);
      setHasMore(hasMoreGames);
    }, 150 * page);
  };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={onLoadMore}
      hasMore={hasMore}
      loader={loader ? cloneElement(loader) : null}>
      {children}
    </InfiniteScroll>
  );
};
