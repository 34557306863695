import styled from 'styled-components';

export const Slide = styled.div`
  width: 100%;
  height: 100%;
  font-size: 0;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
