import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Field, Form} from 'react-final-form';
import styled from 'styled-components';
import {Button, FormGroup, InputPassword, Notification} from '@xsolla/uikit';
import {PasswordTooltip} from '../../PasswordTooltip';
import {composeValidators, confirmation, password, required} from '../../../utils/validators';
import {confirmResetPasswordRequest} from '../../../utils/requester/password';

interface ChangePasswordCredentials {
  password: string;
  confirm_password: string;
}

const StyledForm = styled.form`
  & button {
    width: 100%;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;
`;

export const ChangePasswordForm = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);

  const initialValues: ChangePasswordCredentials = {
    password: '',
    confirm_password: ''
  };

  useEffect(() => {
    if (!queryParams.get('user_id') || !queryParams.get('code')) {
      history.replace('/');
    }
  }, [location]);

  const onSubmit = async (credentials: ChangePasswordCredentials) => {
    try {
      await confirmResetPasswordRequest({
        userID: queryParams.get('user_id'),
        resetCode: queryParams.get('code'),
        newPassword: credentials.password
      });
    } catch (err) {
      return err;
    }
  };

  return (
    <Form<ChangePasswordCredentials>
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({handleSubmit, submitSucceeded, hasSubmitErrors, submitting}) => {
        if (submitSucceeded) {
          return (
            <SuccessMessage>
              You&lsquo;ve successfully updated your password, and should now be able to log in.
            </SuccessMessage>
          );
        }

        return (
          <StyledForm onSubmit={handleSubmit}>
            <FormGroup indentation="lg">
              <FormGroup label="Password" tooltip={<PasswordTooltip />}>
                <Field
                  component={InputPassword}
                  type="password"
                  name="password"
                  validate={composeValidators(required, password)}
                />
              </FormGroup>

              <FormGroup label="Confirm password">
                <Field
                  component={InputPassword}
                  type="password"
                  name="confirm_password"
                  validate={composeValidators(required, password, confirmation('password'))}
                />
              </FormGroup>

              {hasSubmitErrors && (
                <FormGroup>
                  <Notification status="error" icon={true}>
                    Invalid reset code
                  </Notification>
                </FormGroup>
              )}
            </FormGroup>

            <FormGroup>
              <Button type="submit" fetching={submitting}>
                Change password
              </Button>
            </FormGroup>
          </StyledForm>
        );
      }}
    />
  );
};
