import {AnyAction} from 'redux';
import {GDPRInstance} from './types';

export const GDPR_SET_COMPONENT = '@/gdpr/SET_COMPONENT';

export const saveGDPRInstance = (gdpr: GDPRInstance): AnyAction => ({
  type: GDPR_SET_COMPONENT,
  payload: {
    instance: gdpr
  }
});
