import styled from 'styled-components';

export const FormGroupContainer = styled.div<{$indent: string}>`
  margin-bottom: ${({$indent}) => $indent};
`;

export const FormGroupLabel = styled.label`
  ${({theme}) => theme.typography.description};
  color: ${({theme}) => theme.colors.muted};
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const OptionalMark = styled.div`
  &::after {
    content: '\\00a0\\00a0(Optional)';
    opacity: 50%;
  }
`;
