import {DependencyList, useEffect, useState} from 'react';

export interface GradientReplacer {
  from: string;
  to: string;
}

const GRADIENTS: GradientReplacer[] = [
  {from: '#FCDD40', to: '#FF497F'},
  {from: '#37B9FB', to: '#AC2EFF'},
  {from: '#00FFCA', to: '#489DFF'}
];

/**
 * Returns a random gradient to replace the image.
 * @param deps
 */
export const useGradientImageReplacer = (deps: DependencyList = []) => {
  const [gradient, setGradient] = useState<GradientReplacer>({
    from: 'transparent',
    to: 'transparent'
  });

  useEffect(() => {
    setGradient(GRADIENTS[Math.floor(Math.random() * GRADIENTS.length)]);
  }, deps);

  return [gradient];
};
