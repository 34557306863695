import React from 'react';
import style from './style.module.scss';
import {DiscoverPageSectionSize, DiscoverPageSectionType} from '../../../redux/types/discover';
import {DiscoverPageSection} from '../../@discover/DiscoverPageSection';
import {discoverPageSectionSize} from '../../../lib/discover-page';

interface DiscoverSlickPlaceholderProps {
  label: string;
  section: DiscoverPageSectionType;
  description?: string;
}

export const DiscoverSlickPlaceholder = ({section, ...rest}: DiscoverSlickPlaceholderProps) => {
  const getPlaceHolderBySectionSize = () => {
    if (discoverPageSectionSize(section) === DiscoverPageSectionSize.SMALL_SLIDER) {
      return (
        <div style={{display: 'flex'}}>
          <div
            className={style.placeholder}
            style={{width: 310, height: 144, flexShrink: 0, marginRight: 24}}
          />
          <div className={style.placeholder} style={{width: 310, height: 144, flexShrink: 0}} />
        </div>
      );
    }

    return (
      <div style={{display: 'flex'}}>
        <div className={style.placeholder} style={{width: 508, height: 242, flexShrink: 0}} />
        <div style={{marginLeft: 24, width: '100%'}}>
          <div className={style.placeholder} style={{width: 164, height: 24, marginBottom: 8}} />
          <div className={style.placeholder} style={{height: 24, marginBottom: 8}} />
          <div className={style.placeholder} style={{height: 24, marginBottom: 56}} />
          <div className={style.placeholder} style={{width: 90, height: 24}} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <DiscoverPageSection section={section} {...rest}>
        <div className={style.slickPlaceholder}>{getPlaceHolderBySectionSize()}</div>
      </DiscoverPageSection>
    </div>
  );
};
