export const eventName = 'changeCard';

export const getChangeCardEvent = (type: string): CustomEvent =>
  new CustomEvent(eventName, {
    bubbles: true,
    cancelable: true,
    detail: {
      type
    }
  });
