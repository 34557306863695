/**
 * Capitalize first symbol of a string.
 * @param string
 */
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Trims the line and adds three dots at the end.
 * @param string
 * @param length
 */
export const truncate = (string: string, length: number) => {
  const dots = string.length > length ? '...' : '';
  return string.substring(0, length) + dots;
};
