import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface FormDividerProps {
  children: ReactNode;
}

const StyledFormDivider = styled.div`
  display: flex;
  align-items: center;
  color: #00244d;
`;

const Divider = styled.div`
  width: 100%;
  background-color: #c5d0db;
  height: 1px;
`;

const Label = styled.div`
  padding: 0 24px;
`;

export const FormDivider = ({children}: FormDividerProps) => (
  <StyledFormDivider>
    <Divider />
    <Label>{children}</Label>
    <Divider />
  </StyledFormDivider>
);
