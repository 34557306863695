import {investorApiClient} from '../apiClient';
import {ContactDeveloperPayload, GameDetails, GameInvestmentInfo} from './types';

export const requestGameDetails = async (
  companyId: number,
  gameId: string
): Promise<GameDetails> => {
  const {data} = await investorApiClient.get<GameDetails>(
    `/company/${companyId}/project/${gameId}`
  );

  return data;
};

export const requestContactDeveloper = (
  companyId: number,
  gameId: number,
  data: ContactDeveloperPayload
): Promise<void> => {
  return investorApiClient.post(`company/${companyId}/project/${gameId}/contact`, data);
};

export const requestGameInvestmentInfo = async (
  companyId: number,
  gameId: string
): Promise<GameInvestmentInfo> => {
  const {data} = await investorApiClient.get<GameInvestmentInfo>(
    `/company/${companyId}/project/${gameId}/investment_info`
  );

  return data;
};
