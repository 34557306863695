import React from 'react';
import {Link} from 'react-router-dom';
import {InjectedIntl, injectIntl} from 'react-intl';
import styled from 'styled-components';
import {Tag} from '../../../@ui';
import {CardProps} from './DiscoverSliderCard';
import {CardImage, CardImageSize} from '../../../CardImage';
import {getFormattedGameType} from '../../../../utils/formatter/from-json-value';
import {ReactComponent as TimerImage} from '../../../../images/icons/timer.svg';
import {getDiscoverGameMonetizationTypeTag} from '../../../../lib/discover-page';
import {DiscoverPageSectionType} from '../../../../redux/types/discover';
import {AnalyticsEvent} from '../../../../lib/XsollaAnalytics';
import {useAnalytics} from '../../../../hooks/useAnalytics';

interface LastChanceCardProps {
  intl: InjectedIntl;
  game: CardProps;
  section: DiscoverPageSectionType;
}

const StyledLastChanceCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  &:hover img {
    transform: scale(1.05);
  }
`;

const GameName = styled(Link)`
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  color: var(--colorTextBlack);
  &:hover {
    color: var(--colorBrand);
  }
`;

const Info = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const Tags = styled.div`
  display: flex;
  margin: 12px 0;
`;

const DaysLeft = styled.div`
  margin-left: 18px;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const StyledTimerImage = styled(TimerImage)`
  margin-right: 6px;
`;

const ImageLink = styled(Link)`
  display: flex;
  overflow: hidden;
  width: inherit;
  flex-shrink: 0;
  border-radius: 8px;

  &:hover ~ ${Info} ${Header} ${GameName} {
    color: var(--colorBrand);
  }
`;

export const LastChanceCard = injectIntl(({intl, game, section}: LastChanceCardProps) => {
  const analytics = useAnalytics();

  if (!game.days_left) return null;

  const onGameCardClick = () => {
    analytics.click(AnalyticsEvent.GameCardClick, {
      exv: {
        section: section,
        game_id: game.id,
        game_name: game.game_name
      }
    });
  };

  return (
    <StyledLastChanceCard>
      <ImageLink to={`/office/game/${game.id}/detail`} onClick={onGameCardClick}>
        <CardImage
          src={game.header_image_url}
          placeholder={game.game_name}
          size={CardImageSize.Default}
        />
      </ImageLink>

      <Info>
        <Header>
          <GameName to={`/office/game/${game.id}/detail`} onClick={onGameCardClick}>
            {game.game_name}
          </GameName>
          <DaysLeft>
            <StyledTimerImage />
            {game.days_left} days left
          </DaysLeft>
        </Header>

        <Tags>
          <Tag>
            {getFormattedGameType(game.game_type, intl)}
            {getDiscoverGameMonetizationTypeTag(game.monetization_type)}
          </Tag>
        </Tags>
      </Info>
    </StyledLastChanceCard>
  );
});
