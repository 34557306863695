import React from 'react';
import styled from 'styled-components';
import {mediaDevices} from '../../../lib/styled';
import {Title} from '../Title';

interface HighlightProps {
  label: string;
  value: string;
}

const StyledHighlight = styled.div`
  text-align: center;
  max-width: 270px;
  width: 100%;
  padding: 0 24px;

  @media screen and ${mediaDevices.mobile} {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and ${mediaDevices.desktop} {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  margin-top: -10px;
  font-size: 15px;
  line-height: 24px;
  color: #d6e0e7;
`;

export const Highlight = ({label, value}: HighlightProps) => (
  <StyledHighlight>
    <Title>{value}</Title>
    <Label>{label}</Label>
  </StyledHighlight>
);
