import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const GameName = styled(Link)`
  margin-bottom: 6px;
  color: inherit;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.2;
  line-height: 32px;
`;

export const Card = styled.div`
  display: flex;
  height: 242px;

  &:hover {
    img {
      transform: scale(1.05);
    }

    ${GameName} {
      color: ${({theme}) => theme.colors.brand};
    }
  }
`;

export const ImageContainer = styled(Link)`
  display: flex;
  overflow: hidden;
  width: 508px;
  height: 242px;
  flex-shrink: 0;
  border-radius: 8px;
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 24px;
`;

export const Description = styled.div`
  margin: 0 0 24px;
  color: ${({theme}) => theme.colors.muted};
`;

export const StatusLabelContainer = styled.div`
  margin: 0 0 24px;
`;

export const PropertyGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(164px, max-content) 1px minmax(0, max-content);
  gap: 24px;
`;

export const PropertyGridDivider = styled.div`
  width: 1px;
  height: 100%;
  background: ${({theme}) => theme.colors.stroke};
`;

export const PropertyLabel = styled.p`
  ${({theme}) => theme.typography.description};
  color: ${({theme}) => theme.colors.muted};
  margin: 0;
`;

export const PropertyValue = styled.div`
  ${({theme}) => theme.typography.accent};
  color: ${({theme}) => theme.colors.text};
  margin: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PropertyIconContainer = styled.div`
  padding-top: 4px;
  margin-right: 4px;
  line-height: 0;
`;
