import {FieldMetaState} from 'react-final-form';

export type FieldValue = string | number | boolean;

interface FieldMetaErrorOptions {
  forced: boolean;
}

const defaultOptions = {
  forced: false
};

/**
 * Return field computed error message
 * @param meta
 * @param options
 */
export const getFieldMetaError = <FieldValue>(
  meta: FieldMetaState<FieldValue>,
  options: Partial<FieldMetaErrorOptions> = defaultOptions
) => {
  const {forced} = {...defaultOptions, ...options};

  if (forced || meta.touched || meta.visited) {
    return meta.error || meta.submitError;
  }

  return null;
};
