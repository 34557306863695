import {AnyAction} from 'redux';
import {validateToken} from '../../lib/jwtToken';
import {COOKIES, clearCookie, getFromCookie} from '../../lib/cookies';

export interface ApplicationState {
  loggedIn: boolean;
  companyID: number | null;
}

const loggedIn = (() => {
  const token = getFromCookie(COOKIES.PRODUCT_COOKIE_TOKEN);
  if (token && validateToken(token)) {
    return true;
  }
  clearCookie();
  return false;
})();

const logoutState: ApplicationState = {
  loggedIn: false,
  companyID: null
};

const initialState: ApplicationState = {
  loggedIn,
  companyID: loggedIn ? getFromCookie(COOKIES.PRODUCT_COOKIE_COMPANY_ID) : null
};

export const APPLICATION_SET_LOGGED_IN = '@/application/SET_LOGGED_IN';
export const APPLICATION_SET_COMPANY_ID = '@/application/SET_COMPANY_ID';
export const APPLICATION_SET_LOGOUT_STATE = '@/application/SET_LOGOUT_STATE';

export const applicationReducer = (state = initialState, action: AnyAction): ApplicationState => {
  switch (action.type) {
    case APPLICATION_SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: action.payload
      };

    case APPLICATION_SET_COMPANY_ID:
      return {
        ...state,
        companyID: action.payload
      };

    case APPLICATION_SET_LOGOUT_STATE:
      return logoutState;

    default:
      return state;
  }
};
