import {useEffect, useState} from 'react';
import {ENABLED_BASIC_AUTH} from '../constant/config';
import {COOKIES, getFromCookie} from '../lib/cookies';

export const useBasicAuth = (): boolean => {
  const [isAuthChecked, setAuthChecked] = useState(false);
  const [isAuthChecking, setAuthChecking] = useState(true);

  useEffect(() => {
    if (!ENABLED_BASIC_AUTH) {
      return;
    }

    const session = getFromCookie(COOKIES.__SESSION);
    (async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/checkAuth?session=${session}`
        );
        if (res.status === 401) {
          document.location.href = `${document.location.origin}/basic-auth`;
        } else {
          setAuthChecked(true);
        }
      } catch (e) {
        document.location.href = `${document.location.origin}/basic-auth`;
        setAuthChecked(false);
      }
      setAuthChecking(false);
    })();
  }, []);

  return (isAuthChecking || !isAuthChecked) && ENABLED_BASIC_AUTH;
};
