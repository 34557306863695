import {
  OnboardingDataUpdateParams,
  useCompleteOnboardingApi,
  useSaveOnboardingDataApi
} from '../../api/onboarding';

export interface UpdateOnboardingDataProps extends OnboardingDataUpdateParams {
  onSuccess: () => void;
  onError: () => void;
}

interface UseOnboardingForm {
  updateOnboardingData: (props: UpdateOnboardingDataProps) => Promise<void>;
  completeOnboarding: () => Promise<void>;
  isLoading: boolean;
  isError: boolean;
}

export const useOnboardingForm = (companyId: number): UseOnboardingForm => {
  const {
    mutateAsync: update,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate
  } = useSaveOnboardingDataApi();
  const {
    mutateAsync: completeOnboarding,
    isLoading: isLoadingComplete,
    isError: isErrorComplete
  } = useCompleteOnboardingApi(companyId);

  const updateOnboardingData = async ({
    step,
    values,
    companyId,
    onError,
    onSuccess
  }: UpdateOnboardingDataProps): Promise<void> => {
    try {
      await update({companyId, step, values});
      onSuccess();
    } catch (err) {
      onError();
      throw err;
    }
  };

  return {
    updateOnboardingData,
    isLoading: isLoadingUpdate || isLoadingComplete,
    isError: isErrorUpdate || isErrorComplete,
    completeOnboarding
  };
};
