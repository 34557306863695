import {createSelector} from 'reselect';
import {RootState} from '../types/store';

/**
 * Select discover filter reducer
 */
export const selectDiscoverFilter = createSelector(
  (state: RootState) => state.discoverFilter,
  discoverFilter => discoverFilter
);

/**
 * Select discover filter fetching
 */
export const selectDiscoverFilterFetching = createSelector(
  selectDiscoverFilter,
  discoverFilter => discoverFilter.fetching
);

/**
 * Select discover filter value
 */
export const selectDiscoverFilterValue = createSelector(
  selectDiscoverFilter,
  discoverFilter => discoverFilter.value
);

/**
 * Select discover filter dirty state
 */
export const selectDiscoverFilterDirty = createSelector(
  selectDiscoverFilter,
  ({value: {genres, platforms, query}, filterCount}) =>
    !!genres.length || !!platforms.length || !!query || !!filterCount
);

/**
 * Select discover `more filters` count
 */
export const selectDiscoverMoreFiltersCount = createSelector(
  selectDiscoverFilter,
  discoverFilter => {
    const count = discoverFilter.filterCount;
    if (count === 0) {
      return count;
    }
    return discoverFilter.value.query ? count - 1 : count;
  }
);

/**
 * Select discover filters sort
 */
export const selectDiscoverFilterSort = createSelector(
  selectDiscoverFilter,
  discoverFilter => discoverFilter.value.sort
);

/**
 * Select current discover search section page
 */
export const selectDiscoverSearchSectionPage = createSelector(
  selectDiscoverFilter,
  discoverFilter => discoverFilter.searchSectionPage
);
