// ToDo: move error messages to the translations and use intl here.
export class DuplicateUserError extends Error {
  constructor(errMessage: string) {
    super(errMessage);
  }
}

export class ForbiddenEmailDomainError extends Error {
  constructor() {
    super('An error has occurred. Please try to continue with Google or use a different email.');
  }
}

export class InvalidEmailError extends Error {
  constructor() {
    super('Email address does not exist. Try another email address.');
  }
}
