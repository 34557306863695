import {investorApiClient} from '../apiClient';
import {serializeOnboardingCompanyInfoStep} from './serializers';
import {
  OnboardingCompanyInfoStepParams,
  OnboardingDataParams,
  OnboardingDataResponse,
  OnboardingDataUpdateParams
} from './types';
import {OnboardingSteps} from '../../constants/OnboardingSteps';

export const requestOnboardingStatus = async (companyId: number): Promise<void> => {
  await investorApiClient.get<void>(`company/${companyId}/confirmed`);
};

export const requestOnboardingData = async (
  companyID: number,
  step: OnboardingSteps
): Promise<OnboardingDataResponse> => {
  const {data} = await investorApiClient.get<OnboardingDataResponse>(
    `company/${companyID}/onboarding/step/${step}`
  );

  return data;
};

export const requestSaveOnboardingData = async ({
  step,
  companyId,
  values
}: OnboardingDataUpdateParams): Promise<void> => {
  let data: OnboardingDataParams | FormData = values;
  const config = {
    headers: {}
  };

  if (step === OnboardingSteps.COMPANY_INFO) {
    const companyInfoValues = values as OnboardingCompanyInfoStepParams;

    data = serializeOnboardingCompanyInfoStep(companyInfoValues);
    config.headers = {
      'Content-Type': 'multipart/form-data'
    };
  }

  await investorApiClient.put<void>(`company/${companyId}/onboarding/step/${step}`, data, config);
};

export const requestCompleteOnboarding = async (companyId: number): Promise<void> => {
  await investorApiClient.post(`company/${companyId}/onboarding/complete`);
};
