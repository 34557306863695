import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {useUserMainInfo} from '../../../api/user/queries';
import {FUNDING_CLUB_EMAIL} from '../../../constant';
import {DiscoverFilterForm} from '../../@forms/DiscoverFilterForm';

const StyledDiscoverFilter = styled.div`
  padding: 32px 24px 12px 24px;
  margin-bottom: 64px;
  background: #fafbfc;
  border-radius: 8px;
`;

const Intro = styled.div`
  margin-bottom: 64px;
`;

const Title = styled.div`
  margin-bottom: 8px;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 40px;
`;

const Caption = styled.div`
  max-width: 624px;
  color: #747e8a;
  font-size: 15px;
  line-height: 24px;

  & a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      color: #0076ff;
    }
  }
`;

export const DiscoverFilter = () => {
  const {data: userMainInfo} = useUserMainInfo();
  const userName = userMainInfo
    ? [userMainInfo.firstName, userMainInfo.lastName].filter(s => s !== '').join(' ')
    : '...';

  return (
    <StyledDiscoverFilter>
      <Intro>
        <Title>{userName}, welcome to Investor Account!</Title>
        <Caption>
          Discover all qualified games, including those that match your&nbsp;
          <Link to="/settings/investor">investment criteria</Link>.<br />
          If you have questions or feedback, please email us at&nbsp;
          <a href={`mailto: ${FUNDING_CLUB_EMAIL}`}>{FUNDING_CLUB_EMAIL}</a>.
        </Caption>
      </Intro>

      <DiscoverFilterForm />
    </StyledDiscoverFilter>
  );
};
