import React from 'react';
import {FormApi} from 'final-form';
import {useSelector} from 'react-redux';
import {FieldArray} from 'react-final-form-arrays';
import {Field, Form} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames/bind';
import {Button, FormFooter, FormGroup, Head, Modal} from '@xsolla/uikit';
import {selectDiscoverFilterValue} from '../../../redux/selectors/discover-filter';
import {defaultDiscoverFilterValue} from '../../../redux/reducer/discover-filter';
import {FieldValue} from '../../../lib/form';
import {FieldOption, getFieldOptions} from '../../../lib/fieldOptions';
import {DiscoverFilterValue} from '../../../redux/types';
import {FilterFieldGroup, MultiCheckbox, Select} from '../../@ui';
import style from './style.module.scss';

interface DiscoverMoreFiltersModalProps {
  onClose: () => void;
  onSubmit: (values: DiscoverFilterValue) => void;
}

const cx = classNames.bind(style);

const anyValue = {
  label: 'Any',
  value: []
};

export const DiscoverMoreFiltersModal = ({onClose, onSubmit}: DiscoverMoreFiltersModalProps) => {
  const initialValues = useSelector(selectDiscoverFilterValue);

  const resetForm = (form: FormApi<DiscoverFilterValue>) => {
    form.reset({...defaultDiscoverFilterValue, query: form.getState().values.query});
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <div className={cx(style.discoverModal)}>
        <Head>More filters</Head>
        <Form<DiscoverFilterValue>
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{
            getAll: ([name], state) => state.formState.values[name],
            removeAll: ([name], state) => (state.formState.values[name] = []),
            ...arrayMutators
          }}
          render={({form, handleSubmit, submitting}) => (
            <form onSubmit={handleSubmit} className={cx(style.filterForm)}>
              <div>
                <FilterFieldGroup label="General">
                  <FormGroup label="Requested investment amount">
                    <Field
                      name="investment_prefer_range"
                      parse={(val: FieldValue): FieldValue => val}
                      component={Select}
                      options={[...getFieldOptions(FieldOption.InvestmentPreferRange), anyValue]}
                    />
                  </FormGroup>
                </FilterFieldGroup>

                <FilterFieldGroup label="Game">
                  <FormGroup label="Genres">
                    <div className={cx(style.checkbox)}>
                      <FieldArray
                        name="genres"
                        component={MultiCheckbox}
                        removeAll={form.mutators.removeAll}
                        maySelectAll={true}
                        classNames={cx(style.checkboxWidth)}
                        options={getFieldOptions(FieldOption.Genres)}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup label="Subgenre">
                    <div className={cx(style.checkbox)}>
                      <FieldArray
                        name="subgenre"
                        component={MultiCheckbox}
                        removeAll={form.mutators.removeAll}
                        maySelectAll={true}
                        classNames={cx(style.checkboxWidth)}
                        options={getFieldOptions(FieldOption.Subgenres)}
                      />
                    </div>
                  </FormGroup>
                </FilterFieldGroup>

                <FilterFieldGroup label="Development">
                  <FormGroup label="Game engine">
                    <div className={cx(style.checkbox)}>
                      <FieldArray
                        name="game_engine"
                        component={MultiCheckbox}
                        removeAll={form.mutators.removeAll}
                        maySelectAll={true}
                        classNames={cx(style.checkboxWidth)}
                        options={getFieldOptions(FieldOption.GameEngine)}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup label="Initial platforms">
                    <div className={cx(style.checkbox)}>
                      <FieldArray
                        name="platforms"
                        component={MultiCheckbox}
                        removeAll={form.mutators.removeAll}
                        maySelectAll={true}
                        classNames={cx(style.checkboxWidth)}
                        options={getFieldOptions(FieldOption.InitialPlatforms)}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup label="Monetization model">
                    <div className={cx(style.checkbox)}>
                      <FieldArray
                        name="monetization_model"
                        component={MultiCheckbox}
                        removeAll={form.mutators.removeAll}
                        maySelectAll={true}
                        options={getFieldOptions(FieldOption.MonetizationModel)}
                      />
                    </div>
                  </FormGroup>
                </FilterFieldGroup>
              </div>

              <div className={cx(style.footer)}>
                <FormFooter>
                  <Button appearance="flatten" onClick={() => resetForm(form)}>
                    Reset all filters
                  </Button>
                  <Button
                    type="submit"
                    appearance="secondary"
                    fetching={submitting}
                    disabled={submitting}>
                    Apply
                  </Button>
                </FormFooter>
              </div>
            </form>
          )}
        />
      </div>
    </Modal>
  );
};
