import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import {Button, ContentBlock, FormFooter, Head} from '@xsolla/uikit';
import {ToastType, useToasts} from '../../component/ToastProvider';
import {requestUserUpdateAccount, useUserAccount} from '../../api/user';
import {actionUserUpdateAccount} from '../../redux/action/user';
import {selectGDPRComponent} from '../../redux/gdpr';
import {selectApplicationCompanyID} from '../../redux/selectors/application';

import {UserAccount} from '../../api/user';
import {XFCPersonalInfoPage} from '../../component/@fields';
import style from './style.module.scss';

export const AccountPage = () => {
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const companyID = useSelector(selectApplicationCompanyID);
  const gdpr = useSelector(selectGDPRComponent);
  const {data: userAccount} = useUserAccount(companyID);

  /**
   * Update user profile
   * @param account
   */
  const onSubmitForm = async (account: UserAccount) => {
    try {
      await requestUserUpdateAccount(companyID, account);
      dispatch(actionUserUpdateAccount(account));
      addToast('Changes have been saved!', ToastType.Success);
    } catch (e) {
      addToast('An error has occurred', ToastType.Error);
    }
  };

  const showPrivacySettings = () => {
    if (!gdpr) {
      return;
    }

    gdpr.show();
  };

  return (
    <ContentBlock>
      <Form<UserAccount>
        onSubmit={onSubmitForm}
        initialValues={userAccount || {}}
        render={({handleSubmit, valid, pristine, form, submitting}) => (
          <form onSubmit={handleSubmit}>
            <Head indentation="lg">Account settings</Head>

            <XFCPersonalInfoPage settings={true} />

            <Button type="button" appearance="secondary" onClick={showPrivacySettings}>
              Show Privacy Settings
            </Button>

            <div className={style.footer}>
              <FormFooter>
                <Button appearance="outline" onClick={form.reset}>
                  Discard changes
                </Button>
                <Button
                  type="submit"
                  fetching={submitting}
                  disabled={submitting || pristine || !valid}>
                  Save changes
                </Button>
              </FormFooter>
            </div>
          </form>
        )}
      />
    </ContentBlock>
  );
};
