import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {AppThemeEnum} from '../../../../redux/types/theme';
import {setDiscoverFilterValue} from '../../../../redux/action/discover-filter';
import {defaultDiscoverFilterValue} from '../../../../redux/reducer/discover-filter';
import {ReactComponent as LightLogoIcon} from './logo_header_light.svg';
import {ReactComponent as DarkLogoIcon} from './logo_header_dark.svg';
import {LogoContainer} from './styled';

interface LogoProps {
  theme: AppThemeEnum;
}

export const Logo: FC<LogoProps> = ({theme}) => {
  const dispatch = useDispatch();
  const resetDiscoverFilters = () => {
    dispatch(setDiscoverFilterValue(defaultDiscoverFilterValue));
  };

  return (
    <Link to="/">
      <LogoContainer $logged={theme === AppThemeEnum.LIGHT} onClick={resetDiscoverFilters}>
        {theme === AppThemeEnum.LIGHT ? <LightLogoIcon /> : <DarkLogoIcon />}
      </LogoContainer>
    </Link>
  );
};
