import React from 'react';
import styled from 'styled-components';
import {ReactComponent as ExperienceIcon} from './experience.svg';
import {ReactComponent as RisksIcon} from './risks.svg';
import {ReactComponent as PortfolioIcon} from './portfolio.svg';
import {ReactComponent as SimplicityIcon} from './simplicity.svg';
import {mediaDevices} from '../../../lib/styled';
import {Section} from '../Section';
import {Title} from '../Title';

const StyledWhyBlock = styled.div``;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 40px -15px -15px -15px;

  @media screen and ${mediaDevices.mobile} {
    flex-direction: column;
  }

  @media screen and ${mediaDevices.desktop} {
    flex-direction: row;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  width: 50%;
  padding: 15px;

  @media screen and ${mediaDevices.mobile} {
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and ${mediaDevices.desktop} {
    flex-direction: row;
    margin-bottom: 0;
    width: 50%;
  }
`;

const IconWrapper = styled.div`
  margin-right: 30px;

  margin-bottom: 24px;

  & svg {
    display: block;
    width: 48px;
    height: 48px;
  }
`;

const Label = styled.div`
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
  margin-bottom: 8px;
`;

const Text = styled.div`
  font-size: 15px;
  line-height: 24px;
  color: #d6e0e7;
`;

export const WhyBlock = () => (
  <Section edged={true}>
    <StyledWhyBlock>
      <Title>Why investing with xsolla</Title>

      <Grid>
        <Item>
          <IconWrapper>
            <ExperienceIcon />
          </IconWrapper>
          <div>
            <Label>15 years of video game industry experience</Label>
            <Text>
              Xsolla creates industry-leading video gaming-specific tools and services for
              developers, publishers, and platform partners, with more than 2,000 and counting
              global clients.
            </Text>
          </div>
        </Item>

        <Item>
          <IconWrapper>
            <RisksIcon />
          </IconWrapper>
          <div>
            <Label>Lowered risks with co-investments</Label>
            <Text>
              Co-investment opportunities — wherein more than one investor funds the same project —
              are available through Xsolla Funding Club, improving a developer’s odds of matching
              while lowering risk for investors.
            </Text>
          </div>
        </Item>

        <Item>
          <IconWrapper>
            <SimplicityIcon />
          </IconWrapper>
          <div>
            <Label>Simplicity by design</Label>
            <Text>
              Xsolla Funding Club exists to remove the barriers between game creators and game
              financiers, enabling every industry player the opportunity to get their best work
              done.
            </Text>
          </div>
        </Item>

        <Item>
          <IconWrapper>
            <PortfolioIcon />
          </IconWrapper>
          <div>
            <Label>200+ qualified games in portfolio</Label>
            <Text>
              Xsolla reviews every developer and their games to ensure their competitive potential
              in a thriving marketplace.
            </Text>
          </div>
        </Item>
      </Grid>
    </StyledWhyBlock>
  </Section>
);
