import {request} from './index';
import {investorApiClient} from '../axios/client/investor';
import {DiscoverFilterValue} from '../../redux/types';
import {DiscoverGamesResponse} from '../../redux/types/discover';

interface RequestDiscoverParams extends DiscoverFilterValue {
  limit: number;
  offset: number;
}

/**
 * Request Games list with pagination & filter
 * @param companyID
 * @param type
 * @param params
 */
export const requestDiscover = async (
  companyID: number,
  type: string,
  params: Partial<RequestDiscoverParams> = {}
): Promise<DiscoverGamesResponse> => {
  const response = await request(
    investorApiClient,
    'get',
    `company/${companyID}/discover/${type}`,
    {
      params
    }
  );

  return response.data;
};
