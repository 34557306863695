import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface ButtonProps {
  children: ReactNode;
}

const StyledButton = styled.button`
  background: #0076ff;
  border: 1px solid #0073f7;
  display: inline-flex;
  align-items: center;
  padding: 0 64px;
  border-radius: 100px;
  height: 50px;
  font-family: var(--fontFamilyLogin);
  font-size: 15px;
  line-height: normal;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 4px 16px rgba(0, 115, 247, 0.32);
`;

export const Button = ({children}: ButtonProps) => <StyledButton>{children}</StyledButton>;
