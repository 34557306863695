import {AnyAction} from 'redux';
import {Dispatch} from 'react';
import {COOKIES, getFromCookie} from '../../../lib/cookies';
import {AuthType, DispatchLogin} from '../../types/registration';
import {
  xsollaLoginApiLogin,
  xsollaLoginApiRegister,
  xsollaLoginAuthSocial
} from '../../../api/actions/registration';
import {AppThemeEnum} from '../../types/theme';
import {logOutApplication, setApplicationCompanyID, setApplicationLoggedIn} from '../application';
import {setAppTheme} from '../apptheme';
import {
  DuplicateUserError,
  ForbiddenEmailDomainError,
  InvalidEmailError
} from '../../../types/errors';
import {
  FORBIDDEN_EMAIL_DOMAIN_ERROR_CODE,
  INVALID_EMAIL_ERROR_CODE,
  USER_EXISTS_ERROR_CODE
} from './constants';

const login = (authType: AuthType, payload: Record<string, string>) => async (
  dispatch: Dispatch<AnyAction>
) => {
  try {
    switch (authType) {
      case AuthType.LOGIN:
        await xsollaLoginApiLogin(payload);
        break;
      case AuthType.REGISTER:
        await xsollaLoginApiRegister(payload);
        break;
      case AuthType.SOCIAL:
        await xsollaLoginAuthSocial(payload);
        break;
      default:
        return;
    }
    dispatch(setApplicationCompanyID(getFromCookie(COOKIES.PRODUCT_COOKIE_COMPANY_ID)));
    dispatch(setApplicationLoggedIn(true));
    dispatch(setAppTheme(AppThemeEnum.LIGHT));
  } catch (err) {
    dispatch(logOutApplication());

    if (err?.response.status === 422) {
      const respErr = err?.response?.data?.error;
      if (respErr) {
        switch (respErr.code) {
          case USER_EXISTS_ERROR_CODE:
            throw new DuplicateUserError(respErr.description);
          case FORBIDDEN_EMAIL_DOMAIN_ERROR_CODE:
            throw new ForbiddenEmailDomainError();
          case INVALID_EMAIL_ERROR_CODE:
            throw new InvalidEmailError();
        }
      }
    }

    throw err;
  }
};

export const registerApplication = payload => (dispatch: DispatchLogin) => {
  return dispatch(login(AuthType.REGISTER, payload));
};

export const loginApplication = payload => (dispatch: DispatchLogin) => {
  return dispatch(login(AuthType.LOGIN, payload));
};

export const loginApplicationSocial = (url: string) => (dispatch: DispatchLogin) => {
  return dispatch(login(AuthType.SOCIAL, {url}));
};
