import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form} from 'react-final-form';
import {ContentBlock, FormGroup, Head, Switch} from '@xsolla/uikit';
import {requestUserUpdateNotifications} from '../../api/user';
import {ToastType, useToasts} from '../../component/ToastProvider';
import {actionUserUpdateNotifications} from '../../redux/action/user';
import {selectApplicationCompanyID} from '../../redux/selectors/application';
import {selectUserNotifications} from '../../redux/selectors/user';
import {UserNotifications} from '../../redux/types/user';

export const NotificationsPage = () => {
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const companyID = useSelector(selectApplicationCompanyID);
  const initialValues = useSelector(selectUserNotifications);

  const onSubmitForm = async (notifications: UserNotifications) => {
    try {
      await requestUserUpdateNotifications(companyID, notifications);
      dispatch(actionUserUpdateNotifications(notifications));
      addToast('Changes have been saved!', ToastType.Success);
    } catch (e) {
      addToast('An error has occurred', ToastType.Error);
    }
  };

  return (
    <ContentBlock>
      <Form<UserNotifications>
        onSubmit={onSubmitForm}
        initialValues={initialValues}
        render={({handleSubmit, submitting}) => (
          <form onChange={handleSubmit}>
            <Head indentation="lg">Notifications</Head>

            <FormGroup>
              <Field
                name="weekly_digest_notification"
                component={Switch}
                label="Weekly digest of new & networthy games"
                sublabel="Get a weekly update on the games you might be interested in (base on your company settings)."
                fetching={submitting}
                disabled={submitting}
              />
            </FormGroup>
          </form>
        )}
      />
    </ContentBlock>
  );
};
