import React from 'react';
import {FieldRenderProps} from 'react-final-form';
import {Select as UISelect} from '@xsolla/uikit';
import {FieldValue} from '../../../lib/form';

export interface SelectProps extends FieldRenderProps<FieldValue, HTMLInputElement> {
  options: Record<string, FieldValue>[];
}

export const Select = ({input, meta, ...rest}: SelectProps) => {
  /**
   * Delays the firing of the `onBlur` event by 120ms (animation time popper).
   */
  const onDelayedBlur = () => {
    setTimeout(() => {
      input.onBlur();
    }, 120);
  };

  return <UISelect input={input} meta={meta} {...rest} onBlurHandler={onDelayedBlur} />;
};
