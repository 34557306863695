import React, {Children, ReactNode} from 'react';
import classNames from 'classnames/bind';
import Masonry from 'react-masonry-css';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  cols?: number;
}

const cx = classNames.bind(styles);

export const Grid = ({children, cols = 3}: Props) => (
  <Masonry
    breakpointCols={{
      default: cols,
      1024: 2,
      768: 1
    }}
    className={cx(styles.grid)}
    columnClassName={cx(styles.column)}>
    {Children.map(
      children,
      child => child && <div className={cx(styles.item)}>{child}</div>
    ).filter(Boolean)}
  </Masonry>
);
