import React, {FC, ReactNode} from 'react';
import {IntlProvider} from 'react-intl';
import {ThemeProvider} from 'styled-components';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ModalProvider} from 'react-modal-hook';

import {GlobalStyles, theme} from '../../styled';
import {ToastProvider} from '../../component/ToastProvider';
import {getTranslations} from '../../utils/translations';

const queryClient = new QueryClient({
  defaultOptions: {queries: {retry: 0, refetchOnWindowFocus: false}}
});

interface GlobalProvidersProps {
  children: ReactNode;
}

export const GlobalProviders: FC<GlobalProvidersProps> = ({children}) => {
  return (
    <IntlProvider locale="en" messages={getTranslations('en')}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ModalProvider>
              <ToastProvider>
                <GlobalStyles />
                {children}
              </ToastProvider>
            </ModalProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </IntlProvider>
  );
};
