import {AnyAction} from 'redux';
import {Dispatch} from 'react';
import {
  SET_FILTER_FETCHING,
  SET_FILTER_SORT,
  SET_FILTER_VALUE,
  SET_SEARCH_PAGE,
  defaultDiscoverFilterValue
} from '../reducer/discover-filter';
import {DiscoverPageSectionType} from '../types/discover';
import {DiscoverFilterValue} from '../types';
import {FieldOption, convertFieldToNumber} from '../../lib/fieldOptions';
import {RootState} from '../types/store';

/**
 * Set discover filter fetching state
 * @param fetching
 */
export const setDiscoverFilterFetching = (fetching: boolean): AnyAction => ({
  type: SET_FILTER_FETCHING,
  payload: {fetching}
});

/**
 * Set discover filter value
 * @param value
 */
export const setDiscoverFilterValue = (value: Partial<DiscoverFilterValue>): AnyAction => ({
  type: SET_FILTER_VALUE,
  payload: {value}
});

/**
 * Set discover filter sorting
 * @param sort
 */
export const setDiscoverFilterSort = (sort: string): AnyAction => ({
  type: SET_FILTER_SORT,
  payload: {sort}
});

/**
 * Set discover search page
 * @param searchSectionPage
 */
export const setDiscoverSearchPage = (searchSectionPage: DiscoverPageSectionType): AnyAction => ({
  type: SET_SEARCH_PAGE,
  payload: {searchSectionPage}
});

/**
 * Set predefined recommended filters and set recommended page
 */
export const setDiscoverRecommendedFilterValue = () => (
  dispatch: Dispatch<AnyAction>,
  getState: () => RootState
) => {
  const recommended = getState().user.investorProfile;
  const result = {
    ...defaultDiscoverFilterValue,
    monetization_model: recommended.monetization_types,
    genres: convertFieldToNumber(FieldOption.Genres, recommended.genres),
    investment_prefer_ranges: recommended.deal_ranges,
    platforms: convertFieldToNumber(FieldOption.InitialPlatforms, recommended.platforms),
    playable_build: recommended.playable_builds
  };
  dispatch(setDiscoverFilterValue(result));
};
