import {
  OnboardingDataResponse,
  useOnboardingCompanyInfoStepApi,
  useOnboardingInvestingInterestStepApi,
  useOnboardingPersonalInfoStepApi
} from '../../api/onboarding';
import {OnboardingSteps} from '../../constants/OnboardingSteps';

interface UseOnboardingPage {
  fetchOnboardingData: (step: number) => Promise<OnboardingDataResponse>;
}

export const useOnboardingData = (companyId: number): UseOnboardingPage => {
  const {refetch: refetchPersonalInfo} = useOnboardingPersonalInfoStepApi(companyId);
  const {refetch: refetchCompanyInfo} = useOnboardingCompanyInfoStepApi(companyId);
  const {refetch: refetchInvestingInterest} = useOnboardingInvestingInterestStepApi(companyId);

  const fetchOnboardingData = async (step: number): Promise<OnboardingDataResponse> => {
    try {
      switch (step) {
        case OnboardingSteps.PERSONAL_INFO: {
          const {data} = await refetchPersonalInfo({throwOnError: true});
          return data;
        }
        case OnboardingSteps.COMPANY_INFO: {
          const {data} = await refetchCompanyInfo({throwOnError: true});
          return data;
        }
        case OnboardingSteps.INVESTING_INTEREST: {
          const {data} = await refetchInvestingInterest({throwOnError: true});
          return data;
        }
        default:
          throw new Error('Unknown <step> value');
      }
    } catch (err) {
      throw err;
    }
  };

  return {
    fetchOnboardingData
  };
};
