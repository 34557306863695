import {AnyAction} from 'redux';
import {
  GIP_ONBOARDING_SET_PAGES_DATA,
  ONBOARDING_SET_PAGES_DATA,
  ONBOARDING_UPDATE_PAGE_DATA,
  ONBOARDING_UPDATE_STATUS
} from '../../reducer/onboarding';
import {OnboardingStatus} from '../../../constants/OnboardingStatus';
import {AnyOnboardingPage} from '../../types/onboarding';

export const setOnboardingPagesData = (payload: AnyOnboardingPage[]): AnyAction => ({
  type: ONBOARDING_SET_PAGES_DATA,
  payload
});

export const GIPSetOnboardingPagesData = (payload: AnyOnboardingPage[]): AnyAction => ({
  type: GIP_ONBOARDING_SET_PAGES_DATA,
  payload
});

export const updateOnboardingPageData = (payload: {
  page: number;
  data: AnyOnboardingPage;
}): AnyAction => ({
  type: ONBOARDING_UPDATE_PAGE_DATA,
  payload
});

/**
 * The action updates the onboarding status in the repository
 * @param status
 */
export const actionUpdateOnboardingStatus = (status: OnboardingStatus): AnyAction => ({
  type: ONBOARDING_UPDATE_STATUS,
  payload: {status}
});
