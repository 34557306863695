import React from 'react';
import {ContentBlock} from '@xsolla/uikit';
import {FUNDING_CLUB_EMAIL} from '../../constant';
import AlertIcon from '../../images/icons/alert--yellow.svg';
import style from './style.module.scss';

export const DeclinePageXFC = () => (
  <div className={style.declinePage}>
    <ContentBlock>
      <div className={style.warningMessage}>
        <img src={AlertIcon} alt="" className={style.icon} />
        <h2 className={style.title}>Your account is blocked</h2>
        <p>
          Based on your application, we were unable to verify your account and your profile has been
          disabled.
        </p>
      </div>

      <div>
        If you believe that this has occurred in error, please email us at <br />
        <a href={`mailto: ${FUNDING_CLUB_EMAIL}`}>{FUNDING_CLUB_EMAIL}</a>.
      </div>
    </ContentBlock>
  </div>
);
