import React from 'react';
import {LastChanceCard} from './LastChanceCard';
import {DefaultCard} from './DefaultCard';
import {discoverPageSectionSize} from '../../../../lib/discover-page';
import {DiscoverPageSectionSize, DiscoverPageSectionType} from '../../../../redux/types/discover';

export interface CardProps {
  header_image_url: string;
  game_name: string;
  replacer: string;
  game_type: string;
  description: string;
  invested_amount: number;
  requested_amount: number;
  id: number;
  investment_specialization: string;
  monetization_type: string;
  status: string;
  percentage: number;
  game_already_launched: boolean;
  partially_funded: boolean;
  days_left?: number;
  launch_date?: string;
  image_replacer?: string;
}

export interface DiscoverSliderCardProps {
  game: CardProps;
  section: DiscoverPageSectionType;
}

export const DiscoverSliderCard = ({game, section}: DiscoverSliderCardProps) => {
  if (discoverPageSectionSize(section) === DiscoverPageSectionSize.SMALL_SLIDER) {
    return <LastChanceCard game={game} section={section} />;
  }

  return <DefaultCard game={game} section={section} />;
};
