import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {OnboardingUserPanel} from './components/OnboardingUserPanel';
import {OfficeUserPanel} from './components/OfficeUserPanel';
import {Logo} from './components/Logo';
import {selectAppTheme} from '../../redux/selectors/apptheme';
import {selectOnboardingStatus} from '../../redux/selectors/onboarding';
import {OnboardingStatus} from '../../constants/OnboardingStatus';
import {BlockedContainer, HeaderContainer, LogoContainer, UserContainer} from './styled';

export const Header: FC = () => {
  const theme = useSelector(selectAppTheme);
  const onboardingStatus = useSelector(selectOnboardingStatus);

  if (onboardingStatus !== OnboardingStatus.APPROVED) {
    return (
      <HeaderContainer>
        <BlockedContainer>
          <Logo theme={theme} />
        </BlockedContainer>

        <UserContainer>
          <OnboardingUserPanel />
        </UserContainer>
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer>
      <LogoContainer>
        <Logo theme={theme} />
      </LogoContainer>

      <UserContainer>
        <OfficeUserPanel />
      </UserContainer>
    </HeaderContainer>
  );
};
