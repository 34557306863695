import {DependencyList, useEffect, useState} from 'react';

/**
 * Async request hook with fetching
 * @param func
 * @param deps
 */
export const useAsyncRequest = (func: () => Promise<void>, deps: DependencyList = []) => {
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setFetching(true);

    (async () => {
      try {
        await func();
      } catch (err) {
        setError(err);
        throw err;
      } finally {
        setFetching(false);
      }
    })();
  }, deps);

  return [fetching, error];
};
