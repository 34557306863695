import React from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form} from 'react-final-form';
import styled from 'styled-components';
import {Button, FormGroup, Input, InputPassword, Notification} from '@xsolla/uikit';
import {FormDivider} from '../../@ui';
import {PasswordTooltip} from '../../PasswordTooltip';
import {ReactComponent as GoogleIcon} from '../../../assets/google_logo.svg';
import {
  composeValidators,
  confirmation,
  email,
  password,
  required
} from '../../../utils/validators';
import {requestSocialLogin} from '../../../utils/requester/login';
import {registerApplication} from '../../../redux/action/registration';
import {XSOLLA_POLICIES_LINK, XSOLLA_TERMS_LINK} from '../../../constant';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {AnalyticsEvent} from '../../../lib/XsollaAnalytics';
import {ToastType, useToasts} from '../../ToastProvider';
import {
  DuplicateUserError,
  ForbiddenEmailDomainError,
  InvalidEmailError
} from '../../../types/errors';
import {getEmailFromToken, getLoginIdFromToken} from '../../../lib/jwtToken';
import {AuthenticationType} from '../../../lib/XsollaAnalytics/AnalyticsEvent';

interface SignupCredentials {
  username: string;
  password: string;
  confirm_password: string;
}

const StyledForm = styled.form`
  & button {
    width: 100%;
  }
`;

const Policy = styled.div`
  padding: 0 16px;
  text-align: center;
  color: #00244d;
`;

const StyledGoogleIcon = styled(GoogleIcon)`
  margin-right: 12px;
`;

export const SignupForm = () => {
  const analytics = useAnalytics();

  const dispatch = useDispatch();
  const {addToast} = useToasts();

  const initialValues: SignupCredentials = {
    username: '',
    password: '',
    confirm_password: ''
  };

  const sendAnalytics = () => {
    const loginId = getLoginIdFromToken();
    const email = getEmailFromToken();

    analytics.customEvent(AnalyticsEvent.UserAuthenticated, {
      exv: {email, user_id: loginId, auth_type: AuthenticationType.RegistrationEmail}
    });
  };

  const onSubmit = async (credentials: SignupCredentials) => {
    try {
      await registerApplication(credentials)(dispatch);
      sendAnalytics();
    } catch (e) {
      let errMessage = 'An error has occurred';
      if (
        e instanceof DuplicateUserError ||
        e instanceof ForbiddenEmailDomainError ||
        e instanceof InvalidEmailError
      ) {
        errMessage = e.message;
      }

      addToast(errMessage, ToastType.Error);
    }
  };

  return (
    <Form<SignupCredentials>
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({handleSubmit, submitting, hasSubmitErrors}) => (
        <StyledForm onSubmit={handleSubmit}>
          <FormGroup indentation="lg">
            <FormGroup label="Email">
              <Field
                component={Input}
                type="email"
                name="username"
                spellCheck="false"
                placeholder="email@domain.com"
                validate={composeValidators(required, email)}
              />
            </FormGroup>

            <FormGroup label="Password" tooltip={<PasswordTooltip />}>
              <Field
                component={InputPassword}
                type="password"
                name="password"
                spellCheck="false"
                validate={composeValidators(required, password)}
              />
            </FormGroup>

            <FormGroup label="Confirm password">
              <Field
                component={InputPassword}
                type="password"
                name="confirm_password"
                spellCheck="false"
                validate={composeValidators(required, password, confirmation('password'))}
              />
            </FormGroup>

            {hasSubmitErrors && (
              <FormGroup>
                <Notification status="error" icon={true}>
                  A user with the specified username already exists.
                </Notification>
              </FormGroup>
            )}
          </FormGroup>

          <FormGroup indentation="lg">
            <Policy>
              By signing up for Xsolla, you agree to&nbsp;
              <a href={XSOLLA_TERMS_LINK} target="_blank" rel="noopener noreferrer">
                Terms&nbsp;of&nbsp;Use
              </a>
              &nbsp;and our&nbsp;
              <a href={XSOLLA_POLICIES_LINK} target="_blank" rel="noopener noreferrer">
                Privacy&nbsp;Policy
              </a>
              .
            </Policy>
          </FormGroup>

          <FormGroup>
            <Button type="submit" fetching={submitting}>
              Continue with Email
            </Button>
          </FormGroup>

          <FormGroup>
            <FormDivider>or</FormDivider>
          </FormGroup>

          <FormGroup>
            <Button
              type="button"
              appearance="outline"
              onClick={() => {
                requestSocialLogin('google');
              }}>
              <StyledGoogleIcon />
              <span>Continue with Google</span>
            </Button>
          </FormGroup>
        </StyledForm>
      )}
    />
  );
};
