import {Action, AnyAction} from 'redux';
import {clearCookie} from '../../../lib/cookies';
import {
  APPLICATION_SET_COMPANY_ID,
  APPLICATION_SET_LOGGED_IN,
  APPLICATION_SET_LOGOUT_STATE
} from '../../reducer/application';

/**
 *
 */
export const logOutApplication = (): Action => {
  clearCookie();
  return {
    type: APPLICATION_SET_LOGOUT_STATE
  };
};

/**
 *
 * @param payload
 */
export const setApplicationLoggedIn = (payload: boolean): AnyAction => ({
  type: APPLICATION_SET_LOGGED_IN,
  payload
});

/**
 *
 * @param payload
 */
export const setApplicationCompanyID = (payload: number): AnyAction => ({
  type: APPLICATION_SET_COMPANY_ID,
  payload
});
