import {COOKIES, getFromCookie} from '../../lib/cookies';
import {useOnboardingStatusApi} from '../../api/onboarding';

interface GetUserAccountProps {
  onSuccess: () => void;
  onOnboardingIsUncomplete?: () => void;
  onOnboardingIsPassed?: () => void;
  onUnknownError?: () => void;
}

interface UseOnboardingStatus {
  isFetchingOnboardingStatus: boolean;
  getOnboardingStatus: (props: GetUserAccountProps) => void;
}

export const useOnboardingStatus = (): UseOnboardingStatus => {
  const companyId = getFromCookie<number>(COOKIES.PRODUCT_COOKIE_COMPANY_ID);

  const {refetch: request, isFetching: isFetchingOnboardingStatus} = useOnboardingStatusApi(
    companyId
  );

  const getOnboardingStatus = async ({
    onSuccess,
    onOnboardingIsUncomplete,
    onOnboardingIsPassed,
    onUnknownError
  }: GetUserAccountProps) => {
    try {
      await request({throwOnError: true});
      onSuccess();
    } catch (error) {
      /* unauthorized */
      if (error?.response?.status === 401) {
        return;
      }

      /* onboarding is incomplete */
      if (error?.response?.data.code === 101) {
        onOnboardingIsUncomplete && onOnboardingIsUncomplete();
        // OnboardingStatus.UNCOMPLETED;
        return;
      }

      /* onboarding passed */
      if (error?.response?.data.code === 102) {
        onOnboardingIsPassed && onOnboardingIsPassed();
        // OnboardingStatus.PASSED;
        return;
      }

      onUnknownError && onUnknownError();

      throw error;
    }
  };

  return {
    isFetchingOnboardingStatus: isFetchingOnboardingStatus,
    getOnboardingStatus
  };
};
