import React, {useCallback} from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {FormState} from 'final-form';
import {GameDetails} from '../../api/game';
import {Button, FormFooter, FormGroup, Input, Notification, Textarea} from '@xsolla/uikit';
import {composeValidators, email, required} from '../../utils/validators';
import {ContactDeveloperFormValues} from './index';

interface ContactDeveloperFormProps {
  game: GameDetails;
  onSubmit: (values: ContactDeveloperFormValues) => void;
  onClose: () => void;
}

const ContactDeveloperForm = ({game, onSubmit, onClose}: ContactDeveloperFormProps) => {
  const temporaryValues: Partial<ContactDeveloperFormValues> = JSON.parse(
    window.localStorage.getItem(`ContactDeveloperForm.${game.id}`)
  );

  const initialValues: ContactDeveloperFormValues = {
    email: temporaryValues?.email || '',
    message: temporaryValues?.message || ''
  };

  const handleTemporarilySave = useCallback(
    ({values}: FormState<ContactDeveloperFormValues>) => {
      window.localStorage.setItem(`ContactDeveloperForm.${game.id}`, JSON.stringify(values));
    },
    [game]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({handleSubmit, submitting}) => (
        <form onSubmit={handleSubmit}>
          <FormSpy subscription={{values: true}} onChange={handleTemporarilySave} />

          <FormGroup>
            <Notification icon="alert" status="info" title="How it works">
              Thank you for taking the next step. We’ll send a direct email to {game.studio_name}.
              Please see your email for receipt of confirmation.
            </Notification>
          </FormGroup>

          <FormGroup label="Your email for contact">
            <Field
              name="email"
              component={Input}
              placeholder="email@example.com"
              validate={composeValidators(required, email)}
            />
          </FormGroup>

          <FormGroup label="Please, specify details to start dialog">
            <Field name="message" component={Textarea} validate={required} />
          </FormGroup>

          <FormFooter>
            <Button appearance="outline" onClick={onClose}>
              Close
            </Button>
            <Button type="submit" fetching={submitting} onClick={handleSubmit}>
              Submit
            </Button>
          </FormFooter>
        </form>
      )}
    />
  );
};

export default ContactDeveloperForm;
