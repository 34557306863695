import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form, FormSpy} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import {FormApi, FormState} from 'final-form';
import {useModal} from 'react-modal-hook';
import {Button, Input, SelectMultiple} from '@xsolla/uikit';
import classNames from 'classnames/bind';
import debounce from 'lodash/debounce';
import {DiscoverMoreFiltersModal} from '../../@modals';
import {FieldGroup, FieldGroupJustify} from '../../@ui';
import {FieldGroupSize} from '../../@ui';
import {setDiscoverFilterValue, setDiscoverSearchPage} from '../../../redux/action/discover-filter';
import {DiscoverFilterValue} from '../../../redux/types';
import {defaultDiscoverFilterValue} from '../../../redux/reducer/discover-filter';
import {FieldOption, getFieldOptions} from '../../../lib/fieldOptions';
import {ReactComponent as FilterLogo} from '../../../images/icons/filter-select.svg';
import {
  selectDiscoverFilterDirty,
  selectDiscoverFilterValue,
  selectDiscoverMoreFiltersCount
} from '../../../redux/selectors/discover-filter';
import style from './style.module.scss';

const cx = classNames.bind(style);

export const DiscoverFilterForm = () => {
  const dispatch = useDispatch();
  const initialValues = useSelector(selectDiscoverFilterValue, () => true);
  const discoverFilterDirty = useSelector(selectDiscoverFilterDirty);
  const filterCount = useSelector(selectDiscoverMoreFiltersCount);
  const needFilterCount = !!filterCount;

  useEffect(() => {
    if (!discoverFilterDirty) {
      dispatch(setDiscoverSearchPage(null));
    }
  }, [discoverFilterDirty]);

  const onChange = useCallback((formState: FormState<DiscoverFilterValue>) => {
    if (!formState.dirty) {
      return;
    }
    dispatch(setDiscoverFilterValue(formState.values));
  }, []);

  const debouncedChange = debounce(onChange, 360);

  const [onOpenMoreFilters, onCloseMoreFilters] = useModal(() => (
    <DiscoverMoreFiltersModal
      onSubmit={async (values: DiscoverFilterValue) => {
        await dispatch(setDiscoverFilterValue(values));
        return Promise.resolve().then(() => onCloseMoreFilters());
      }}
      onClose={onCloseMoreFilters}
    />
  ));

  const onReset = (form: FormApi<DiscoverFilterValue>) => {
    form.reset(defaultDiscoverFilterValue);
    dispatch(setDiscoverFilterValue(defaultDiscoverFilterValue));
  };

  return (
    <Form<DiscoverFilterValue>
      initialValues={initialValues}
      onSubmit={() => void 0}
      mutators={{
        getAll: ([name], state) => state.formState.values[name],
        removeAll: () => void 0,
        ...arrayMutators
      }}
      render={({handleSubmit, form}) => (
        <form onSubmit={handleSubmit}>
          <FormSpy subscription={{values: true, dirty: true}} onChange={debouncedChange} />

          <FieldGroup size={FieldGroupSize.Small} justify={FieldGroupJustify.Between}>
            <FieldGroup size={FieldGroupSize.Small}>
              <div className={cx(style.item, style.select, needFilterCount && style.small)}>
                <FieldArray
                  name="genres"
                  component={SelectMultiple}
                  options={getFieldOptions(FieldOption.Genres)}
                  placeholder="Genre"
                  size="sm"
                />
              </div>
              <div className={cx(style.item, style.select, needFilterCount && style.small)}>
                <FieldArray
                  name="platforms"
                  component={SelectMultiple}
                  options={getFieldOptions(FieldOption.InitialPlatforms)}
                  placeholder="Platform"
                  size="sm"
                />
              </div>
              <div className={cx(style.item, style.more, needFilterCount && style.icon)}>
                <Input
                  readOnly={true}
                  name="basic-input"
                  onClick={onOpenMoreFilters}
                  input={{
                    value: (
                      <div className={cx(style.moreFilter)}>
                        <FilterLogo className={cx(style.modalIcon)} />
                        <div>More filters</div>
                        {needFilterCount && <div className={cx(style.count)}>{filterCount}</div>}
                      </div>
                    )
                  }}
                  size="sm"
                />
              </div>
            </FieldGroup>

            {discoverFilterDirty && (
              <div className={cx(style.item)}>
                <Button
                  appearance="flatten"
                  icon="circle-remove-mini"
                  onClick={() => onReset(form)}>
                  Clear filters
                </Button>
              </div>
            )}

            <div className={cx(style.item, style.query)}>
              <Field
                name="query"
                component={Input}
                placeholder="Search by title..."
                size="sm"
                icon="search"
                offset="left"
                parse={(val: string): string => val}
              />
            </div>
          </FieldGroup>
        </form>
      )}
    />
  );
};
