import React, {ReactNode, useRef, useState} from 'react';
import classNames from 'classnames/bind';
import {Icon} from '@xsolla/uikit';
import {getChangeCardEvent} from './event';
import StepperLoader from './StepperLoader';
import style from './style.module.scss';

interface Props {
  count: number;
  hovered: boolean;
  animationTimeout: number;
}

const cx = classNames.bind(style);

export const DiscoverSliderControls = ({count, hovered, animationTimeout}: Props) => {
  const ref = useRef<HTMLDivElement>();
  const [index, setIndex] = useState<number>(0);
  const [disabled, setDisabled] = useState<boolean>(false);

  const sendCardChangedEvent = (type: string) => {
    if (type && ref.current) {
      ref.current.dispatchEvent(getChangeCardEvent(type));
    }
  };

  const setNewIndex = (index: number) => {
    setIndex(index);
    setDisabled(false);
  };

  const updateIndex = (delta: number) => (currentIndex: number = index) => {
    setDisabled(true);
    setTimeout(() => {
      const length = count - 1;
      const newIndex = Number(currentIndex + delta);
      sendCardChangedEvent(delta > 0 ? 'next' : 'prev');

      if (newIndex > length) {
        setNewIndex(0);
        return;
      }

      if (newIndex < 0) {
        setNewIndex(length);
        return;
      }

      setNewIndex(newIndex);
    }, animationTimeout);
  };

  const getDots = () => {
    const dots: Array<ReactNode> = [];
    for (let i = 0; i < count; i++) {
      dots.push(
        index === i ? (
          <StepperLoader
            stop={hovered}
            key={i}
            setNewIndex={(delta: number) => updateIndex(delta)}
          />
        ) : (
          <div className={cx(style.dot)} key={i} />
        )
      );
    }

    return dots;
  };

  if (count < 2) {
    return null;
  }

  return (
    <div className={cx(style.controls, disabled && style.disabled)} ref={ref}>
      <div className={cx(style.arrow, style.left)}>
        <Icon name="chevron-reverse" onClick={() => updateIndex(-1)()} />
      </div>
      <>{getDots()}</>
      <div className={cx(style.arrow, style.right)}>
        <Icon name="chevron" onClick={() => updateIndex(1)()} />
      </div>
    </div>
  );
};
