import {AxiosError} from 'axios';
import {UseMutationResult, useMutation} from 'react-query';
import {requestSaveInvestorProfile} from './requests';
import {SaveInvestorProfileParams} from './types';

export const useSaveInvestorProfileApi = (): UseMutationResult<
  void,
  AxiosError,
  SaveInvestorProfileParams
> => {
  return useMutation(props => requestSaveInvestorProfile(props));
};
