import React, {HTMLAttributes} from 'react';
import Imgix from 'react-imgix';
import styled from 'styled-components';
import {ImageReplacer} from './@ui';
import {prepareUrlImgix} from '../lib/imgix';

export enum CardImageSize {
  Default,
  Large
}

interface CardImageProps extends HTMLAttributes<HTMLDivElement> {
  src: string;
  placeholder: string;
  size?: CardImageSize;
  onClick?: () => void;
}

const sizeMap = new Map([
  [CardImageSize.Default, {width: 310, height: 144}],
  [CardImageSize.Large, {width: 508, height: 242}]
]);

const StyledImage = styled(Imgix)`
  background-color: #f5f6f8;
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 8px;
  transition: transform ease-in-out 240ms;
`;

export const CardImage = ({src, placeholder, size = CardImageSize.Default}: CardImageProps) => {
  const unmappedSize = sizeMap.get(size);

  if (!src) {
    return <ImageReplacer>{placeholder}</ImageReplacer>;
  }

  return (
    <StyledImage
      src={prepareUrlImgix(src)}
      width={unmappedSize.width}
      height={unmappedSize.height}
      imgixParams={{fit: 'crop', lossless: true}}
    />
  );
};
