import styled, {css} from 'styled-components';

export const LogoContainer = styled.div<{$logged: boolean}>`
  width: 226px;
  height: 100px;
  cursor: pointer;

  svg {
    height: 100%;
  }

  ${({$logged}) =>
    $logged &&
    css`
      width: 160px;
      height: 80px;
    `}
`;
