import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useAsyncRequest} from '../../hooks/useAsyncRequest';
import {loginApplicationSocial} from '../../redux/action/registration';
import {Routes} from '../../constant';
import {useAnalytics} from '../../hooks/useAnalytics';
import {AnalyticsEvent} from '../../lib/XsollaAnalytics';
import {getEmailFromToken, getLoginIdFromToken} from '../../lib/jwtToken';
import {AuthenticationType} from '../../lib/XsollaAnalytics/AnalyticsEvent';

/**
 * This component handles social login.
 */
export const VerifyPage = () => {
  const analytics = useAnalytics();

  const dispatch = useDispatch();
  const history = useHistory();

  const sendAnalytics = () => {
    const loginId = getLoginIdFromToken();
    const email = getEmailFromToken();

    analytics.customEvent(AnalyticsEvent.UserAuthenticated, {
      exv: {email, user_id: loginId, auth_type: AuthenticationType.Social}
    });
  };

  useAsyncRequest(async () => {
    try {
      await loginApplicationSocial(window.location.href)(dispatch);
      sendAnalytics();
    } catch (err) {
      history.replace(Routes.LOGIN);
    }
  }, []);

  return null;
};
