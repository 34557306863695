import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';
import {ReactComponent as Logotype} from './logotype.svg';
import {mediaDevices} from '../../../lib/styled';

export enum BrandSize {
  Large = 'Large',
  Normal = 'Normal',
  Small = 'Small'
}

interface BrandProps {
  label?: ReactNode;
  size?: BrandSize;
  fontSize?: number;
}

const StyledBrand = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

const StyledLogotype = styled(Logotype)<{size: BrandSize}>`
  ${p =>
    p.size === BrandSize.Large &&
    css`
      @media screen and ${mediaDevices.mobile} {
        width: 64px;
        height: 64px;
      }
      @media screen and ${mediaDevices.desktop} {
        width: 100px;
        height: 100px;
      }
    `}

  ${p =>
    p.size === BrandSize.Normal &&
    css`
      width: 72px;
      height: 72px;
    `}

  ${p =>
    p.size === BrandSize.Small &&
    css`
      width: 56px;
      height: 56px;
    `}
`;

const Label = styled.div<{size: BrandSize}>`
  ${p =>
    p.size === BrandSize.Large &&
    css`
      @media screen and ${mediaDevices.mobile} {
        margin-left: 20px;
        font-size: 15px;
        margin-top: 8px;
      }
      @media screen and ${mediaDevices.desktop} {
        margin-left: 32px;
        font-size: 22px;
        margin-top: 8px;
      }
    `}

  ${p =>
    p.size === BrandSize.Normal &&
    css`
      margin-left: 24px;
      font-size: 22px;
      margin-top: 8px;
    `}

          ${p =>
    p.size === BrandSize.Small &&
    css`
      margin-left: 12px;
      font-size: 15px;
    `}
  
  font-family: var(--fontFamilyLogin);
  font-weight: 700;
  line-height: normal;
  max-width: 180px;
`;

export const Brand = ({label, size = BrandSize.Normal}: BrandProps) => (
  <StyledBrand>
    <StyledLogotype size={size} />
    {label && <Label size={size}>{label}</Label>}
  </StyledBrand>
);
