import React from 'react';
import styled from 'styled-components';
import {Loader} from '@xsolla/uikit';

const StyledExploreGamesPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 966px;
`;

export const ExploreGamesPlaceholder = () => (
  <StyledExploreGamesPlaceholder>
    <Loader />
  </StyledExploreGamesPlaceholder>
);
