import {OnboardingCompanyInfoStepParams} from './types';

export const serializeOnboardingCompanyInfoStep = ({
  accredited_investor,
  company_name,
  crunchbase_url,
  deal_ranges,
  investment_specialization
}: OnboardingCompanyInfoStepParams): FormData => {
  const formData = new FormData();
  const accreditedInvestor = Boolean(parseInt(accredited_investor));

  formData.append('company_name', company_name);
  formData.append('crunchbase_url', crunchbase_url);
  formData.append('investment_specialization', investment_specialization);
  formData.append('accredited_investor', accreditedInvestor.toString());
  deal_ranges.forEach(x => {
    formData.append('deal_ranges', x);
  });

  return formData;
};
