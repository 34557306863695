import {investorApiClient} from '../apiClient';
import {InvestorProfile, UserAccount, UserMainInfo} from './types';

export const requestUserAccount = async (companyId: number): Promise<UserAccount> => {
  const {data} = await investorApiClient.get<UserAccount>(`/company/${companyId}/settings/user`);
  return data;
};

export const requestInvestorProfile = async (companyId: number): Promise<InvestorProfile> => {
  const {data} = await investorApiClient.get<InvestorProfile>(
    `/company/${companyId}/settings/profile`
  );
  return data;
};

export const requestMainUserInfo = async (companyId: number): Promise<UserMainInfo> => {
  const {data} = await investorApiClient.get<UserMainInfo>(`/company/${companyId}/user/main-info`);
  return data;
};
