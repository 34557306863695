/* eslint-disable */

export const websiteRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const httpHttpsStartsRegex = /^(http|https):/;

export const nonZeroAtBegin = /^$|^[1-9][0-9]*$/;

export const onlyNumber = /[^\d+]/g;

/**
 * At least one lowercase latin letter regular expression
 * @returns {regex object}
 */
export const atLeastOneSmallLetter = /^(?=.*[a-z])/;

/**
 * At least one uppercase latin letter regular expression
 * @returns {regex object}
 */
export const atLeastOneCapitalLetter = /^(?=.*[A-Z])/;

/**
 * At least one digit regular expression
 * @returns {regex object}
 */
export const atLeastOneDigit = /^(?=.*\d)/;

/**
 * Password available symbols regular expression
 * @returns {regex object}
 */
export const passwordAllowedSymbols = /^[0-9a-zA-Z!@#$%^&*()_\-+§±{};:"`|<>?№\\\/,.~=]*$/;

/**
 * Coupon code available symbols regular expression
 * @returns {regex object}
 */
export const couponAllowedSymbols = /^[0-9a-zA-Z_\-]*$/;

export const tokenUrlMatch = /\?(?:token)=([\S\s]*?)&/;
