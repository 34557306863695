import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const GameName = styled.div`
  margin: 0 0 8px;
  font-size: 15px;
  font-weight: bold;
`;

export const Card = styled(Link)`
  display: flex;
  width: 309px;
  flex-direction: column;
  margin-bottom: 24px;
  color: inherit;
  cursor: pointer;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    img {
      transform: scale(1.1);
    }

    ${GameName} {
      color: ${({theme}) => theme.colors.brand};
    }
  }
`;

export const ImageContainer = styled.div`
  overflow: hidden;
  height: 144px;
  border-radius: 8px;
`;

export const Info = styled.div`
  margin-top: 9px;
`;

export const InfoHeader = styled.div`
  margin-bottom: 16px;
`;

export const TagContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;

export const PropertyGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 1px minmax(0, 1fr);
  gap: 24px;
  margin: 0 0 8px;
`;

export const PropertyGridDivider = styled.div`
  width: 1px;
  height: 100%;
  background: ${({theme}) => theme.colors.stroke};
`;

export const PropertyLabel = styled.p`
  ${({theme}) => theme.typography.descriptionCompact}
  color: ${({theme}) => theme.colors.disabled};
  margin: 0;
`;

export const TargetReleseDate = styled.p`
  ${({theme}) => theme.typography.description}
  color: ${({theme}) => theme.colors.text};
  margin: 0;
`;

export const FundingGoal = styled.p`
  ${({theme}) => theme.typography.subHeader2}
  color: ${({theme}) => theme.colors.brand};
  margin: 0;
`;
