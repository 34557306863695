import {LanguageState} from '../types/language';
import {AnyAction} from 'redux';

const initialState: LanguageState = {
  language: navigator.language
};

export const languageReducer = (state = initialState, action: AnyAction): LanguageState => {
  switch (action.type) {
    default:
      return state;
  }
};
