import {investorApiClient} from '../apiClient';
import {SaveInvestorProfileParams} from './types';

export const requestSaveInvestorProfile = async ({
  companyId,
  values
}: SaveInvestorProfileParams): Promise<void> => {
  const {
    investment_specialization,
    investment_stages,
    company_name,
    deal_ranges,
    platforms,
    playable_builds,
    genres,
    monetization_types
  } = values;

  const formData = new FormData();
  formData.append('company_name', company_name);
  formData.append('investment_specialization', investment_specialization);
  formData.append('playable_builds', playable_builds);

  deal_ranges.forEach(i => {
    formData.append('deal_ranges', i);
  });
  genres.forEach(i => {
    formData.append('genres', i);
  });
  investment_stages.forEach(i => {
    formData.append('investment_stages', i);
  });
  monetization_types.forEach(i => {
    formData.append('monetization_types', i);
  });
  platforms.forEach(i => {
    formData.append('platforms', i);
  });

  await investorApiClient.put(`/company/${companyId}/settings/profile`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
