import {AxiosError} from 'axios';
import {UseMutationResult, UseQueryResult, useMutation, useQuery} from 'react-query';
import {requestContactDeveloper, requestGameDetails, requestGameInvestmentInfo} from './requests';
import {ContactDeveloperPayload, GameDetails, GameInvestmentInfo} from './types';

export const useGameDetailsQuery = (
  companyId: number,
  gameId: string
): UseQueryResult<GameDetails, AxiosError> => {
  return useQuery<GameDetails, AxiosError>(['game-details', companyId, gameId], () =>
    requestGameDetails(companyId, gameId)
  );
};

export const useContactDeveloperMutation = (
  companyId: number,
  gameId: number
): UseMutationResult<void, AxiosError, ContactDeveloperPayload> => {
  return useMutation((payload: ContactDeveloperPayload) =>
    requestContactDeveloper(companyId, gameId, payload)
  );
};

export const useGameInvestmentInfoQuery = (
  companyId: number,
  gameId: string
): UseQueryResult<GameInvestmentInfo, AxiosError> => {
  return useQuery<GameInvestmentInfo, AxiosError>(['game-investment-info', companyId, gameId], () =>
    requestGameInvestmentInfo(companyId, gameId)
  );
};
