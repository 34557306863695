export const request = (apiClient, method, url, data) => {
  switch (method) {
    case 'post':
      return apiClient.post(url, data);
    case 'get':
      return apiClient.get(url, data);
    case 'put':
      return apiClient.put(url, data);
    default: {
      // eslint-disable-next-line no-console
      console.error(`Method ${method} not implemented`);
      return;
    }
  }
};
