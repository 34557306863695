import React, {ReactNode, useRef, useState} from 'react';
import {InjectedIntl, injectIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import {Dropdown, Icon} from '@xsolla/uikit';
import {UserPanel} from '../../../@ui';
import {logOutApplication} from '../../../../redux/action/application';
import {actionUserLogout} from '../../../../redux/action/user';

interface OnboardingUserPanelProps {
  intl: InjectedIntl;
}

export const OnboardingUserPanel = injectIntl(({intl}: OnboardingUserPanelProps) => {
  const rootRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [active, setActive] = useState(false);

  const onClickLogout = () => {
    dispatch(actionUserLogout());
    dispatch(logOutApplication());
    history.replace('/');
  };

  const options: Array<ReactNode> = [
    {
      label: intl.formatMessage({id: 'app.header.profile.logout'}),
      value: 'logout',
      customIcon: <Icon name="logout" />,
      onClick: onClickLogout
    }
  ];

  useOnClickOutside(rootRef, () => setActive(false));

  return (
    <div ref={rootRef}>
      <Dropdown align="right" options={options} onClick={() => setActive(true)}>
        <UserPanel active={active} />
      </Dropdown>
    </div>
  );
});
