import {createSelector} from 'reselect';
import {RootState} from '../types/store';
import {LanguageState} from '../types/language';

export const selectApplicationLanguageRoot = createSelector(
  (state: RootState) => state.language,
  (language: LanguageState) => language
);

export const selectApplicationLanguage = createSelector(
  selectApplicationLanguageRoot,
  (language: LanguageState) => language.language
);
