import {METRIKA_COUNTER, METRIKA_ENABLE, METRIKA_HOST} from '../../constant/config';
import XsollaAnalytics from '@xsolla/metrika';

export const initAnalytics = () => {
  if (!METRIKA_ENABLE) {
    window.XsollaAnalyticsSDK = null;
    return;
  }
  try {
    const siteDomains = [
      'investor.xsolla.com',
      'funding-club-stage.web.app',
      'funding-club-stage.firebaseapp.com'
    ];

    window.XsollaAnalyticsSDK = new XsollaAnalytics({
      id: METRIKA_COUNTER,
      siteDomains,
      server: METRIKA_HOST
    });
  } catch {}
};
