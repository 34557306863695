import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';
import style from './style.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const cx = classNames.bind(style);

const WorkArea = ({children, className}: Props) => {
  return <div className={cx(style.work_area, className)}>{children}</div>;
};

export default WorkArea;
