import {createSelector} from 'reselect';
import {RootState} from '../types/store';

export const selectApplication = createSelector(
  (state: RootState) => state.application,
  application => application
);

export const selectApplicationCompanyID = createSelector(
  selectApplication,
  application => application.companyID
);

export const selectApplicationLoggedIn = createSelector(
  selectApplication,
  application => application.loggedIn
);
