import React, {Children, MutableRefObject, ReactNode} from 'react';
import Slider, {Settings as SliderSettings} from 'react-slick';

interface SlickSliderProps {
  children?: ReactNode;
  settings?: SliderSettings;
  sliderRef: MutableRefObject<Slider>;
}

const defaultSettings: SliderSettings = {
  adaptiveHeight: true,
  lazyLoad: 'ondemand',
  slidesToShow: 1,
  speed: 500,
  infinite: true,
  arrows: false,
  draggable: false
};

export const SlickSlider = ({
  children,
  sliderRef,
  settings = defaultSettings
}: SlickSliderProps) => {
  const sliderSettings = {...defaultSettings, ...settings};
  return (
    <Slider {...sliderSettings} ref={ref => (sliderRef.current = ref)}>
      {Children.map(children, child => child)}
    </Slider>
  );
};
