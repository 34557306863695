import React, {Fragment, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {Header} from '../../component/header';
import {GDPR} from '../../component/gdpr/GDPR';
import {Footer} from '../../component/Footer';
import {selectOnboardingStatus} from '../../redux/selectors/onboarding';
import {OnboardingStatus} from '../../constants/OnboardingStatus';
import {Bottom, Entry, StyledOfficeLayout} from './styled';

interface OfficeLayoutProps {
  children?: ReactNode;
  FooterDisclamer?: ReactNode;
}

export const OfficeLayout = ({children, FooterDisclamer}: OfficeLayoutProps) => {
  const tribeFix = useSelector(selectOnboardingStatus) === OnboardingStatus.APPROVED;

  return (
    <Fragment>
      <GDPR />
      <StyledOfficeLayout>
        <Header />
        <Entry tribeFix={tribeFix}>{children}</Entry>
        <Bottom>
          <Footer />
          {FooterDisclamer}
        </Bottom>
      </StyledOfficeLayout>
    </Fragment>
  );
};
