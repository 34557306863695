import {METRIKA_ENABLE} from '../../constant/config';
import {
  AnalyticsClick,
  AnalyticsCustomEvent,
  AnalyticsForm,
  AnalyticsHit
} from '../../types/xsolla';
import {XsollaAnalyticsError} from './XsollaAnalyticsError';

export const analyticsAdapter = (email: string, companyID: number, loginId: string) => {
  const sdk = window.XsollaAnalyticsSDK;
  const enabled = METRIKA_ENABLE && !!sdk;

  const hit: AnalyticsHit = (url, options = {}) => {
    if (!enabled) return;

    try {
      const extraValues = Object.assign({email, user_id: loginId}, options?.exv);
      sdk.hit(url, {...options, eid: companyID, exv: extraValues});
    } catch (err) {
      throw new XsollaAnalyticsError(err);
    }
  };

  const click: AnalyticsClick = (event, options) => {
    if (!enabled) return;

    try {
      const extraValues = Object.assign({email, user_id: loginId}, options?.exv);
      sdk.elementClick(event, {...options, eid: companyID, exv: extraValues});
    } catch (err) {
      throw new XsollaAnalyticsError(err);
    }
  };

  const form: AnalyticsForm = (event, data = {}, options = {}) => {
    if (!enabled) return;

    try {
      const extraValues = Object.assign({email, user_id: loginId}, options?.exv);
      sdk.formData(event, data, {...options, eid: companyID, exv: extraValues});
    } catch (err) {
      throw new XsollaAnalyticsError(err);
    }
  };

  const customEvent: AnalyticsCustomEvent = (event, options = {}) => {
    if (!enabled) return;

    try {
      const extraValues = Object.assign({email, user_id: loginId}, options?.exv);
      sdk.customEvent(event, {...options, eid: companyID, exv: extraValues});
    } catch (err) {
      throw new XsollaAnalyticsError(err);
    }
  };

  return {hit, click, form, customEvent};
};
