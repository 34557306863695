import React, {FC} from 'react';
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Loader} from '@xsolla/uikit';
import {OnboardingPage, xfcPageMap} from '../../page/OnboardingPage';
import {ConfirmationPage} from '../../page/ConfirmationPage';
import WorkArea from '../../component/work-area';
import {OfficeLayout} from '../../layouts';
import {selectApplicationCompanyID} from '../../redux/selectors/application';
import {selectOnboardingStatus} from '../../redux/selectors/onboarding';
import {OnboardingStatus} from '../../constants/OnboardingStatus';
import {Routes} from '../../constant';
import {useAsyncRequest} from '../../hooks/useAsyncRequest';
import {setOnboardingPagesData} from '../../redux/action/onboarding';
import {Disclaimer} from './components';
import {LoaderContainer, StyledOnboardingContainer, Wrapper} from './styled';
import {useOnboardingData} from './useOnboardingData';

export const OnboardingContainer: FC = () => {
  const dispatch = useDispatch();
  const onboardingStatus = useSelector(selectOnboardingStatus);
  const companyID = useSelector(selectApplicationCompanyID);
  const {fetchOnboardingData} = useOnboardingData(companyID);

  const pageSize = xfcPageMap.length;
  const setOnboardingData = setOnboardingPagesData;

  const [fetching] = useAsyncRequest(async () => {
    try {
      const responseArray = [];
      for (let i = 0; i < pageSize; i++) {
        responseArray.push(await fetchOnboardingData(i + 1));
      }
      dispatch(setOnboardingData(responseArray));
    } catch (err) {
      throw err;
    }
  });

  const getContent = () => {
    if (fetching) {
      return (
        <LoaderContainer>
          <Loader size="xlg" color="blue" />
        </LoaderContainer>
      );
    }

    if (onboardingStatus === OnboardingStatus.APPROVED) {
      return <Redirect to={Routes.OFFICE_DISCOVER} />;
    }

    if (onboardingStatus === OnboardingStatus.PASSED) {
      return <ConfirmationPage />;
    }

    return <OnboardingPage />;
  };

  return (
    <OfficeLayout FooterDisclamer={<Disclaimer />}>
      <StyledOnboardingContainer>
        <Wrapper>
          <WorkArea>{getContent()}</WorkArea>
        </Wrapper>
      </StyledOnboardingContainer>
    </OfficeLayout>
  );
};
