import React, {Fragment} from 'react';
import {InjectedIntl, injectIntl} from 'react-intl';
import {FieldArray} from 'react-final-form-arrays';
import {Field} from 'react-final-form';
import {MultiCheckbox, RadioGroup} from '../../../@ui';
import {required, requiredArray} from '../../../../utils/validators';
import {PageTitle} from '../Title';
import {
  getGenres,
  getInvestmentStages,
  getMonetizationTypes,
  getPlatforms,
  getPlayableBuild
} from './options';
import {XFCCompanyInfoPage} from '../CompanyInfo';
import {FormGroup} from '../../../@ui/FormGroup';

interface Props {
  intl: InjectedIntl;
  settings?: boolean;
}

export const XFCInvestingInterestPage = injectIntl(({intl, settings = false}: Props) => {
  return (
    <Fragment>
      {!settings && <PageTitle title="app.page.onboardingpage.page-title.investing-interest" />}

      {settings && <XFCCompanyInfoPage settings={settings} />}

      <FormGroup label={intl.formatMessage({id: 'app.page.onboardingpage.genres'})} isRequired>
        <FieldArray
          name="genres"
          component={MultiCheckbox}
          options={getGenres(intl)}
          validate={requiredArray}
        />
      </FormGroup>

      <FormGroup label={intl.formatMessage({id: 'app.page.onboardingpage.platforms'})} isRequired>
        <FieldArray
          name="platforms"
          component={MultiCheckbox}
          options={getPlatforms(intl)}
          validate={requiredArray}
        />
      </FormGroup>

      <FormGroup
        label={intl.formatMessage({id: 'app.page.onboardingpage.monetization-types'})}
        isRequired>
        <FieldArray
          name="monetization_types"
          component={MultiCheckbox}
          options={getMonetizationTypes(intl)}
          validate={requiredArray}
        />
      </FormGroup>

      <FormGroup
        label={intl.formatMessage({id: 'app.page.onboardingpage.investment-stages'})}
        isRequired>
        <FieldArray
          name="investment_stages"
          component={MultiCheckbox}
          options={getInvestmentStages(intl)}
          validate={requiredArray}
        />
      </FormGroup>

      <FormGroup
        label={intl.formatMessage({id: 'app.page.onboardingpage.playable-build'})}
        isRequired>
        <Field
          name="playable_builds"
          component={RadioGroup}
          options={getPlayableBuild(intl)}
          direction="row"
          validate={required}
        />
      </FormGroup>
    </Fragment>
  );
});
