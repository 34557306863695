import {useEffect, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {analyticsAdapter} from '../lib/XsollaAnalytics';
import {getEmailFromToken, getLoginIdFromToken} from '../lib/jwtToken';

import {selectApplicationCompanyID} from '../redux/selectors/application';

/**
 * Позже переместить в отдельную хуку (после удаления ErrorNotification).
 * @param value
 */
const usePrevious = <T>(value: T) => {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Returns a dynamic adapter for Xsolla Analytics.
 * Automatically puts `companyID`, `loginId` and `email` in events.
 */
export const useAnalytics = () => {
  const companyID = useSelector(selectApplicationCompanyID);
  const loginId = getLoginIdFromToken();
  const email = getEmailFromToken();

  return useMemo(() => analyticsAdapter(email, companyID, loginId), [companyID]);
};

/**
 * Observe page change and send `hit` event.
 */
export const useAnalyticsObserver = () => {
  const {hit} = useAnalytics();
  const location = useLocation();
  const previousLocation = usePrevious(location);

  useEffect(() => {
    hit(location.pathname, {referer: previousLocation ? previousLocation.pathname : ''});
  }, [location]);
};
