import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Brand, BrandSize} from './Brand';
import {Button} from './Button';
import {Routes} from '../../constant';
import {mediaDevices} from '../../lib/styled';

const LoginLink = styled(Link)`
  color: #ffffff;
  display: inline-flex;
  padding: 0 24px;
  line-height: normal;
`;

const JoinLink = styled(Link)`
  margin-left: 24px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
  flex-wrap: wrap;

  @media screen and ${mediaDevices.mobile} {
    & ${JoinLink} {
      display: none;
    }
  }
  @media screen and ${mediaDevices.desktop} {
    & ${JoinLink} {
      display: inline-block;
    }
  }
`;

const Extra = styled.div`
  margin: 8px 0 0 auto;
  display: flex;
  align-items: center;
`;

export const Header = () => (
  <StyledHeader>
    <Brand
      label={
        <Fragment>
          Investment
          <br />
          Platform
        </Fragment>
      }
      size={BrandSize.Large}
    />
    <Extra>
      <LoginLink to={Routes.LOGIN}>Log In</LoginLink>
      <JoinLink to={Routes.LOGIN_JOIN}>
        <Button>Sign up</Button>
      </JoinLink>
    </Extra>
  </StyledHeader>
);
