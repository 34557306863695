import {Dispatch} from 'react';
import {AnyAction} from 'redux';

export interface RegistrationState {
  fetching: boolean;
  error: Error;
}

export enum AuthType {
  REGISTER,
  LOGIN,
  SOCIAL
}

export type DispatchLogin = Dispatch<(dispatch: Dispatch<AnyAction>) => Promise<void>>;
