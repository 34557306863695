import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';

interface SectionProps {
  children?: ReactNode;
  edged?: boolean;
}

const StyledSection = styled.div<Pick<SectionProps, 'edged'>>`
  margin-bottom: 100px;

  ${p =>
    p.edged &&
    css`
      padding-bottom: 100px;
      border-bottom: 1px solid #495c6b;
    `}
`;

export const Section = ({children, edged = false}: SectionProps) => (
  <StyledSection edged={edged}>{children}</StyledSection>
);
