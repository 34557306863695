import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';
import style from './style.module.scss';

export enum NotificationStatus {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Muted = 'muted'
}

interface NotificationProps {
  children: ReactNode;
  status: NotificationStatus;
}

const cx = classNames.bind(style);

export const Notification = ({children, status = NotificationStatus.Info}: NotificationProps) => (
  <div className={cx(style.notification, style[status])}>{children}</div>
);
