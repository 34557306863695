import {format, length} from 'redux-form-validators';
import {FieldValidator} from 'final-form';
import isEmail from 'validator/lib/isEmail';
import isUrl from 'validator/lib/isURL';
import {FieldValue} from '../lib/form';
import {
  atLeastOneCapitalLetter,
  atLeastOneDigit,
  atLeastOneSmallLetter,
  passwordAllowedSymbols
} from './regexp';

/**
 * Required field
 * @param value
 */
export const required: FieldValidator<FieldValue> = value => {
  if (!value) {
    return 'Required';
  }

  return void 0;
};

/**
 * Required array value
 * @param value
 */
export const requiredArray: FieldValidator<FieldValue[]> = value => {
  if (Array.isArray(value) && !value.length) {
    return 'Required';
  }

  return void 0;
};

/**
 * Email field
 * @param value
 */
export const email: FieldValidator<FieldValue> = value => {
  if (value && !isEmail(value.toString())) {
    return 'Invalid Email';
  }

  return void 0;
};

/**
 * Url field
 * @param value
 */
export const url: FieldValidator<FieldValue> = (value = '') => {
  if (!value) {
    return void 0;
  }

  if (!isUrl(value.toString(), {require_protocol: true})) {
    return 'Invalid URL';
  }

  return void 0;
};

/**
 * Password field
 * @param value
 * @param allValues
 */
export const password: FieldValidator<FieldValue> = (value, allValues) => {
  const errors = [
    length({min: 8, max: 255}),
    format({
      with: passwordAllowedSymbols,
      message: 'Field contains prohibited symbols'
    }),
    format({
      with: atLeastOneDigit,
      message: 'Must contain at least one numeral (0-9)'
    }),
    format({
      with: atLeastOneSmallLetter,
      message: 'Must contain at least one lowercase letter'
    }),
    format({
      with: atLeastOneCapitalLetter,
      message: 'Must contain at least one uppercase letter'
    })
  ]
    .map(v => v(value, allValues))
    .filter(Boolean);

  if (!!errors.length) {
    return errors[0];
  }

  return void 0;
};

/**
 * Confirmation field
 * @param field
 */
export const confirmation = (field: string): FieldValidator<FieldValue> => (value, allValues) => {
  if (value !== allValues[field]) {
    return 'Passwords do not match';
  }

  return void 0;
};

/**
 * Compose final-form validators
 * @param validators
 */
export const composeValidators = (
  ...validators: FieldValidator<FieldValue>[]
): FieldValidator<FieldValue> => (value, allValues, meta) =>
  validators.reduce(
    (error: string | undefined, validator: FieldValidator<FieldValue>) =>
      error || validator(value, allValues, meta),
    void 0
  );
