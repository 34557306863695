import React from 'react';
import classNames from 'classnames/bind';
import {Button, FormFooter, Head, Modal} from '@xsolla/uikit';
import style from './style.module.scss';

interface Props {
  onClose: () => void;
}

const cx = classNames.bind(style);

const HowToInvestModal = ({onClose}: Props) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <Head>
        <div className={cx(style.darkText)}>The Xsolla Investment Account Process</div>
      </Head>
      <div className={cx(style.darkText, style.uiFontFamily)}>
        <p>1. Input your selection criteria.</p>
        <p>2. Review qualified games.</p>
        <p>3. Choose a game that’s right for you.</p>
        <p>4. We provide you with everything you need to move forward.</p>
        <p>5. We facilitate a legal agreement between all parties.</p>
        <p>6. Congratulations, your investment is official!</p>
      </div>
      <FormFooter>
        <div />
        <Button onClick={onClose} appearance="outline">
          Close
        </Button>
      </FormFooter>
    </Modal>
  );
};

export default HowToInvestModal;
