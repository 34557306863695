import React from 'react';
import {Field, Form} from 'react-final-form';
import styled from 'styled-components';
import {Button, FormGroup, Input} from '@xsolla/uikit';
import {composeValidators, email, required} from '../../../utils/validators';
import {resetPasswordRequest} from '../../../utils/requester/password';

interface ForgotPasswordCredentials {
  username: string;
}

const StyledForm = styled.form`
  & button {
    width: 100%;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;
`;

export const ForgotPasswordForm = () => {
  const initialValues: ForgotPasswordCredentials = {
    username: ''
  };

  const onSubmit = async (credentials: ForgotPasswordCredentials) => {
    try {
      await resetPasswordRequest(credentials);
    } catch (err) {
      return err;
    }
  };

  return (
    <Form<ForgotPasswordCredentials>
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({handleSubmit, submitSucceeded, submitting, values}) => {
        if (submitSucceeded) {
          return (
            <SuccessMessage>
              Instructions have now been emailed to {values.username}. If you have trouble locating
              them, be sure to check your spam and junk folders.
            </SuccessMessage>
          );
        }

        return (
          <StyledForm onSubmit={handleSubmit}>
            <FormGroup indentation="lg">
              <FormGroup label="Email">
                <Field
                  component={Input}
                  type="email"
                  name="username"
                  placeholder="email@domain.com"
                  validate={composeValidators(required, email)}
                />
              </FormGroup>
            </FormGroup>

            <FormGroup>
              <Button type="submit" fetching={submitting}>
                Next
              </Button>
            </FormGroup>
          </StyledForm>
        );
      }}
    />
  );
};
