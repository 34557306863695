import {createSelector} from 'reselect';
import {RootState} from '../types/store';
import {OnboardingPageType} from '../types/onboarding';

export const selectOnboarding = createSelector(
  (state: RootState) => state.onboarding,
  onboarding => onboarding
);

export const selectOnboardingPersonalInfoPage = createSelector(
  selectOnboarding,
  onboarding => onboarding[OnboardingPageType.PERSONAL_INFO]
);

export const selectOnboardingCompanyInfoPage = createSelector(
  selectOnboarding,
  onboarding => onboarding[OnboardingPageType.COMPANY_INFO]
);

export const selectOnboardingInvestingInterestPage = createSelector(
  selectOnboarding,
  onboarding => onboarding[OnboardingPageType.INVESTING_INTEREST]
);

export const selectOnboardingStatus = createSelector(
  selectOnboarding,
  onboarding => onboarding.status
);
