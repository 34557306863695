import React from 'react';
import {useSelector} from 'react-redux';
import {ContentBlock} from '@xsolla/uikit';
import {selectDiscoverSearchSectionPage} from '../../redux/selectors/discover-filter';
import {DiscoverPageSectionsContainer} from '../../container/DiscoverPageSectionsContainer';
import {
  DiscoverFilter,
  DiscoverGames,
  DiscoverSearchPage,
  DiscoverSliderGames
} from '../../component/@discover';
import {DiscoverPageSectionType} from '../../redux/types/discover';
import {DISCOVER_PAGE_BROWSE_DEFAULT_LIMIT} from '../../constant';

export const DiscoverPage = () => {
  const searchSectionPage = useSelector(selectDiscoverSearchSectionPage);

  return (
    <ContentBlock>
      <DiscoverFilter />

      {searchSectionPage ? (
        <DiscoverSearchPage />
      ) : (
        <DiscoverPageSectionsContainer>
          <DiscoverSliderGames section={DiscoverPageSectionType.FLASH_DEALS} label="Flash deals" />
          <DiscoverSliderGames
            description="Games on last step to final deals"
            label="Most Funded"
            section={DiscoverPageSectionType.MOST_FUNDED}
          />
          <DiscoverGames
            label="Your matches"
            description="Games that match your investing criteria"
            section={DiscoverPageSectionType.RECOMMENDED}
          />
          <DiscoverSliderGames
            section={DiscoverPageSectionType.LAUNCHING_SOON}
            label="Launches soon"
            description="Games that launch soon"
          />
          <DiscoverGames
            label="New"
            description="Games recently added to Investor Account"
            section={DiscoverPageSectionType.NEW_EXCLUSIVES}
          />
          <DiscoverGames
            label="Browse games"
            section={DiscoverPageSectionType.BROWSE}
            limit={DISCOVER_PAGE_BROWSE_DEFAULT_LIMIT}
          />
        </DiscoverPageSectionsContainer>
      )}
    </ContentBlock>
  );
};
