import React from 'react';
import {Redirect} from 'react-router';
import {Route} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {OnboardingStatus} from '../constants/OnboardingStatus';
import {Routes} from '../constant';
import {OutdoorLayout} from '../layouts';
import {DeclinePageXFC} from '../page/DeclinePage';
import {selectOnboardingStatus} from '../redux/selectors/onboarding';

export const DeclineContainer = () => {
  const onboardingStatus = useSelector(selectOnboardingStatus);

  if (onboardingStatus === OnboardingStatus.APPROVED) {
    return <Redirect to={Routes.OFFICE_DISCOVER} />;
  }

  return (
    <OutdoorLayout>
      <Route path="/" component={DeclinePageXFC} />
    </OutdoorLayout>
  );
};
