import {AnyAction} from 'redux';
import {GDPR_SET_COMPONENT} from './actions';
import {GDPRState} from './types';

const initialState: GDPRState = {
  gdpr: null
};

export const gdprReducer = (state = initialState, action: AnyAction): GDPRState => {
  switch (action.type) {
    case GDPR_SET_COMPONENT:
      return {
        gdpr: action.payload.instance
      };
    default:
      return state;
  }
};
