import React, {Children, ReactNode} from 'react';
import styled from 'styled-components';

const StyledDiscoverPageSectionsContainer = styled.div`
  & > :last-child {
    all: unset;
  }
`;

const DiscoverPageSection = styled.div`
  border-bottom: #c5d0db 1px solid;
  margin-bottom: 64px;
  padding-bottom: 64px;

  &:empty {
    display: none;
  }
`;

interface DiscoverPageSectionsContainerProps {
  children: ReactNode;
}

export const DiscoverPageSectionsContainer = ({children}: DiscoverPageSectionsContainerProps) => {
  return (
    <StyledDiscoverPageSectionsContainer>
      {Children.map(children, child => (
        <DiscoverPageSection>{child}</DiscoverPageSection>
      ))}
    </StyledDiscoverPageSectionsContainer>
  );
};
