import React, {FC} from 'react';
import {Tooltip} from '@xsolla/ui-core';
import {FormGroupContainer, FormGroupLabel, OptionalMark} from './styled';

export interface FormGroupProps {
  label?: string;
  tooltip?: string;
  indent?: string;
  className?: string;
  isRequired?: boolean;
}

export const FormGroup: FC<FormGroupProps> = ({
  children,
  label,
  tooltip,
  indent = '24px',
  className = '',
  isRequired = false
}) => {
  return (
    <FormGroupContainer $indent={indent} className={className}>
      {label && (
        <FormGroupLabel>
          {label}
          {tooltip && (
            <>
              &nbsp;
              <Tooltip content={tooltip} placement="right-center" />
            </>
          )}
          {!isRequired && <OptionalMark />}
        </FormGroupLabel>
      )}

      {children}
    </FormGroupContainer>
  );
};
