import {requestDiscover} from '../utils/requester/discover';
import {DiscoverFilterValue} from '../redux/types';
import {DiscoverGamesResponse} from '../redux/types/discover';

interface DispatchDiscoverRequestParams {
  type: string;
  companyID: number;
  filters: {
    params: {page: number; limit: number};
    value?: DiscoverFilterValue;
  };
}

/**
 * Send request to get games for discover page
 * @param type - discover page section type
 * @param companyID
 * @param filters - filters params
 */
export const dispatchDiscoverRequest = async ({
  type,
  companyID,
  filters
}: DispatchDiscoverRequestParams): Promise<DiscoverGamesResponse> => {
  const requestParams = {
    offset: filters.params.limit * filters.params.page,
    limit: filters.params.limit
  };

  return await requestDiscover(
    companyID,
    type,
    filters.value ? {...requestParams, ...filters.value} : requestParams
  );
};
