import messages_en from '../../assets/translations/en.json';

const _translations = {
  en: messages_en
};

export const getTranslations = code => {
  return !_translations[code] ? _translations['en'] : _translations[code];
};

export const getShortBrowserLanguage = browserLanguage => browserLanguage.split(/[-_]/)[0];
