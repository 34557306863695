import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {selectApplicationLoggedIn} from '../redux/selectors/application';
import {Routes} from '../constant';

export const PublicRoute = ({...rest}: RouteProps) => {
  const loggedIn = useSelector(selectApplicationLoggedIn);
  const location = useLocation();

  if (loggedIn) {
    const redirectTo = new URLSearchParams(location.search).get('redirect');
    if (redirectTo) {
      return (
        <Route {...rest}>
          <Redirect to={redirectTo} />
        </Route>
      );
    }

    return (
      <Route {...rest}>
        <Redirect to={{pathname: Routes.OFFICE_DISCOVER}} />
      </Route>
    );
  }

  return <Route {...rest} />;
};
