export const extractUrlValue = (key, url) => {
  if (typeof url === 'undefined') url = window.location.href;
  const match = url.match('[?&]' + key + '=([^&]+)');
  return match ? match[1] : null;
};

/**
 *
 * @param rootObj - родительский объект
 * @param nodeKeyValue - из какого парамтреа в объекте взять значение
 * @param nodeValue - значение от nodeKey
 * @param nodeKey - ключ в объекте (уникальный)
 * @returns {undefined|*}
 */
export const getPropValue = (rootObj, nodeValue, nodeKeyValue = 'value', nodeKey = 'name') => {
  let resultValue;
  for (const prop in rootObj) {
    if (typeof rootObj[prop] === 'object') {
      resultValue = getPropValue(rootObj[prop], nodeValue, nodeKeyValue, nodeKey);
      if (resultValue !== undefined) {
        return resultValue;
      }
    }

    if (
      prop === nodeKey &&
      rootObj[nodeKey] === nodeValue &&
      rootObj.hasOwnProperty(nodeKeyValue)
    ) {
      return rootObj[nodeKeyValue];
    }
  }
};
