import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Loader} from '@xsolla/uikit';
import {requestUser} from '../../api/user';
import {Sidebar} from '../../component/sidebar';
import {OfficeLayout} from '../../layouts';
import {AccountPage, InvestorProfilePage, NotificationsPage} from '../../page/settings';
import {useAsyncRequest} from '../../hooks/useAsyncRequest';
import {actionUserUpdate} from '../../redux/action/user';
import {selectApplicationCompanyID} from '../../redux/selectors/application';
import {Routes} from '../../constant';
import style from './style.module.scss';

export const SettingsContainer = () => {
  const dispatch = useDispatch();
  const companyID = useSelector(selectApplicationCompanyID);

  const [fetching] = useAsyncRequest(async () => {
    const user = await requestUser(companyID);
    dispatch(actionUserUpdate(user));
  }, [dispatch, companyID]);

  return (
    <OfficeLayout>
      <div className={style.settingsContainer}>
        <div className={style.aside}>
          <Sidebar
            links={[
              {children: 'Account settings', to: Routes.SETTINGS_USER_ACCOUNT},
              {children: 'Investor profile', to: Routes.SETTINGS_USER_INVESTOR_PROFILE},
              {children: 'Notifications', to: Routes.SETTINGS_USER_NOTIFICATIONS}
            ]}
          />
        </div>
        <div className={style.entry}>
          {fetching ? (
            <Loader color="blue" centered={true} />
          ) : (
            <Switch>
              <Route path={Routes.SETTINGS_USER_ACCOUNT} component={AccountPage} />
              <Route path={Routes.SETTINGS_USER_INVESTOR_PROFILE} component={InvestorProfilePage} />
              <Route path={Routes.SETTINGS_USER_NOTIFICATIONS} component={NotificationsPage} />
              <Redirect from="*" to={Routes.SETTINGS_USER_ACCOUNT} />
            </Switch>
          )}
        </div>
      </div>
    </OfficeLayout>
  );
};
