import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import styled, {keyframes} from 'styled-components';
import {BrowseGame, requestBrowseGames} from '../../../api/guest/browseGames';
import {ExploreGamesPlaceholder} from '../../@placeholders/ExploreGamesPlaceholder';
import {useAsyncRequest} from '../../../hooks/useAsyncRequest';
import {mediaDevices} from '../../../lib/styled';
import {Button, Section} from '../index';
import {GameCard} from './GameCard';
import {Routes} from '../../../constant';
import {subTitleTypography} from '../typography';

const exploreGamesAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledExploreGames = styled.div`
  position: relative;
  animation: ${exploreGamesAnimation} ease-in-out 240ms;

  &::after {
    position: absolute;
    left: -15px;
    right: -15px;
    bottom: -15px;
    top: -15px;
    background: linear-gradient(180deg, rgba(1, 22, 39, 0) 30%, #011627 95%);
    content: ' ';
    pointer-events: none;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
`;

const SubTitle = styled.h2`
  ${subTitleTypography};
  margin: 0 0 60px 0;
  text-align: center;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px;

  @media screen and ${mediaDevices.mobile} {
    width: 100%;
  }

  @media screen and ${mediaDevices.tablet} {
    width: 50%;
  }

  @media screen and ${mediaDevices.desktop} {
    width: 33.33%;
  }
`;

const Action = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
`;

export const ExploreGames = () => {
  const [browseGames, setBrowseGames] = useState<BrowseGame[]>([]);

  const [fetching] = useAsyncRequest(async () => {
    setBrowseGames(await requestBrowseGames());
  }, []);

  if (fetching) {
    return (
      <Section edged={true}>
        <ExploreGamesPlaceholder />
      </Section>
    );
  }

  if (!browseGames.length) {
    return <Section edged={true} />;
  }

  return (
    <Section edged={true}>
      <StyledExploreGames>
        <SubTitle>Explore tons of pre-qualified games</SubTitle>
        <Grid>
          {browseGames.map(game => (
            <Item key={`game-card-${game.id}`}>
              <GameCard game={game} />
            </Item>
          ))}
        </Grid>

        <Action>
          <Link to={Routes.LOGIN_JOIN}>
            <Button>Explore more games</Button>
          </Link>
        </Action>
      </StyledExploreGames>
    </Section>
  );
};
