import {FieldValue} from '../form';
export type FieldRecord = Record<string, FieldValue>;

export const gameGenresFieldOptions: FieldRecord[] = [
  {id: 22, label: 'Action', value: 22},
  {id: 23, label: 'Adventure', value: 23},
  {id: 24, label: 'Role Playing Game (RPG)', value: 24},
  {id: 25, label: 'Simulation', value: 25},
  {id: 26, label: 'Strategy', value: 26},
  {id: 27, label: 'Sports', value: 27}
];

export const teamSizeFieldOptions: FieldRecord[] = [
  {label: '1-5', value: 1},
  {label: '6-12', value: 2},
  {label: '13-20', value: 3},
  {label: '21-50', value: 4},
  {label: '51-150', value: 5},
  {label: '151-300', value: 6},
  {label: '301+', value: 7}
];

export const raisedPercentageFieldOptions: FieldRecord[] = [
  {label: 'up to 10%', value: '0-10%'},
  {label: '10 to 50%', value: '10-50%'},
  {label: '50 to 100%', value: '50-100%'}
];

export const investmentPreferRangeFieldOptions: FieldRecord[] = [
  {label: '$0 - $100k', value: '0-100k'},
  {label: '$100k - $500k', value: '100-500k'},
  {label: '$500k - $1M', value: '500k-1m'},
  {label: '$1M - $2.5M', value: '1m-2.5m'},
  {label: '$2.5M - $5M', value: '2.5m-5m'},
  {label: '$5 - $10M', value: '5m-10m'},
  {label: '$10M+', value: '10m'}
];

export const initialPlatformsFieldOptions: FieldRecord[] = [
  {id: 85, label: 'PC', value: 85},
  {id: 86, label: 'Browser-Based', value: 86},
  {id: 87, label: 'Switch', value: 87},
  {id: 88, label: 'Xbox One', value: 88},
  {id: 89, label: 'Playstation 4', value: 89},
  {id: 90, label: 'Console Next-Gen', value: 90},
  {id: 91, label: 'Mobile', value: 91}
];

export const gameEngineFieldOptions: FieldRecord[] = [
  {id: 8, label: 'Unreal'},
  {id: 9, label: 'Unity'},
  {id: 10, label: 'CryEngine / Lumberyard'},
  {id: 11, label: 'Proprietary'}
];

export const monetizationModelFieldOptions: FieldRecord[] = [
  {id: 'f2p', label: 'Free to Play'},
  {id: 'p2p', label: 'Premium'}
];

export const subgenresFieldOptions: FieldRecord[] = [
  {id: 29, label: 'Platformer'},
  {id: 30, label: 'First-Person Shooter (FPS)'},
  {id: 31, label: 'Shooter'},
  {id: 32, label: 'Fighting'},
  {id: 33, label: 'Stealth'},
  {id: 34, label: 'Survival'},
  {id: 35, label: 'Rhythm'},
  {id: 36, label: 'Horror'},
  {id: 37, label: 'Metroidvania'},
  {id: 38, label: 'Text-Based'},
  {id: 39, label: 'Interactive Movie'},
  {id: 40, label: 'MMORPG'},
  {id: 41, label: 'Rogue-like'},
  {id: 42, label: 'Sandbox RPG'},
  {id: 43, label: 'First-person party-based RPG'},
  {id: 44, label: 'Fantasy'},
  {id: 45, label: 'Construction/Management'},
  {id: 46, label: 'Real Time Strategy (RTS)'},
  {id: 47, label: 'Multiplayer Online Battle Arena (MOBA)'},
  {id: 48, label: 'Tower Defense'},
  {id: 49, label: 'Turn-based strategy (TBS)'},
  {id: 50, label: 'Racing'},
  {id: 51, label: 'Casual'},
  {id: 52, label: 'Party'},
  {id: 53, label: 'Educational'},
  {id: 54, label: '2D'},
  {id: 55, label: 'Puzzle'},
  {id: 56, label: 'Open World'},
  {id: 57, label: 'Trading Card Game (TCG)'}
];

export const playableBuildFieldOptions: FieldRecord[] = [
  {label: 'Yes', radioValue: 'yes'},
  {label: 'No', radioValue: 'no'},
  {label: 'Not sure', radioValue: 'maybe'}
];
