import React, {FC} from 'react';
import ReactDOM from 'react-dom';
import {Provider, connect} from 'react-redux';
import {GlobalProviders} from './features/GlobalProviders';
import {App} from './app';
import {initAnalytics} from './lib/XsollaAnalytics';
import {initSentry} from './Sentry';
import {selectApplicationLanguage} from './redux/selectors/language';
import {RootState} from './redux/types/store';
import store from './redux/store';
import 'fullscreen-polyfill';

initSentry();
initAnalytics();

export const Main: FC = () => {
  return (
    <GlobalProviders>
      <App />
    </GlobalProviders>
  );
};

const AppContainer = connect((state: RootState) => ({
  currentLang: selectApplicationLanguage(state)
}))(Main);

ReactDOM.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('root')
);
