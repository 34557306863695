import {AnyAction} from 'redux';
import {AppThemeEnum, AppThemeState} from '../types/theme';

// TODO: @ds.pankov remove this

const initialState: AppThemeState = {
  theme: AppThemeEnum.LIGHT
};

export const APP_THEME_SET_THEME = '@/app-theme/SET_THEME';

export const appThemeReducer = (state = initialState, action: AnyAction): AppThemeState => {
  switch (action.type) {
    case APP_THEME_SET_THEME:
      return {
        theme: action.payload
      };
    default:
      return state;
  }
};
