import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {Button, ContentBlock, FormFooter, Head} from '@xsolla/uikit';
import {ToastType, useToasts} from '../../component/ToastProvider';
import {actionUserUpdateInvestorProfile} from '../../redux/action/user';
import {selectApplicationCompanyID} from '../../redux/selectors/application';
import {selectUserInvestorProfile} from '../../redux/selectors/user';
import {InvestorProfile} from '../../api/user';
import {XFCInvestingInterestPage} from '../../component/@fields';
import style from './style.module.scss';
import {useInvestorProfileForm} from './useInvestorProfileForm';

export const InvestorProfilePage = () => {
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const companyId = useSelector(selectApplicationCompanyID);
  const initialValues = {
    ...useSelector(selectUserInvestorProfile)
  };

  const {save} = useInvestorProfileForm();

  const onSubmitForm = async (investorProfile: InvestorProfile) => {
    await save({
      companyId,
      values: investorProfile,
      onSuccess: () => {
        dispatch(actionUserUpdateInvestorProfile(investorProfile));
        addToast('Changes have been saved!', ToastType.Success);
      },
      onError: () => {
        addToast('An error has occurred', ToastType.Error);
      }
    });
  };

  return (
    <ContentBlock>
      <Form<InvestorProfile>
        onSubmit={onSubmitForm}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
          getAll: ([name], state) => state.formState.values[name],
          removeAll: () => void 0
        }}
        render={({handleSubmit, valid, pristine, form, submitting}) => (
          <form onSubmit={handleSubmit}>
            <Head indentation="lg">Investor profile</Head>

            <XFCInvestingInterestPage settings={true} />

            <div className={style.footer}>
              <FormFooter>
                <Button appearance="outline" onClick={form.reset}>
                  Discard changes
                </Button>
                <Button
                  type="submit"
                  fetching={submitting}
                  disabled={submitting || !valid || pristine}>
                  Save changes
                </Button>
              </FormFooter>
            </div>
          </form>
        )}
      />
    </ContentBlock>
  );
};
