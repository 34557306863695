import React from 'react';
import classNames from 'classnames/bind';
import {useUserMainInfo} from '../../../api/user/queries';
import {ReactComponent as ChevronDown} from './chevron-down.svg';
import {ReactComponent as UserIcon} from './user.svg';
import style from './style.module.scss';

interface UserPanelProps {
  active?: boolean;
  onClick?: () => void;
}

const cx = classNames.bind(style);

export const UserPanel = ({active = false, onClick = () => void 0}: UserPanelProps) => {
  const {data: userMainInfo} = useUserMainInfo();

  return (
    <div className={cx(style.userPanel)}>
      <div className={cx(style.aside)}>
        {userMainInfo && (
          <>
            <div className={style.name}>
              {[userMainInfo.firstName, userMainInfo.lastName].join(' ')}
            </div>
            <div className={style.company}>{userMainInfo.companyName}</div>
          </>
        )}
      </div>

      <div className={cx(style.trigger, active && style.active)} onClick={onClick}>
        <div className={style.icon}>
          <UserIcon />
        </div>

        <ChevronDown className={style.chevron} />
      </div>
    </div>
  );
};
