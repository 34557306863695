import {DiscoverPageSectionSize, DiscoverPageSectionType} from '../redux/types/discover';

export const discoverPageSectionSize = (
  pageSection: DiscoverPageSectionType
): DiscoverPageSectionSize => {
  if ([DiscoverPageSectionType.LAST_CHANCE_TO_INVEST].includes(pageSection)) {
    return DiscoverPageSectionSize.SMALL_SLIDER;
  }

  return DiscoverPageSectionSize.DEFAULT_SLIDER;
};

const monetizationTypeTag = new Map<string, string>([
  ['f2p', 'Free-to-Play'],
  ['p2p', 'Premium']
]);

/**
 * Get monetization_type tag for discover game card
 */
export const getDiscoverGameMonetizationTypeTag = (type: string): string =>
  monetizationTypeTag.get(type) || '';
