import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {useModal} from 'react-modal-hook';
import {GameDetailsPage as GameDetails, GameDetailsSkeleton} from '@xsolla/funding-ui/xfc';

import {AllowParams} from '../../../typings/allow-params';

import {getLaunchDate} from '../../../lib/date';
import {getFormattedNumber} from '../../../utils/formatter';

import {selectApplicationCompanyID} from '../../../redux/selectors/application';

import {useGameDetailsQuery, useGameInvestmentInfoQuery} from '../../../api/game';

import {ContactDeveloperModal} from '../../../component/@modals';

import {Image, Slide} from '../components/Slide';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {AnalyticsEvent} from '../../../lib/XsollaAnalytics';

export const GameDetailsPage: FC = () => {
  const analytics = useAnalytics();

  const {game_id: gameId} = useParams<AllowParams>();
  const history = useHistory();
  const companyId = useSelector(selectApplicationCompanyID);

  const {data: game, isFetching: isFetchingGame} = useGameDetailsQuery(companyId, gameId);
  const {data: investmentInfo, isFetching: isFetchingInvestmentInfo} = useGameInvestmentInfoQuery(
    companyId,
    gameId
  );

  const [onOpenContactDeveloperModal, onCloseContactDeveloperModal] = useModal(() => {
    analytics.click(AnalyticsEvent.ContactDeveloperClick, {
      exv: {game_id: game.id, game_name: game.game_name}
    });
    return <ContactDeveloperModal game={game} onClose={onCloseContactDeveloperModal} />;
  }, [game]);

  if (isFetchingGame || isFetchingInvestmentInfo) {
    return <GameDetailsSkeleton />;
  }

  return (
    <GameDetails
      onClickBackButton={() => history.goBack()}
      onClickContactDeveloper={onOpenContactDeveloperModal}
      gameName={game.game_name}
      website={game.website}
      studioName={game.studio_name}
      screenshots={
        game.screenshots && game.screenshots.length ? game.screenshots : [game.header_image_url]
      }
      renderSlide={src => (
        <Slide key={src}>
          <Image src={src} />
        </Slide>
      )}
      videoPlaceholderImage={game.header_image_url}
      subgenres={game.subgenre}
      gameGenres={game.game_genre}
      videoContent={game.video_content}
      highConcept={game.high_concept}
      launchDate={getLaunchDate({
        gameAlreadyLaunched: game.game_already_launched,
        launchDate: game?.launch_date,
        gameAlreadyLaunchedLabel: 'Already launched'
      })}
      startDate={game.start_date && new Date(game.start_date).toLocaleDateString('en-US')}
      milestone={game.milestone}
      releasePlatform={game.release_platform}
      playableBuild={game.playable_build}
      teamSize={game.team_size}
      teamLocation={game.team_location}
      launchedGames={game.launched_games}
      portfolio={game.portfolio}
      pitchDeck={game.pitch_deck_url}
      additionalDocs={game.additionalDocs}
      gameType={game.game_type}
      monetizationModel={game.monetization_model}
      //
      requestedAmount={<>${getFormattedNumber(investmentInfo.requested_amount)}</>}
      totalAmount={<>${getFormattedNumber(investmentInfo.total_amount)}</>}
      ownFunds={<>${getFormattedNumber(investmentInfo.own_funds)}</>}
      alreadyInvestedAmount={<>${getFormattedNumber(investmentInfo.already_invested_amount)}</>}
      spentToDate={<>${getFormattedNumber(investmentInfo.spent_to_date)}</>}
    />
  );
};
