import React from 'react';
import styled from 'styled-components';

const StyledPasswordTooltip = styled.div``;

const List = styled.ul`
  margin-block-start: 8px;
  padding-inline-start: 20px;
`;

export const PasswordTooltip = () => (
  <StyledPasswordTooltip>
    The password must contain:
    <List>
      <li>Minimum of 8 characters</li>
      <li>At least one numeral (0-9)</li>
      <li>At least one uppercase and lowercase letter</li>
    </List>
  </StyledPasswordTooltip>
);
