import {reducer as formReducer} from 'redux-form';
import {combineReducers} from 'redux';
import {gdprReducer} from '../gdpr';
import {applicationReducer} from './application';
import {appThemeReducer} from './apptheme';
import {discoverFilterReducer} from './discover-filter';
import {gamesReducer} from './games';
import {languageReducer} from './language';
import {onboardingReducer} from './onboarding';
import {userReducer} from './user';

export default combineReducers({
  form: formReducer,
  application: applicationReducer,
  appTheme: appThemeReducer,
  discoverFilter: discoverFilterReducer,
  games: gamesReducer,
  language: languageReducer,
  onboarding: onboardingReducer,
  user: userReducer,
  gdpr: gdprReducer
});
