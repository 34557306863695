import React from 'react';
import Imgix from 'react-imgix';
import styled from 'styled-components';
import {BrowseGame} from '../../../api/guest/browseGames';
import {ProgressBar} from '../../@ui';
import {mediaDevices} from '../../../lib/styled';
import {prepareUrlImgix} from '../../../lib/imgix';

interface GameCardProps {
  game: BrowseGame;
}

const StyledGameCard = styled.div`
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0, 36, 77, 0.08);
  color: #011627;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 16px 16px 8px 8px;
`;

const Cover = styled(Imgix)`
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 7px 7px 0 0;
  box-sizing: border-box;

  @media screen and ${mediaDevices.mobile} {
    height: 140px;
  }

  @media screen and ${mediaDevices.tablet} {
    height: 172px;
  }

  @media screen and ${mediaDevices.desktop} {
    height: 172px;
  }
`;

const Entry = styled.div`
  padding: 20px;
`;

const Title = styled.div`
  font-size: 26px;
  line-height: 30px;
  margin: 0 0 10px 0;
  font-weight: 800;
`;

const Caption = styled.div`
  margin-top: 8px;
  font-size: 14px;
  line-height: 18px;
  color: #747e8a;
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--fontFamilySpaceMono);

  & span {
    margin-right: 4px;
    color: #00244d;
  }
`;

export const GameCard = ({game}: GameCardProps) => (
  <StyledGameCard>
    <Cover
      src={prepareUrlImgix(game.header_image_url)}
      width={400}
      height={200}
      imgixParams={{fit: 'crop'}}
    />
    <Entry>
      <Title>{game.game_name}</Title>
      <ProgressBar percentage={game.invested_percentage} />
      <Caption>
        <span>{game.invested_percentage}%</span>
        funded of goal amount
      </Caption>
    </Entry>
  </StyledGameCard>
);
