export const getDealRanges = [
  {id: '0-100k', label: '$0 - $100k', value: '0-100k'},
  {id: '100-500k', label: '$100k - $500k', value: '100-500k'},
  {id: '500k-1m', label: '$500k - $1M', value: '500k-1m'},
  {id: '1m-2.5m', label: '$1M - $2.5M', value: '1m-2.5m'},
  {id: '2.5m-5m', label: '$2.5M - $5M', value: '2.5m-5m'},
  {id: '5m-10m', label: '$5M - $10M', value: '5m-10m'},
  {id: '10m', label: '$10M+', value: '10m'}
];

export const getInvestmentSpecialization = [
  {label: 'Game Publisher', value: 'game_publisher'},
  {label: 'Investment fund', value: 'investment_fund'}
];

export const getAccreditedInvestor = Object.freeze([
  {label: 'Yes', radioValue: '1'},
  {label: 'No', radioValue: '0'}
]);

export const getLastYearGameInvested = Object.freeze([
  {label: 'Yes', radioValue: '1'},
  {label: 'No', radioValue: '0'}
]);
