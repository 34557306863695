import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

interface FormSectionProps {
  children: ReactNode;
  label: ReactNode;
}

const cx = classNames.bind(styles);

export const FormSection = ({children, label}: FormSectionProps) => (
  <div className={cx(styles.formSection)}>
    <div className={cx(styles.label)}>{label}</div>
    <div className={cx(styles.content)}>{children}</div>
  </div>
);
