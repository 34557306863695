import {InjectedIntl} from 'react-intl';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getGenres = (intl: InjectedIntl) => [
  {
    id: 'action',
    label: 'Action',
    value: 'action'
  },
  {
    id: 'rpg',
    label: 'RPG',
    value: 'rpg'
  },
  {
    id: 'strategy',
    label: 'Strategy',
    value: 'strategy'
  },
  {
    id: 'adventure',
    label: 'Adventure',
    value: 'adventure'
  },
  {
    id: 'simulation',
    label: 'Simulation',
    value: 'simulation'
  },
  {
    id: 'sports',
    label: 'Sports',
    value: 'sports'
  }
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPlatforms = (intl: InjectedIntl) => [
  {id: 'pc', label: 'PC', value: 'pc'},
  {id: 'console', label: 'Console', value: 'console'},
  {id: 'mobile', label: 'Mobile', value: 'mobile'}
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMonetizationTypes = (intl: InjectedIntl) => [
  {id: 'f2p', label: 'Free to Play', value: 'f2p'},
  {id: 'p2p', label: 'Premium', value: 'p2p'}
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPlayableBuild = (intl: InjectedIntl) => [
  {label: 'Yes', radioValue: 'yes'},
  {label: 'No', radioValue: 'no'},
  {label: 'Not necessary', radioValue: 'maybe'}
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getInvestmentStages = (intl: InjectedIntl) => [
  {id: 'concept', label: 'Concept', value: 'concept'},
  {id: 'first_playable', label: 'First Playable', value: 'first_playable'},
  {id: 'mid_development', label: 'Mid-Development', value: 'mid_development'},
  {id: 'finishing_funds', label: 'Finishing Funds', value: 'finishing_funds'}
];
