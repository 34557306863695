import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Section} from './Section';
import {subTitleTypography} from './typography';
import {Routes} from '../../constant';

const StyledFaqBlock = styled.div`
  max-width: 670px;
  margin: 0 auto 40px auto;
`;

const Title = styled.p`
  margin: 0 0 60px 0;
  font-weight: 900;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
`;

const SubTitle = styled.h2`
  ${subTitleTypography};
  margin: 0 0 10px 0;
`;

const Text = styled.p`
  margin: 0 0 30px 0;
  color: #d6e0e7;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FaqBlock = () => (
  <Section>
    <StyledFaqBlock>
      <Title>Frequently asked questions</Title>

      <SubTitle>What is Xsolla Game Investment Platform?</SubTitle>
      <Text>
        Xsolla Game Investment Platform is a portfolio of investment-ready games. Individuals,
        companies, and other funding sources can browse the games portfolio or search directly for
        games matching their criteria. Once they&lsquo;ve found a desired game, investors select a
        game to invest in with pre-set terms by the developer in consultation with Xsolla.
      </Text>

      <SubTitle>
        Why is Xsolla Game Investment Platform qualified to help me find an investment-worthy game?
      </SubTitle>
      <Text>
        Xsolla has 15 years of experience creating industry-leading video gaming-specific tools and
        services for developers, publishers, and platform partners, with more than 2,000 and
        counting global clients. We have also assembled a team of industry veterans that evaluate
        and review each submission prior to their selection into the investment program.
      </Text>

      <SubTitle>How does the Xsolla Game Investment Platform application process work?</SubTitle>
      <Text>
        Individual investors can <Link to={Routes.LOGIN_JOIN}>create a no-cost account</Link> and
        apply to join.
      </Text>

      <SubTitle>
        Why wouldn&lsquo;t I be accepted as an investor in Game Investment Platform?
      </SubTitle>
      <Text>
        Xsolla Game Investment Platform is designed for qualified investors that are interested in
        investing in video games. This platform is intended for individuals interested in co-funding
        projects rather than lead funding. If you are more interested in being a lead investor,
        apply for an investor account today at&nbsp;
        <a href="https://xsolla.com/funding">Xsolla Funding Club.</a>
      </Text>

      <SubTitle>What happens if you want to invest in a game?</SubTitle>
      <Text>
        Once you have selected your desired investment amount, you can submit to invest. To ensure a
        secure transaction, your submission is processed through a KYC (Know Your Customer)
        verification system, typically within a few minutes. Upon approval, you will receive an
        email to finalize your investment on the Game Investment Platform. Xsolla will take care of
        the rest.
      </Text>
    </StyledFaqBlock>
  </Section>
);
