import {request} from '../index';
import {loginApiClient} from '../../axios/client/login';
import {investorApiClient} from '../../axios/client/investor';
import {XSOLLA_LOGIN_SESSION_URL} from '../../../constant';
import {getLoginProjectID} from '../../../lib/login';

const loginProjectID = getLoginProjectID();

export const requestRegister = (data: Record<string, string>) => {
  return request(
    loginApiClient,
    'post',
    `api/user?projectId=${loginProjectID}&login_url=${XSOLLA_LOGIN_SESSION_URL}`,
    {
      username: data.username,
      email: data.username,
      password: data.password
    }
  );
};

export const requestLoginUser = (data: Record<string, string>) => {
  return request(
    loginApiClient,
    'post',
    `api/login?projectId=${loginProjectID}&login_url=${XSOLLA_LOGIN_SESSION_URL}`,
    data
  );
};

export const investorLoginUser = () => {
  return request(investorApiClient, 'post', 'user/login');
};

export const investorGetCompanies = () => {
  return request(investorApiClient, 'get', 'user/companies');
};

export const requestSocialLogin = (socialNetwork: string) => {
  const REQUEST_FIELDS = 'email';

  const searchParams = new URLSearchParams({
    fields: REQUEST_FIELDS,
    projectId: loginProjectID,
    login_url: `${XSOLLA_LOGIN_SESSION_URL}${window.location.search}`
  });

  return window.location.replace(
    `${
      process.env.REACT_APP_LOGIN_API_URL
    }/api/social/${socialNetwork}/login_redirect?${searchParams.toString()}`
  );
};
