const genresToNumberMap = {
  action: 22,
  adventure: 23,
  rpg: 24,
  simulation: 25,
  strategy: 26,
  sports: 27
};

export const convertFieldGenresToNumber = (values: string[]): number[] => {
  return values.map((val: string) => genresToNumberMap[val] || undefined);
};

const platformsToNumberMap = {
  pc: 85,
  browser: 86,
  switch: 87,
  xbox: 88,
  ps4: 89,
  console: 90,
  mobile: 91
};

export const convertFieldPlatformsToNumber = (values: string[]): number[] => {
  return values.map((val: string) => platformsToNumberMap[val] || undefined);
};
