import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

export enum InfoCardLook {
  Bordered = 'bordered',
  Flatten = 'flatten'
}

interface InfoCardProps {
  children: ReactNode;
  look?: InfoCardLook;
  label: string;
}

const cx = classNames.bind(styles);

export const InfoCard = ({children, look = InfoCardLook.Bordered, label}: InfoCardProps) => (
  <div className={cx(styles.infoCard, styles[look])}>
    <div className={cx(styles.label)}>{label}</div>
    <div className={cx(styles.entry)}>{children}</div>
  </div>
);
