import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

const StyledTitle = styled.div`
  margin-top: calc(var(--ls) * -1);
  margin-bottom: var(--ls--lg);
  color: var(--colorTextSecondary);
`;

interface Props {
  title: string;
}

export const PageTitle = ({title}: Props) => {
  return (
    <StyledTitle>
      <FormattedMessage id={title} defaultMessage="Description" />
    </StyledTitle>
  );
};
