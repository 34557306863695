import React, {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import styled from 'styled-components';
import {Button, FormFooter, FormGroup, StepTabs} from '@xsolla/uikit';
import LoadStatus from '../../component/form/load-status';
import {ToastType, useToasts} from '../../component/ToastProvider';
import {
  actionUpdateOnboardingStatus,
  updateOnboardingPageData
} from '../../redux/action/onboarding';
import {selectApplicationCompanyID} from '../../redux/selectors/application';
import {AnyOnboardingPage} from '../../redux/types/onboarding';
import {OnboardingStatus} from '../../constants/OnboardingStatus';
import {useOnboardingForm} from './useOnboardingForm';
import {OnboardingSteps} from '../../constants/OnboardingSteps';
import {AnalyticsEvent} from '../../lib/XsollaAnalytics';
import {useAnalytics} from '../../hooks/useAnalytics';

const StyledForm = styled.form`
  margin-top: 32px;
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const VerifyInfo = styled.div`
  margin-top: 8px;
  color: var(--secondaryTextColor);
`;

const Tabs = styled.div`
  width: 100%;
  margin: 40px;
`;

const Content = styled.div`
  margin-bottom: 44px;
`;

const stepsXFC = [{label: 'Personal info'}, {label: 'Company info'}, {label: 'Investing interest'}];

interface Props {
  currentPage: number;
  confirmButton: boolean;
  onClick: (value: number) => void;
  initialValues: AnyOnboardingPage;
  contentComponent: ReactElement;
}

export const OnboardingForm = ({
  currentPage,
  confirmButton,
  onClick,
  initialValues,
  contentComponent
}: Props) => {
  const analytics = useAnalytics();

  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const companyId = useSelector(selectApplicationCompanyID);

  const {updateOnboardingData, isLoading, isError, completeOnboarding} = useOnboardingForm(
    companyId
  );

  const onSubmit = async values => {
    if (!values) {
      return;
    }

    const indexPage = (currentPage + 1) as OnboardingSteps;

    updateOnboardingData({
      companyId,
      step: indexPage,
      values,
      onSuccess: async () => {
        dispatch(updateOnboardingPageData({page: indexPage, data: values}));

        if (!confirmButton) {
          analytics.click(AnalyticsEvent.OnboardingNextStepClick, {exv: {step: indexPage}});
          onClick(indexPage);
          return;
        }

        await completeOnboarding();
        dispatch(actionUpdateOnboardingStatus(OnboardingStatus.PASSED));
        analytics.click(AnalyticsEvent.SubmitOnboarding);
      },
      onError: () => {
        addToast('An error has occurred', ToastType.Error);
      }
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        getAll: ([name], state) => state.formState.values[name],
        removeAll: () => void 0,
        ...arrayMutators
      }}>
      {({handleSubmit}) => (
        <StyledForm onSubmit={handleSubmit}>
          <FormGroup indentation="xlg">
            <Header>
              <Title>
                <FormattedMessage
                  id="app.page.onboardingpage.title"
                  defaultMessage="Fill in the application form"
                />
              </Title>
              <VerifyInfo>
                You will get access to your personal Investor Account after your application has
                been approved.
              </VerifyInfo>
              <Tabs>
                <StepTabs current={currentPage + 1} steps={stepsXFC} />
              </Tabs>
            </Header>
          </FormGroup>
          <Content>
            <FormGroup indentation="lg">{contentComponent}</FormGroup>
            {isError && (
              <FormGroup indentation="md">
                <LoadStatus status="error">Error while saving data</LoadStatus>
              </FormGroup>
            )}
          </Content>
          <FormFooter ignoreCentered>
            {currentPage > 0 ? (
              <Button
                appearance="outline"
                onClick={() => {
                  onClick(currentPage - 1);
                }}>
                <FormattedMessage id="app.page.onboardingpage.button.back" defaultMessage="Back" />
              </Button>
            ) : (
              <div />
            )}
            <Button appearance="secondary" type="submit" fetching={isLoading} disabled={isLoading}>
              <FormattedMessage
                id={
                  confirmButton
                    ? 'app.page.onboardingpage.button.submit'
                    : 'app.page.onboardingpage.button.next'
                }
                defaultMessage={confirmButton ? 'Submit' : 'Next'}
              />
            </Button>
          </FormFooter>
        </StyledForm>
      )}
    </Form>
  );
};
