import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {dispatchDiscoverRequest} from '../../../api/discover';
import {DISCOVER_PAGE_BROWSE_DEFAULT_LIMIT, Routes} from '../../../constant';
import {PageSection} from '../../@ui';
import {DiscoverSort} from '../../@sort';
import {ManualList} from '../../manual-list';
import {DiscoverGamesGrid} from '../DiscoverGamesBlock';
import {ToastType, useToasts} from '../../ToastProvider';
import {useRouteLeave} from '../../../hooks/useRouteLeave';
import {MinimalGame} from '../../../redux/reducer/games';
import {selectApplicationCompanyID} from '../../../redux/selectors/application';
import {selectDiscoverFilterValue} from '../../../redux/selectors/discover-filter';
import {DiscoverPageSectionType} from '../../../redux/types/discover';
import {GameCardsPlaceholder} from '../../@placeholders/GameCardsPlaceholder';
import {setDiscoverFilterValue} from '../../../redux/action/discover-filter';
import {defaultDiscoverFilterValue} from '../../../redux/reducer/discover-filter';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {AnalyticsEvent} from '../../../lib/XsollaAnalytics';
import {DiscoverFilterValue} from '../../../redux/types';

const NotFound = styled.div`
  display: flex;
  min-height: 180px;
  align-items: center;
  justify-content: center;
  color: var(--colorTextSecondary);
  font-size: 18px;
  font-weight: bold;
`;

const SearchResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 44px;
`;

const SearchResultCount = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
`;

const leaveRoutes = [`${Routes.SETTINGS_PAGE}/*`, '/auth/*'];

export const DiscoverSearchPage = () => {
  const analytics = useAnalytics();

  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const [games, setGames] = useState<MinimalGame[]>([]);
  const [totalGamesCount, setTotalGamesCount] = useState(0);
  const [fetching, setFetching] = useState(false);
  const companyID = useSelector(selectApplicationCompanyID);
  const discoverFilterValue = useSelector(selectDiscoverFilterValue);

  const sendAnalytics = (discoverFilerValue: DiscoverFilterValue) => {
    analytics.customEvent(AnalyticsEvent.UserGamesFilterApplied, {
      exv: {filter_value: discoverFilerValue}
    });
  };

  useEffect(() => {
    setGames([]);
  }, [discoverFilterValue]);

  useRouteLeave(() => {
    dispatch(setDiscoverFilterValue(defaultDiscoverFilterValue));
  }, leaveRoutes);

  const hasGames = !!games.length;

  const requestGame = async (page: number, limit: number) => {
    setFetching(true);
    try {
      const response = await dispatchDiscoverRequest({
        type: DiscoverPageSectionType.BROWSE,
        companyID,
        filters: {
          params: {
            page,
            limit
          },
          value: discoverFilterValue
        }
      });
      setGames(prevGames => [
        ...prevGames,
        ...response.games.filter(game => {
          return !prevGames.some(g => g.id === game.id);
        })
      ]);
      setTotalGamesCount(response?.total_games_count || 0);

      sendAnalytics(discoverFilterValue);

      return response.has_more_games;
    } catch (err) {
      addToast('An error has occurred', ToastType.Error);
      throw err;
    } finally {
      setFetching(false);
    }
  };

  return (
    <PageSection>
      {!fetching && !hasGames && <NotFound>No results for your search</NotFound>}
      {hasGames && (
        <SearchResultContainer>
          <SearchResultCount>Results: {totalGamesCount || games.length} games</SearchResultCount>
          <DiscoverSort />
        </SearchResultContainer>
      )}
      <ManualList
        requestMethod={requestGame}
        limit={DISCOVER_PAGE_BROWSE_DEFAULT_LIMIT}
        loader={<GameCardsPlaceholder key="search-placeholder" internal={true} />}
        deps={[discoverFilterValue]}>
        <DiscoverGamesGrid<MinimalGame> section={DiscoverPageSectionType.SEARCH} games={games} />
      </ManualList>
    </PageSection>
  );
};
