import React, {Fragment} from 'react';
import {InjectedIntl, injectIntl} from 'react-intl';
import {FieldArray} from 'react-final-form-arrays';
import {Field} from 'react-final-form';
import {Input} from '@xsolla/uikit';
import {MultiCheckbox, RadioGroup, Select} from '../../../@ui';
import {required, requiredArray, url} from '../../../../utils/validators';
import {PageTitle} from '../Title';
import {FormGroup} from '../../../@ui/FormGroup';
import {getAccreditedInvestor, getDealRanges, getInvestmentSpecialization} from './options';

interface Props {
  intl: InjectedIntl;
  settings?: boolean;
}

export const XFCCompanyInfoPage = injectIntl(({intl, settings = false}: Props) => {
  return (
    <Fragment>
      {!settings && <PageTitle title="app.page.onboardingpage.page-title.company-info" />}

      <FormGroup isRequired label="Company name">
        <Field component={Input} name="company_name" validate={required} />
      </FormGroup>

      {!settings && (
        <FormGroup
          label={intl.formatMessage({id: 'app.page.onboardingpage.crunchbase-url'})}
          tooltip={intl.formatMessage({id: 'app.page.onboardingpage.crunchbase-url.tooltip'})}>
          <Field
            name="crunchbase_url"
            parse={(val: string) => val}
            component={Input}
            validate={url}
            placeholder="https://www.crunchbase.com/company"
          />
        </FormGroup>
      )}

      <Field name="investment_specialization" validate={required}>
        {props => {
          return (
            <Fragment>
              <FormGroup
                label={intl.formatMessage({
                  id: 'app.page.onboardingpage.investment-specialization'
                })}
                isRequired>
                <Select {...props} options={getInvestmentSpecialization} />
              </FormGroup>
            </Fragment>
          );
        }}
      </Field>

      <FormGroup isRequired label={intl.formatMessage({id: 'app.page.onboardingpage.deal-range'})}>
        <FieldArray
          name="deal_ranges"
          component={MultiCheckbox}
          options={getDealRanges}
          validate={requiredArray}
        />
      </FormGroup>
      {!settings && (
        <>
          <FormGroup
            label={intl.formatMessage({id: 'app.page.onboardingpage.accredited-investor'})}>
            <Field
              name="accredited_investor"
              component={RadioGroup}
              options={getAccreditedInvestor}
              direction="row"
            />
          </FormGroup>
        </>
      )}
    </Fragment>
  );
});
