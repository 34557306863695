import {
  FieldRecord,
  gameEngineFieldOptions,
  gameGenresFieldOptions,
  initialPlatformsFieldOptions,
  investmentPreferRangeFieldOptions,
  monetizationModelFieldOptions,
  playableBuildFieldOptions,
  raisedPercentageFieldOptions,
  subgenresFieldOptions,
  teamSizeFieldOptions
} from './options';
import {convertFieldGenresToNumber, convertFieldPlatformsToNumber} from './fieldConvert';
export type {FieldRecord};

export enum FieldOption {
  Genres = 'genres',
  TeamSize = 'team_size',
  RaisedPercentage = 'raised_percentage',
  InvestmentPreferRange = 'investment_prefer_range',
  InitialPlatforms = 'initial_platforms',
  GameEngine = 'game_engine',
  MonetizationModel = 'monetization_model',
  Subgenres = 'subgenres',
  PlayableBuild = 'playable_build'
}

const FieldOptionsMap = new Map<FieldOption, FieldRecord[]>([
  [FieldOption.GameEngine, gameEngineFieldOptions],
  [FieldOption.Genres, gameGenresFieldOptions],
  [FieldOption.TeamSize, teamSizeFieldOptions],
  [FieldOption.RaisedPercentage, raisedPercentageFieldOptions],
  [FieldOption.InvestmentPreferRange, investmentPreferRangeFieldOptions],
  [FieldOption.InitialPlatforms, initialPlatformsFieldOptions],
  [FieldOption.MonetizationModel, monetizationModelFieldOptions],
  [FieldOption.Subgenres, subgenresFieldOptions],
  [FieldOption.PlayableBuild, playableBuildFieldOptions]
]);

export const getFieldOptions = (field: FieldOption): FieldRecord[] => {
  return FieldOptionsMap.get(field) || [];
};

const ConvertFieldMap = new Map<FieldOption, (value: string[]) => number[]>([
  [FieldOption.Genres, (values: string[]): number[] => convertFieldGenresToNumber(values)],
  [
    FieldOption.InitialPlatforms,
    (values: string[]): number[] => convertFieldPlatformsToNumber(values)
  ]
]);

export const convertFieldToNumber = (field: FieldOption, values: string[]): number[] => {
  return ConvertFieldMap.get(field)(values);
};
