import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {mediaDevices} from '../../lib/styled';
import {selectGDPRComponent} from '../../redux/gdpr';
import {Brand, BrandSize} from './Brand';
import {XSOLLA_POLICIES_LINK, XSOLLA_TERMS_LINK} from '../../constant';
import {countries, zones} from 'moment-timezone/data/meta/latest.json';

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  margin-bottom: 60px;

  @media screen and ${mediaDevices.mobile} {
    flex-wrap: wrap;
  }

  @media screen and ${mediaDevices.desktop} {
    flex-wrap: nowrap;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  line-height: 20px;

  @media screen and ${mediaDevices.mobile} {
    margin-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    line-height: normal;
    flex-direction: column;

    & a {
      margin-bottom: 10px;
    }

    & span {
      display: none;
    }
  }

  @media screen and ${mediaDevices.desktop} {
    margin-top: 0;
    align-items: center;
    justify-content: flex-end;
    font-size: 15px;
    line-height: 20px;
    flex-direction: row;

    & a {
      margin-bottom: 0;
    }

    & span {
      display: inline-block;
    }
  }

  & a {
    color: inherit;
  }

  & span {
    margin: 0 20px;
  }
`;

export const Footer = () => {
  const usCountryName = 'United States';

  const gdpr = useSelector(selectGDPRComponent);
  const showPrivacySettings = () => {
    if (!gdpr) {
      return;
    }

    gdpr.show();
  };

  const getUserCountry = () => {
    const timeZoneToCountry = {};
    Object.keys(zones).forEach(z => {
      timeZoneToCountry[z] = countries[zones[z].countries[0]].name;
    });

    return timeZoneToCountry[Intl.DateTimeFormat().resolvedOptions().timeZone];
  };

  const isUSUser = useMemo(() => {
    return getUserCountry() === usCountryName;
  }, []);

  return (
    <StyledFooter>
      <div>
        <Brand label="Xsolla" size={BrandSize.Small} />
      </div>
      <Links>
        <a href="javascript:void(0)" onClick={showPrivacySettings}>
          {isUSUser && 'Do Not Sell My Personal Information'}
          {!isUSUser && 'Privacy Settings'}
        </a>
        <span>|</span>
        <a href={XSOLLA_POLICIES_LINK} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        <span>|</span>
        <a href={XSOLLA_TERMS_LINK} target="_blank" rel="noopener noreferrer">
          Terms of use
        </a>
        <span>|</span>
        <div>Copyright © 2006 - {new Date().getFullYear()} Xsolla Inc.</div>
      </Links>
    </StyledFooter>
  );
};
