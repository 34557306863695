import {DefaultTheme} from 'styled-components';
import {lightTheme} from '@xsolla/ui-core';
import {tokens} from '@xsolla/ui-core/dist/tokens/tokens';

import {FontFamilys, fontFamilys} from './font-familys';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: typeof tokens.colors;
    palettes: typeof tokens.palettes;
    typography: typeof tokens.typography;
    misc: typeof tokens.misc;

    fontFamilys: FontFamilys;
  }
}

export const theme: DefaultTheme = {
  ...lightTheme,
  fontFamilys
};
