import {AnyAction} from 'redux';
import {
  OnboardingCompanyInfo,
  OnboardingCompanyInfoForm,
  OnboardingInvestingInterest,
  OnboardingPageType,
  OnboardingPersonalInfo
} from '../types/onboarding';
import {OnboardingStatus} from '../../constants/OnboardingStatus';

export interface OnboardingState {
  [OnboardingPageType.PERSONAL_INFO]: OnboardingPersonalInfo;
  [OnboardingPageType.COMPANY_INFO]: OnboardingCompanyInfoForm;
  [OnboardingPageType.INVESTING_INTEREST]: OnboardingInvestingInterest;
  status: OnboardingStatus;
}

export const initialState: OnboardingState = {
  [OnboardingPageType.PERSONAL_INFO]: {
    email: '',
    first_name: '',
    job_title: '',
    last_name: '',
    social_url: ''
  },
  [OnboardingPageType.COMPANY_INFO]: {
    company_name: '',
    crunchbase_url: '',
    deal_ranges: [],
    investment_specialization: '',
    accredited_investor: null
  },
  [OnboardingPageType.INVESTING_INTEREST]: {
    genres: [],
    monetization_types: [],
    platforms: [],
    investment_stages: [],
    playable_builds: ''
  },
  status: null
};

export const ONBOARDING_SET_PAGES_DATA = '@/onboarding/SET_PAGES_DATA';
export const GIP_ONBOARDING_SET_PAGES_DATA = '@/onboarding/GIP_SET_PAGES_DATA';
export const ONBOARDING_UPDATE_PAGE_DATA = '@/onboarding/UPDATE_PAGE_DATA';
export const ONBOARDING_UPDATE_STATUS = '@/onboarding/SET_STATUS';

const onboardingCompanyInfoParser = (data: OnboardingCompanyInfo): OnboardingCompanyInfoForm => {
  const boolToStringConverter = (value: boolean | null): string | null => {
    if (value === null) {
      return null;
    }

    return value ? '1' : '0';
  };

  return {
    ...data,
    accredited_investor: boolToStringConverter(data.accredited_investor)
  };
};

export const onboardingReducer = (state = initialState, action: AnyAction): OnboardingState => {
  switch (action.type) {
    case ONBOARDING_SET_PAGES_DATA:
      const [personalInfo, companyInfo, investingInterest] = action.payload;
      return {
        ...state,
        [OnboardingPageType.PERSONAL_INFO]: personalInfo,
        [OnboardingPageType.COMPANY_INFO]: onboardingCompanyInfoParser(companyInfo),
        [OnboardingPageType.INVESTING_INTEREST]: investingInterest
      };

    case GIP_ONBOARDING_SET_PAGES_DATA:
      const [GIPPersonalInfo, GIPInvestingInterest] = action.payload;
      return {
        ...state,
        [OnboardingPageType.PERSONAL_INFO]: GIPPersonalInfo,
        [OnboardingPageType.INVESTING_INTEREST]: GIPInvestingInterest
      };

    case ONBOARDING_UPDATE_PAGE_DATA:
      return {
        ...state,
        [action.payload.page]: action.payload.data
      };

    case ONBOARDING_UPDATE_STATUS:
      return {
        ...state,
        status: action.payload.status
      };

    default:
      return state;
  }
};
