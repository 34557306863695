import React, {Children, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

export enum FieldGroupJustify {
  Default = 'default',
  Between = 'between',
  Center = 'center'
}

export enum FieldGroupSize {
  Small = 'small',
  Default = 'default',
  Large = 'large'
}

interface Props {
  children: ReactNode;
  justify?: FieldGroupJustify;
  size?: FieldGroupSize;
  stretch?: boolean;
}

const cx = classNames.bind(styles);

export const FieldGroup = ({
  children,
  justify = FieldGroupJustify.Default,
  size = FieldGroupSize.Default,
  stretch = false
}: Props) => (
  <div className={cx(styles.fieldGroup, styles[`justify-${justify}`], styles[`size-${size}`])}>
    {Children.map(
      children,
      (child): ReactNode =>
        child && <div className={cx(styles.child, stretch && styles.stretch)}>{child}</div>
    )}
  </div>
);
