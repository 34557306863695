import {MinimalGame} from '../reducer/games';

export enum DiscoverPageSectionType {
  BROWSE = 'browse',
  RECOMMENDED = 'recommended',
  FLASH_DEALS = 'flash_deals',
  MOST_FUNDED = 'most_funded',
  LAUNCHING_SOON = 'launching_soon',
  LAST_CHANCE_TO_INVEST = 'last_chance_to_invest',
  NEW_GAMES = 'new_games',
  NEW_EXCLUSIVES = 'new_exclusives',
  SEARCH = 'search'
}

export enum DiscoverPageSectionSize {
  DEFAULT_SLIDER = 'default_slider',
  SMALL_SLIDER = 'small_slider'
}

export interface DiscoverGamesResponse {
  games: MinimalGame[];
  has_more_games: boolean;
  total_games_count?: number;
}

export type DiscoverSearchPage = DiscoverPageSectionType | null;
