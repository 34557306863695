const SYSTEM =
  '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';

export interface FontFamilys {
  SHARP_GROTESK: string;
  GRAPHIK_LC_WEB: string;
  SPACE_MONO: string;
}

export const fontFamilys: FontFamilys = {
  SHARP_GROTESK: `'SharpGrotesk', ${SYSTEM}`,
  GRAPHIK_LC_WEB: `'Graphik LC Web', ${SYSTEM}`,
  SPACE_MONO: `'SpaceMono', ${SYSTEM}`
};
