import React, {FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames/bind';
import {initialize, onConsent} from '@xsolla/gdpr/dist/bundle';
import {saveGDPRInstance} from '../../redux/gdpr';
import {injectGtm} from './injectGtm';
import {getEmailFromToken} from '../../lib/jwtToken';

import '@xsolla/gdpr/dist/bundle.css';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

export const GDPR: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const email = getEmailFromToken();

    const userData = email
      ? {
          userIdentityType: 'email',
          userId: email
        }
      : {};

    onConsent(consent => {
      if (consent.cookie.analytics_consent_state) {
        injectGtm();
      }
    });

    initialize(
      {
        state: {
          productName: 'investor_account',
          ...userData
        },
        ui: {
          accentColor: '#FFFFFF'
        },
        localization: {
          locale: 'en'
        }
      },
      (err, instance) => {
        if (err || !instance) {
          return;
        }

        dispatch(saveGDPRInstance(instance));
      }
    );
  }, []);

  return <div data-id="gdpr-consent-form" id="gdpr-consent-form" className={cx('gdpr-app')} />;
};
