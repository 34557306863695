import React, {ReactNode} from 'react';
import {PageSection} from '../../@ui';

export interface DiscoverPageSectionDefaultProps {
  label: string;
  description?: string;
  children: ReactNode;
}

export const DiscoverPageSectionDefault = ({
  children,
  ...rest
}: DiscoverPageSectionDefaultProps) => {
  return <PageSection {...rest}>{children}</PageSection>;
};
