import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {Header} from '../component/header';
import {Footer} from '../component/Footer';

interface OutdoorLayoutProps {
  children?: ReactNode;
}

const StyledOfficeLayout = styled.div`
  padding: 0 32px;
  width: 1040px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: content-box;

  & > * {
    width: 100%;
  }
`;

const Entry = styled.div`
  margin: 40px 0;
  width: 100%;
  min-height: calc(100vh - 264px);
`;

const Bottom = styled.div`
  margin-top: auto;
`;

export const OutdoorLayout = ({children}: OutdoorLayoutProps) => {
  return (
    <StyledOfficeLayout>
      <Header />
      <Entry>{children}</Entry>
      <Bottom>
        <Footer />
      </Bottom>
    </StyledOfficeLayout>
  );
};
