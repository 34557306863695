import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {ContentBlock} from '@xsolla/uikit';
import {requestUserUnsubscribe} from '../../api/user';
import {ReactComponent as SuccessIcon} from '../../images/icons/success.svg';
import {OutdoorLayout} from '../../layouts/OutdoorLayout';
import style from './style.module.scss';

export const UnsubscribePage = () => {
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');

    if (!token) {
      history.replace('/');
      return;
    }

    try {
      requestUserUnsubscribe(token);
    } catch (err) {}
  }, []);

  return (
    <OutdoorLayout>
      <div className={style.unsubscribePage}>
        <ContentBlock>
          <div className={style.successMessage}>
            <SuccessIcon />
            <h2 className={style.title}>
              You have successfully unsubscribed from the Weekly Digest.
            </h2>
            <p>You can renew your subscription any time in your profile settings.</p>
          </div>
        </ContentBlock>

        <Link className={style.link} to="/">
          Go to Investor Account
        </Link>
      </div>
    </OutdoorLayout>
  );
};
