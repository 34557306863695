import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {MinimalGame} from '../../../../redux/reducer/games';
import {GameCard} from '../../../@cards';
import {DiscoverPageSectionType} from '../../../../redux/types/discover';
import {selectDiscoverSearchSectionPage} from '../../../../redux/selectors/discover-filter';

const StyledGamesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
`;

const GameContainer = styled.div`
  width: 33.333%;
  padding: 12px;
`;

interface DiscoverGamesGridProps<T> {
  section: DiscoverPageSectionType;
  games: T[];
}

export const DiscoverGamesGrid = <GameType extends MinimalGame>({
  section,
  games
}: DiscoverGamesGridProps<GameType>) => {
  const searchSectionPage = useSelector(selectDiscoverSearchSectionPage);
  const getShowTags = (): boolean =>
    !searchSectionPage && section !== DiscoverPageSectionType.BROWSE;

  return (
    games.length !== 0 && (
      <StyledGamesGrid>
        {games.map(game => (
          <GameContainer key={`${section}-game-${game.id}`}>
            <GameCard game={game} section={section} showTags={getShowTags()} />
          </GameContainer>
        ))}
      </StyledGamesGrid>
    )
  );
};
