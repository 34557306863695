import {XFCUserInvestorProfile} from './user';
import {InvestorProfile, UserAccount} from '../../api/user';

export enum OnboardingPageType {
  PERSONAL_INFO = 1,
  COMPANY_INFO = 2,
  INVESTING_INTEREST = 3
}

export type OnboardingPersonalInfo = UserAccount;

export type OnboardingCompanyInfo = Pick<
  XFCUserInvestorProfile,
  'company_name' | 'deal_ranges' | 'investment_specialization' | 'crunchbase_url'
> & {
  accredited_investor: boolean | null;
};

export type OnboardingCompanyInfoForm = Omit<OnboardingCompanyInfo, 'accredited_investor'> & {
  accredited_investor: string | null;
};

export type OnboardingInvestingInterest = Pick<
  InvestorProfile,
  'genres' | 'platforms' | 'monetization_types' | 'investment_stages' | 'playable_builds'
>;

export type AnyOnboardingPage =
  | OnboardingPersonalInfo
  | OnboardingCompanyInfoForm
  | OnboardingInvestingInterest;
