import React, {Children, Fragment, ReactNode} from 'react';
import classNames from 'classnames/bind';
import style from './style.module.scss';

interface TagProps {
  children: ReactNode;
  className?: string;
}

const cx = classNames.bind(style);

export const Tag = ({children, className}: TagProps) => (
  <Fragment>
    {Children.map(
      children,
      (child, index) =>
        child && (
          // eslint-disable-next-line react/no-array-index-key
          <div className={cx(style.tag, className)} key={`tag-${index}`}>
            {child}
          </div>
        )
    )}
  </Fragment>
);
