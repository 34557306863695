import {AnyAction} from 'redux';
import {User} from '../types/user';

const initialState: User = {
  account: {
    first_name: '',
    last_name: '',
    email: '',
    job_title: '',
    work_email: '',
    social_url: '',
    is_verified: null
  },
  investorProfile: {
    company_name: '',
    crunchbase_url: '',
    deal_ranges: [],
    genres: [],
    investment_stages: [],
    monetization_types: [],
    platforms: [],
    playable_builds: '',
    investment_specialization: ''
  },
  notifications: {
    weekly_digest_notification: false
  }
};

export const USER_UPDATE = '@/user/UPDATE';
export const USER_UPDATE_ACCOUNT = '@/user/UPDATE_ACCOUNT';
export const USER_UPDATE_INVESTOR_PROFILE = '@/user/UPDATE_INVESTOR_PROFILE';
export const USER_UPDATE_NOTIFICATIONS = '@/user/UPDATE_NOTIFICATIONS';
export const USER_LOGOUT = '@/user/LOGOUT';

export const userReducer = (state = initialState, action: AnyAction): User => {
  switch (action.type) {
    case USER_UPDATE: {
      const {account, investorProfile, notifications} = action.payload;
      return {
        ...state,
        account,
        investorProfile,
        notifications
      };
    }
    case USER_UPDATE_ACCOUNT:
      return {
        ...state,
        account: {...state.account, ...action.payload}
      };
    case USER_UPDATE_INVESTOR_PROFILE:
      return {
        ...state,
        investorProfile: action.payload
      };
    case USER_UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    case USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};
