import React, {Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import {PublicRoute} from '../component/PublicRoute';
import {Routes} from '../constant';

const compatibilityRoutes = [
  ['/login', Routes.LOGIN],
  ['/login/join', Routes.LOGIN_JOIN],
  ['/login/reset', Routes.LOGIN_RESET],
  ['/login/change', Routes.LOGIN_CHANGE]
];

/**
 * Backward compatibility of new routes for authorization.
 */
export const LoginBackwardCompatibility = () => (
  <Fragment>
    {compatibilityRoutes.map(([path, to]) => (
      <PublicRoute key={`compatibilityRoutes-${path}`} path={path} exact={true}>
        <Redirect to={{pathname: to, search: window.location.search}} />
      </PublicRoute>
    ))}
  </Fragment>
);
