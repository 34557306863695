export enum GeneratorTypes {
  TEXT = 'text',
  NUMBER = 'number',
  MULTI_SELECT = 'multi_select',
  SELECT = 'select',
  MULTI_CHECKBOX = 'multi_checkbox',
  CHECKBOX = 'checkbox',
  MULTI_RADIO = 'multi_radio',
  RADIO = 'radio',
  LINK = 'link',
  RANGE_SLIDER = 'slider',
  DATE = 'date',
  MONEY_TEXT = 'money_text'
}
