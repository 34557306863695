import {UseQueryResult, useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {selectApplicationCompanyID} from '../../redux/selectors/application';
import {requestInvestorProfile, requestMainUserInfo, requestUserAccount} from './requests';
import {InvestorProfile, UserAccount, UserMainInfo} from './types';

export const useUserAccount = (companyId: number): UseQueryResult<UserAccount> => {
  return useQuery(['user-account'], () => requestUserAccount(companyId));
};

export const useInvestorProfile = (companyId: number): UseQueryResult<InvestorProfile> => {
  return useQuery(['investor-profile'], () => requestInvestorProfile(companyId), {
    enabled: false,
    cacheTime: 0,
    refetchInterval: false
  });
};

export const useUserMainInfo = (enabled = true): UseQueryResult<UserMainInfo> => {
  const companyId = useSelector(selectApplicationCompanyID);
  return useQuery(['user-main-info'], () => requestMainUserInfo(companyId), {
    cacheTime: Infinity,
    enabled: enabled && companyId !== null
  });
};
