import React from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {Field, Form} from 'react-final-form';
import styled from 'styled-components';
import {Button, FormGroup, Input, InputPassword, Notification} from '@xsolla/uikit';
import {FormDivider} from '../../@ui';
import {ReactComponent as GoogleIcon} from '../../../assets/google_logo.svg';
import {composeValidators, email, required} from '../../../utils/validators';
import {requestSocialLogin} from '../../../utils/requester/login';
import {loginApplication} from '../../../redux/action/registration';
import {Routes} from '../../../constant';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {AnalyticsEvent} from '../../../lib/XsollaAnalytics';
import {getEmailFromToken, getLoginIdFromToken} from '../../../lib/jwtToken';
import {AuthenticationType} from '../../../lib/XsollaAnalytics/AnalyticsEvent';

interface LoginCredentials {
  username: string;
  password: string;
}

const StyledForm = styled.form`
  & button {
    width: 100%;
  }
`;

const Forgot = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledGoogleIcon = styled(GoogleIcon)`
  margin-right: 12px;
`;

export const LoginForm = () => {
  const analytics = useAnalytics();

  const dispatch = useDispatch();
  const initialValues: LoginCredentials = {
    username: '',
    password: ''
  };

  const sendAnalytics = () => {
    const loginId = getLoginIdFromToken();
    const email = getEmailFromToken();

    analytics.customEvent(AnalyticsEvent.UserAuthenticated, {
      exv: {email, user_id: loginId, auth_type: AuthenticationType.LoginEmail}
    });
  };

  const onSubmit = async (credentials: LoginCredentials) => {
    try {
      await loginApplication(credentials)(dispatch);
      sendAnalytics();
    } catch (err) {
      return err?.response?.data?.error?.description || 'Email or password is incorrect';
    }
  };

  return (
    <Form<LoginCredentials>
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({handleSubmit, hasSubmitErrors, submitting, submitErrors}) => (
        <StyledForm onSubmit={handleSubmit}>
          <FormGroup indentation="lg">
            <FormGroup label="Email">
              <Field
                component={Input}
                type="email"
                name="username"
                spellCheck="false"
                placeholder="email@domain.com"
                validate={composeValidators(required, email)}
              />
            </FormGroup>

            <FormGroup label="Password">
              <Field
                component={InputPassword}
                name="password"
                type="password"
                spellCheck="false"
                validate={composeValidators(required)}
              />
            </FormGroup>

            {hasSubmitErrors && (
              <FormGroup>
                <Notification status="error" icon={true}>
                  {submitErrors}
                </Notification>
              </FormGroup>
            )}
          </FormGroup>

          <FormGroup indentation="lg">
            <Forgot>
              <Link to={Routes.LOGIN_RESET}>Forgot your password?</Link>
            </Forgot>
          </FormGroup>

          <FormGroup>
            <Button type="submit" fetching={submitting}>
              Log In
            </Button>
          </FormGroup>

          <FormGroup>
            <FormDivider>or</FormDivider>
          </FormGroup>

          <FormGroup>
            <Button
              type="button"
              appearance="outline"
              onClick={() => {
                requestSocialLogin('google');
              }}>
              <StyledGoogleIcon />
              <span>Log In with Google</span>
            </Button>
          </FormGroup>
        </StyledForm>
      )}
    />
  );
};
