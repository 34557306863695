import React, {ReactNode} from 'react';
import {NavLink} from 'react-router-dom';
import {Icon} from '@xsolla/uikit';
import style from './style.module.scss';

interface ItemLinkProps {
  children: ReactNode;
  to: string;
}

interface SidebarProps {
  links: ItemLinkProps[];
}

const Link = ({children, to}: ItemLinkProps) => (
  <NavLink className={style.link} activeClassName={style.active} to={to}>
    {children}
    <Icon name="chevron-mini" />
  </NavLink>
);

export const Sidebar = ({links}: SidebarProps) => (
  <div className={style.sidebar}>
    {links.map(link => (
      <Link key={`sidebar-link-${link.to}`} to={link.to}>
        {link.children}
      </Link>
    ))}
  </div>
);
