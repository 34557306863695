import {SaveInvestorProfileParams, useSaveInvestorProfileApi} from '../../api/settings';

interface SaveParams extends SaveInvestorProfileParams {
  onSuccess: () => void;
  onError: () => void;
}

interface UseInvestorProfileForm {
  save: (params: SaveParams) => Promise<void>;
}

export const useInvestorProfileForm = (): UseInvestorProfileForm => {
  const {mutateAsync: saveInvestorProfile} = useSaveInvestorProfileApi();

  const save = async ({values, companyId, onSuccess, onError}) => {
    try {
      await saveInvestorProfile({companyId, values});
      onSuccess();
    } catch (err) {
      onError();
      throw err;
    }
  };

  return {
    save
  };
};
