import React, {FC} from 'react';
import {Text} from './styled';

export const Disclaimer: FC = () => {
  return (
    <Text>
      To make a deal with a game developer on the platform, you must contact them directly. You can
      do so by clicking the &quot;Contact developer&quot; button on a game&apos;s page.
    </Text>
  );
};
