import React from 'react';
import {InjectedIntl, injectIntl} from 'react-intl';
import {format} from 'date-fns';
import {Calendar as CalendarIcon} from '@xsolla/ui-icons';
import {CardImage, CardImageSize} from '../../../CardImage';
import {Tag} from '../../../@ui';
import {getFormattedGameType} from '../../../../utils/formatter/from-json-value';
import {getDiscoverGameMonetizationTypeTag} from '../../../../lib/discover-page';
import {truncate} from '../../../../lib/string';
import {getFormattedNumber} from '../../../../utils/formatter';
import {CardProps} from './DiscoverSliderCard';
import {StatusLabel} from '../../../StatusLabel';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {DiscoverPageSectionType} from '../../../../redux/types/discover';
import {AnalyticsEvent} from '../../../../lib/XsollaAnalytics';
import {
  Card,
  Description,
  GameName,
  ImageContainer,
  InfoContainer,
  PropertyGrid,
  PropertyGridDivider,
  PropertyIconContainer,
  PropertyLabel,
  PropertyValue,
  StatusLabelContainer
} from './styled';
export interface DefaultCardProps {
  intl: InjectedIntl;
  game: CardProps;
  section: DiscoverPageSectionType;
}

export const DefaultCard = injectIntl(({game, intl, section}: DefaultCardProps) => {
  const analytics = useAnalytics();

  const onGameCardClick = () => {
    analytics.click(AnalyticsEvent.GameCardClick, {
      exv: {
        section: section,
        game_id: game.id,
        game_name: game.game_name
      }
    });
  };

  return (
    <Card>
      <ImageContainer to={`/office/game/${game.id}/detail`} onClick={onGameCardClick}>
        <CardImage
          src={game.header_image_url}
          placeholder={game.game_name}
          size={CardImageSize.Large}
        />
      </ImageContainer>

      <InfoContainer>
        <div>
          <GameName to={`/office/game/${game.id}/detail`} onClick={onGameCardClick}>
            {game.game_name}
          </GameName>
          <Description>{truncate(game.description, 160)}</Description>

          <Tag>
            {getFormattedGameType(game.game_type, intl)}
            {getDiscoverGameMonetizationTypeTag(game.monetization_type)}
          </Tag>
        </div>

        <div>
          {game.partially_funded && (
            <StatusLabelContainer>
              <StatusLabel $color="brand">Partially funded</StatusLabel>
            </StatusLabelContainer>
          )}

          <PropertyGrid>
            <div>
              <PropertyLabel>Target release date</PropertyLabel>
              <PropertyValue>
                <PropertyIconContainer>
                  <CalendarIcon size={24} />
                </PropertyIconContainer>
                {game.game_already_launched
                  ? 'Already launched'
                  : format(new Date(game.launch_date), 'MMM d, yyyy')}
              </PropertyValue>
            </div>
            <div>
              <PropertyGridDivider />
            </div>
            <div>
              <PropertyLabel>Funding goal</PropertyLabel>
              <PropertyValue>${getFormattedNumber(game.requested_amount)}</PropertyValue>
            </div>
          </PropertyGrid>
        </div>
      </InfoContainer>
    </Card>
  );
});
