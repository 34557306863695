import {request} from '../../utils/requester';
import {investorApiClient} from '../../utils/axios/client/investor';
import {User, UserNotifications} from '../../redux/types/user';
import {UserAccount} from './types';
import {requestInvestorProfile, requestUserAccount} from './requests';

/**
 * Request user notifications
 * @param companyID
 */
export const requestUserNotifications = async (companyID: number): Promise<UserNotifications> => {
  const {data} = await request(
    investorApiClient,
    'get',
    `/company/${companyID}/settings/notifications`
  );
  return data;
};

/**
 * Request complete user object
 * @param companyID
 */
export const requestUser = async (companyID: number): Promise<User> => ({
  account: await requestUserAccount(companyID),
  investorProfile: await requestInvestorProfile(companyID),
  notifications: await requestUserNotifications(companyID)
});

/**
 * Request update user account
 * @param companyID
 * @param account
 */
export const requestUserUpdateAccount = async (
  companyID: number,
  account: UserAccount
): Promise<void> => {
  const {data} = await request(
    investorApiClient,
    'put',
    `/company/${companyID}/settings/user`,
    account
  );
  return data;
};

/**
 * Request update user notifications
 * @param companyID
 * @param notifications
 */
export const requestUserUpdateNotifications = async (
  companyID: number,
  notifications: UserNotifications
): Promise<void> => {
  const {data} = await request(
    investorApiClient,
    'put',
    `/company/${companyID}/settings/notifications`,
    notifications
  );
  return data;
};

/**
 * Request unsubscribe user
 * @param token
 */
export const requestUserUnsubscribe = async (token: string): Promise<void> => {
  const {data} = await investorApiClient.post('/user/unsubscribe', {token});
  return data;
};
