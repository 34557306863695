import React, {Fragment, ReactElement, useState} from 'react';
import {useSelector} from 'react-redux';
import {OutputSelector} from 'reselect';
import {
  selectOnboardingCompanyInfoPage,
  selectOnboardingInvestingInterestPage,
  selectOnboardingPersonalInfoPage
} from '../../redux/selectors/onboarding';
import {
  XFCCompanyInfoPage,
  XFCInvestingInterestPage,
  XFCPersonalInfoPage
} from '../../component/@fields';
import {OnboardingForm} from './OnboardingForm';
import {RootState} from '../../redux/types/store';
import {AnyOnboardingPage} from '../../redux/types/onboarding';
import {OnboardingState} from '../../redux/reducer/onboarding';

type MapValue = {
  selector: OutputSelector<
    RootState,
    AnyOnboardingPage,
    (res: OnboardingState) => AnyOnboardingPage
  >;
  component: ReactElement;
};

export const xfcPageMap: MapValue[] = [
  {
    selector: selectOnboardingPersonalInfoPage,
    component: <XFCPersonalInfoPage />
  },
  {
    selector: selectOnboardingCompanyInfoPage,
    component: <XFCCompanyInfoPage />
  },
  {
    selector: selectOnboardingInvestingInterestPage,
    component: <XFCInvestingInterestPage />
  }
];

export const OnboardingPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const mapData = xfcPageMap[currentPage];
  const pageCount = xfcPageMap.length - 1;

  return (
    <Fragment>
      {pageCount >= currentPage && (
        <OnboardingForm
          currentPage={currentPage}
          initialValues={useSelector(mapData.selector)}
          contentComponent={mapData.component}
          onClick={index => setCurrentPage(index)}
          confirmButton={pageCount === currentPage}
        />
      )}
    </Fragment>
  );
};
