import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {ignoreErrors} from './ignoreErrors';
import {beforeSend} from './beforeSend';

/**
 * Starts Sentry initialization.
 */
export const initSentry = () => {
  const environment = process.env.REACT_APP_SENTRY_ENV || 'development';

  if (environment === 'development') {
    return;
  }

  Sentry.init({
    environment,
    dsn: 'https://4aed924e18354df098ecf1fe23b5a550@o494898.ingest.sentry.io/5566744',
    release: process.env.REACT_APP_RELEASE_TAG,
    autoSessionTracking: false,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
    ignoreErrors: ignoreErrors,
    beforeSend
  });
};
